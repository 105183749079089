export enum ValidationKey {
  required = 'required',
  min = 'min',
  max = 'max',
  minLength = 'minLength',
  maxLength = 'maxLength',
  pattern = 'pattern',
  isInteger = 'isInteger',
  isPositive = 'isPositive',
  custom = 'custom',
}

export enum ValidationFormName {
  parkingAdressCheck = 'parkingAdressCheck',
  parkingSubscription = 'parkingSubscription',
  registration = 'registration',
  parkings = 'parkings',
}

export type ValidationRequirement = {
  [key in string]: ValidationType;
};
export type ValidationResult = {
  [key in string]: keyof ValidationType | string | null;
};
export type ValidationResults = {
  [key in string]: ValidationResult | ValidationResult[];
};

export interface ValidationType {
  [ValidationKey.required]?: boolean;
  [ValidationKey.min]?: number;
  [ValidationKey.max]?: number;
  [ValidationKey.minLength]?: number;
  [ValidationKey.maxLength]?: number;
  [ValidationKey.pattern]?: string;
  [ValidationKey.isInteger]?: boolean;
  [ValidationKey.isPositive]?: boolean;
  // eslint-disable-next-line
  [ValidationKey.custom]?: (data: any) => string | null;
}

export type ValidateResultData = {
  isValid: boolean;
  validateData: ValidationResult;
};

export const validator = (
  // eslint-disable-next-line
  datas: { [key in string]: any },
  validationRequirement: ValidationRequirement
): ValidateResultData => {
  let isValid = true;
  const validateData = Object.keys(validationRequirement).reduce(
    (result: ValidationResult, key: string) => {
      const data = datas[key];
      const requirement = validationRequirement[key];

      if (requirement[ValidationKey.required]) {
        if (
          !data ||
          data === '' ||
          data === 0 ||
          (typeof data === 'object' && !Object.keys(data).length) ||
          (Array.isArray(data) && !data.length) ||
          data === false
        ) {
          isValid = false;
          result[key] = ValidationKey.required;
          return result;
        }
      }

      if (requirement[ValidationKey.min] !== undefined) {
        const minValue = requirement[ValidationKey.min];

        if (minValue !== undefined && data * 1 < minValue) {
          isValid = false;
          result[key] = ValidationKey.min;
          return result;
        }
      }

      if (requirement[ValidationKey.max] !== undefined) {
        const maxValue = requirement[ValidationKey.max];

        if (maxValue !== undefined && data * 1 > maxValue) {
          isValid = false;
          result[key] = ValidationKey.max;
          return result;
        }
      }

      if (requirement[ValidationKey.minLength] !== undefined) {
        const minLength = requirement[ValidationKey.minLength];

        if (minLength !== undefined && data.length < minLength) {
          isValid = false;
          result[key] = ValidationKey.minLength;
          return result;
        }
      }

      if (requirement[ValidationKey.maxLength] !== undefined) {
        const maxLength = requirement[ValidationKey.maxLength];

        if (maxLength !== undefined && data.length > maxLength) {
          isValid = false;
          result[key] = ValidationKey.maxLength;
          return result;
        }
      }

      if (requirement[ValidationKey.custom] !== undefined) {
        const vFunction = requirement[ValidationKey.custom];

        if (vFunction !== undefined) {
          const vValue = vFunction(datas);

          if (vValue) {
            isValid = false;
            result[key] = vValue;
            return result;
          }
        }
      }

      return result;
    },
    {}
  );

  return {
    isValid,
    validateData,
  };
};

export const EmailRegex = '^\\w+([.-]?\\w+)*@\\w+([.-]?\\w+)*(\\.\\w{2,12})+$';
