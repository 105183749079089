import React from 'react';

import { useTranslation } from 'react-i18next';

import { Benefit } from '../../dumps/Benefit/Benefit';

import {
  BenefitWithoutKeys,
  BenefitType,
} from '../../../libraries/sections/models/Benefits';

export const benefitsList: BenefitType[] = [
  {
    key: BenefitWithoutKeys.FUNDED,
    icon: 'PiggyBank',
    size: '100',
    color: '#000',
  },
  {
    key: BenefitWithoutKeys.TURNKEY,
    icon: 'Thumbup',
    size: '100',
    color: '#000',
  },
  {
    key: BenefitWithoutKeys.SIMPLIFY,
    icon: 'CertifValid',
    size: '100',
    color: '#000',
  },
];

export const BenefitSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-normal pt-5">
      <div className="benefit-section">
        {benefitsList.map((benefit: BenefitType) => (
          <Benefit
            key={benefit.key}
            color={benefit.color}
            detail={t(
              `ownerPage.benefitSection.benefits.${benefit.key}.detail`
            )}
            icon={benefit.icon}
            size={benefit.size}
            title={t(`ownerPage.benefitSection.benefits.${benefit.key}.title`)}
          />
        ))}
      </div>
    </section>
  );
};
