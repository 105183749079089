import React, { lazy, useMemo, Suspense } from 'react';

interface IconProps {
  type: string;
  color?: string;
  size?: string;
  className?: string;
  iconRef?: string;
}

const iconComponent = (icon: string) => lazy(() => import(`./Icons/${icon}`));

export const Icon = ({
  type,
  size = 'standard',
  color,
  className,
  iconRef,
}: IconProps) => {
  const IconComponent = useMemo(() => iconComponent(type), [type]);

  return (
    <Suspense fallback={<div className={className} />}>
      <IconComponent
        iconRef={iconRef}
        className={className}
        color={color}
        size={size}
      />
    </Suspense>
  );
};
