import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import TagManager from 'react-gtm-module';

import { useEffect } from 'react';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { Icon } from '../../dumps/Icon/Icon';

export const CompletedStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validationTranslation: LanguageData = t('forms.validation', {
    returnObjects: true,
  });

  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        content_type: 'final-confirmation',
        item_id: 'form-subscription',
      },
    });
  }, []);

  return (
    <div className="page-form page-form__form validation-container">
      <div className="completed-step">
        <Icon type="Sparkles" />
        <h1 className="validation-title">{validationTranslation.title}</h1>
        <Icon type="SparklesBis" />
      </div>
      <h1 className="validation-title">{validationTranslation.titleBis}</h1>
      <h2 className="validation-subtitle">{validationTranslation.subtitle}</h2>
      <button
        className="btn btn--red btn-next btn-validation"
        onClick={() => navigate('/')}
      >
        {validationTranslation.btn}
      </button>
    </div>
  );
};
