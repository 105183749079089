import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ErrorContext } from '../components/views/Layouts/NormalLayout';

export const useError = () => {
  const { setError } = useContext(ErrorContext);
  const { t } = useTranslation();

  const setErr = (error: Error | string) => {
    // TODO Process the error with an ErrorCode from the backend
    if (typeof error === 'string') {
      setError(t(error));
    } else {
      setError(error.message);
    }
  };

  const clearErr = () => {
    setError(undefined);
  };
  return { setErr, clearErr };
};
