import { useTranslation } from 'react-i18next';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { Subscription } from '../../Formik/FormikContext';

import {
  displayCableSupportPrice,
  displayConnectionCablePrice,
  Pricing,
  selectSubscriptionPrice,
} from './Pricing';
import { FormatPrice } from './FormatPrice';

interface ValidationResumeProps {
  parkingInformations: Subscription;
}

export const ValidationResume = ({
  parkingInformations,
}: ValidationResumeProps) => {
  const { t } = useTranslation();

  const translation: LanguageData = t('forms.subscribe.formContent', {
    returnObjects: true,
  });

  return (
    <div className="summary">
      <span className="title important full">
        {`Place ${parkingInformations.parkingPlaceNumber}`}
      </span>
      <span className="summary--label title">{`${
        translation.subscriptionReselect.options[
          parkingInformations.subscriptionType
        ]
      }`}</span>
      <span className="summary--value title">
        <FormatPrice
          price={selectSubscriptionPrice(
            parkingInformations.subscriptionType as string
          )}
        />
        {translation.byMonth}
      </span>
      {parkingInformations.subscriptionType === 'purchase' && (
        <>
          <span className="summary--label">
            {translation.subscriptionReselect.bornPrice}
          </span>
          <span className="summary--value">
            <FormatPrice price={2120} />
          </span>
        </>
      )}
      <span className="summary--label">{translation.activationPrice}</span>
      <span className="summary--value">
        <FormatPrice price={380} />
      </span>
      {parkingInformations.options.activationCard > 1 && (
        <>
          <span className="summary--label">
            {`+ ${parkingInformations.options.activationCard - 1} ${
              translation.subscriptionReselect.activationCard
            } supplémentaire`}
          </span>
          <span className="summary--value">
            <FormatPrice
              price={
                (parkingInformations.options.activationCard - 1) *
                Pricing.activationCard
              }
            />
          </span>
        </>
      )}
      {parkingInformations.options.wallOutlet > 0 && (
        <>
          <span className="summary--label">
            {`+ ${parkingInformations.options.wallOutlet} ${translation.subscriptionReselect.wallOutlet}`}
          </span>
          <span className="summary--value">
            <FormatPrice
              price={
                parkingInformations.options.wallOutlet * Pricing.wallOutlet
              }
            />
          </span>
        </>
      )}
      {parkingInformations.options.connectionCable !== 'none' && (
        <>
          <span className="summary--label">{`+ ${translation.subscriptionReselect.connectionCable}`}</span>
          <span className="summary--value">
            <FormatPrice
              price={displayConnectionCablePrice(
                parkingInformations.options.connectionCable
              )}
            />
          </span>
        </>
      )}
      {parkingInformations.options.supportCable !== 'none' && (
        <>
          <span className="summary--label">{`+ ${translation.subscriptionReselect.cableSupport}`}</span>
          <span className="summary--value">
            <FormatPrice
              price={displayCableSupportPrice(
                parkingInformations.options.supportCable
              )}
            />
          </span>
        </>
      )}
    </div>
  );
};
