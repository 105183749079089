import React from 'react';
import { Trans } from 'react-i18next';

type BannerProps = {
  title: string;
  detail_1?: string;
  detail_2?: string;
  detail_3?: string;
  background: string;
  overlay?: boolean;
};
// Banner component show several title with an image in the background. We use it on every information static pages
export const Banner = ({
  title,
  detail_1,
  detail_2,
  detail_3,
  background,
  overlay,
}: BannerProps) => {
  return (
    <section
      className="home-banner"
      style={{
        background: `url(${background})`,
      }}
    >
      {!overlay && <div className="home-banner__overlay"></div>}
      <div className="home-banner__base max-w-section m-auto">
        <h1 className="home-banner__base__title">
          <Trans i18nKey={title} />
        </h1>
        <p className="home-banner__base__detail--1">{detail_1}</p>
        <p className="home-banner__base__detail--2">{detail_2}</p>
        <p>{detail_3}</p>
      </div>
    </section>
  );
};
