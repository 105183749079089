import React, { ChangeEvent, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { object, string } from 'yup';

import { Formik } from 'formik';

import TagManager from 'react-gtm-module';

import { FormDataKey } from '../../../libraries/sections/models/Subscriptions';

import { LanguageData } from '../../../libraries/sections/models/Common';

import {
  InvoicingAddress,
  Reason,
  ThirdPartyType,
} from '../../Formik/FormikContext';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';

import { StepperContext } from '../../Stepper';

import { FormSelect } from '../../dumps/Forms/FormSelect';

import { FormField } from '../../dumps/FormikField/FormField';

import { InputLabel } from '../../dumps/FormikField/InputLabel';

import { PhoneNumber } from '../../PhoneNumber/PhoneNumber';

import { FormSwitch } from '../../dumps/Forms/FormSwitch';

import { InvoicingFormAddress } from './InvoicingFormAddress';
import { PreviousStepResume } from './PreviousStepResume';

export const InvoicingForm = () => {
  const { data, setData } = useContext(FormikContext);
  const { onNextStep } = useContext(StepperContext);
  const { t } = useTranslation();
  const subscribeFormLangData: LanguageData = t('forms.subscribe', {
    returnObjects: true,
  });
  const errorTranslation: LanguageData = t('errorMessage', {
    returnObjects: true,
  });

  const InvoicingAddressValidation = object().shape({
    npa: string()
      .required(subscribeFormLangData.formContent.npa.validation.required)
      .min(4, subscribeFormLangData.formContent.npa.validation.min)
      .max(4, subscribeFormLangData.formContent.npa.validation.max),
    city: string().required(
      subscribeFormLangData.formContent.local.validation.required
    ),
    street: string().required(
      subscribeFormLangData.formContent.address.validation.required
    ),
    streetNumber: string().required(
      subscribeFormLangData.formContent.streetNumber.validation.required
    ),
    reason: string().oneOf([Reason.ANOTHER_ADDRESS, Reason.THIRD_PARTY]),
    thirdPartyType: string().oneOf([
      ThirdPartyType.SOMEONE,
      ThirdPartyType.COMPANY,
    ]),
    thirdPartyPayment: object().when(
      ['reason', 'thirdPartyType'],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (reason: any, thirdPartyType: any) => {
        if (reason === Reason.THIRD_PARTY) {
          return thirdPartyType === ThirdPartyType.SOMEONE
            ? object()
                .required()
                .shape({
                  lastName: string()
                    .max(60, errorTranslation.tooLong)
                    .required(errorTranslation.required),
                  firstName: string()
                    .max(60, errorTranslation.tooLong)
                    .required(errorTranslation.required),
                  mail: string()
                    .email(errorTranslation.email)
                    .max(60, errorTranslation.tooLong)
                    .required(errorTranslation.required),
                  phoneNumber: string()
                    .max(60, errorTranslation.tooLong)
                    .required(errorTranslation.required),
                  denomination: string()
                    .oneOf(['M.', 'Mme'], '')
                    .required(errorTranslation.required),
                })
            : object()
                .required()
                .shape({
                  companyName: string().required(errorTranslation.required),
                });
        } else {
          return object().notRequired().default(null).nullable();
        }
      }
    ),
  });

  // Those values are initialized manually
  // to allow adding more properties in the form
  // but keep the logic of get the parking information address by default
  const initialValues: InvoicingAddress = {
    npa:
      data.invoicingAddress && data.invoicingAddress.npa
        ? data.invoicingAddress.npa
        : data.parkingInformation.npa,
    city:
      data.invoicingAddress && data.invoicingAddress.city
        ? data.invoicingAddress.city
        : data.parkingInformation.city,
    street:
      data.invoicingAddress && data.invoicingAddress.street
        ? data.invoicingAddress.street
        : data.parkingInformation.street,
    streetNumber:
      data.invoicingAddress && data.invoicingAddress.streetNumber
        ? data.invoicingAddress.streetNumber
        : data.parkingInformation.streetNumber,
    isUseParkingAddressChecked: data.invoicingAddress
      ? data.invoicingAddress.isUseParkingAddressChecked
      : true,
    reason: data.invoicingAddress.reason,
    thirdPartyType: data.invoicingAddress.thirdPartyType,
    thirdPartyPayment: data.invoicingAddress.thirdPartyPayment,
  };

  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        content_type: 'step4-billing',
        item_id: 'form-subscription',
      },
    });
  }, []);

  return (
    <div className="page-form page-form__form">
      <PreviousStepResume propertyKey="invoicing" />
      <h2 className="page-form__form__detail title">
        {subscribeFormLangData.detail.facturation}
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={InvoicingAddressValidation}
        onSubmit={(values) => {
          setData({
            ...data,
            invoicingAddress: values,
          });
          onNextStep();
        }}
      >
        {({ handleSubmit, ...props }) => {
          const onIsUseParkingAddressCheckChange = (
            event: React.ChangeEvent<HTMLInputElement>
          ) => {
            const invoicing = {
              [FormDataKey.npa]: event.target['checked']
                ? data?.parkingInformation?.npa
                : '',
              [FormDataKey.city]: event.target['checked']
                ? data?.parkingInformation?.city
                : '',
              [FormDataKey.street]: event.target['checked']
                ? data?.parkingInformation?.street
                : '',
              [FormDataKey.streetNumber]: event.target['checked']
                ? data?.parkingInformation?.streetNumber
                : '',
              isUseParkingAddressChecked: event.target['checked'],
            };

            props.setValues({ ...props.values, ...invoicing });
          };
          return (
            <form
              onSubmit={handleSubmit}
              className="form-container"
              noValidate={true}
            >
              <div className="form-container__parking-address">
                <p className="form-container__parking-address--label">
                  Adresse que vous avez renseignée lors de la première étape :
                </p>
                <p className="form-container__parking-address--info">
                  {`${data.parkingInformation.street} ${data.parkingInformation.streetNumber}, ${data.parkingInformation.npa} ${data.parkingInformation.city}`}
                </p>
              </div>
              <FormSwitch
                name={FormDataKey.isUseParkingAddressChecked}
                value={props.values.isUseParkingAddressChecked}
                onChange={onIsUseParkingAddressCheckChange}
                label={
                  subscribeFormLangData.formContent.isUseParkingAddressChecked
                }
              />
              {!props.values.isUseParkingAddressChecked && (
                <FormField
                  inputElement={FormSelect}
                  name="reason"
                  required={false}
                  options={[
                    {
                      value: Reason.ANOTHER_ADDRESS,
                      label:
                        subscribeFormLangData.formContent.reason.anotherAddress,
                    },
                    {
                      value: Reason.THIRD_PARTY,
                      label:
                        subscribeFormLangData.formContent.reason.thirdParty,
                    },
                  ]}
                  label="Raison"
                  onChange={(event) => {
                    props.setValues({
                      reason: (event as ChangeEvent<HTMLInputElement>).target
                        .value as Reason,
                      npa: '',
                      city: '',
                      street: '',
                      streetNumber: '',
                      isUseParkingAddressChecked:
                        props.values.isUseParkingAddressChecked,
                      thirdPartyType: ThirdPartyType.SOMEONE,
                      thirdPartyPayment: {
                        denomination: 'M.',
                      },
                    });
                  }}
                />
              )}
              {!props.values.isUseParkingAddressChecked &&
                props.values.reason === Reason.THIRD_PARTY && (
                  <FormField
                    inputElement={FormSelect}
                    name="thirdPartyType"
                    required={false}
                    options={[
                      {
                        value: ThirdPartyType.SOMEONE,
                        label: 'Particulier',
                      },
                      {
                        value: ThirdPartyType.COMPANY,
                        label: 'Professionnel',
                      },
                    ]}
                    label="Type de tiers payeur"
                    onChange={(event) => {
                      props.setValues({
                        reason: props.values.reason,
                        npa: '',
                        city: '',
                        street: '',
                        streetNumber: '',
                        isUseParkingAddressChecked: false,
                        thirdPartyType: (event as ChangeEvent<HTMLInputElement>)
                          .target.value as ThirdPartyType,
                        thirdPartyPayment: {
                          denomination: 'M.',
                        },
                      });
                    }}
                  />
                )}
              {!props.values.isUseParkingAddressChecked &&
                props.values.reason === Reason.THIRD_PARTY &&
                props.values.thirdPartyType === ThirdPartyType.SOMEONE && (
                  <>
                    <InputLabel
                      className="custom-label-denomination"
                      label={subscribeFormLangData.formContent.lastName}
                      name="nom"
                      required
                    />
                    <FormField
                      classNameContentField="field__denomination"
                      inputElement={FormSelect}
                      name="thirdPartyPayment.denomination"
                      required
                      options={[
                        { value: 'M.', label: 'M.' },
                        { value: 'Mme', label: 'Mme' },
                      ]}
                    />
                    <FormField
                      classNameContentField="field__last-name"
                      className="inp"
                      inputElement="input"
                      name="thirdPartyPayment.lastName"
                      required
                    />
                    <FormField
                      className="inp"
                      inputElement="input"
                      name="thirdPartyPayment.firstName"
                      required
                      label={subscribeFormLangData.formContent.firstName}
                    />
                    <FormField
                      className="inp"
                      inputElement="input"
                      name="thirdPartyPayment.mail"
                      label={subscribeFormLangData.formContent.email}
                      required
                    />
                    <FormField
                      inputElement={PhoneNumber}
                      name="thirdPartyPayment.phoneNumber"
                      label={subscribeFormLangData.formContent.phone}
                      required
                    />
                  </>
                )}
              {!props.values.isUseParkingAddressChecked &&
                props.values.reason === Reason.THIRD_PARTY &&
                props.values.thirdPartyType === ThirdPartyType.COMPANY && (
                  <FormField
                    className="inp"
                    inputElement="input"
                    name="thirdPartyPayment.companyName"
                    required
                    label={subscribeFormLangData.formContent.companyName}
                  />
                )}
              {!props.values.isUseParkingAddressChecked && (
                <InvoicingFormAddress
                  subscribeFormLangData={subscribeFormLangData}
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  setValues={props.setValues}
                  setFieldTouched={props.setFieldTouched}
                  disabled={props.values.isUseParkingAddressChecked}
                  startIndex={9}
                />
              )}
              <div className="btn-container">
                <button
                  type="submit"
                  className="btn btn--red btn-next"
                  tabIndex={13}
                >
                  {subscribeFormLangData.formContent.next}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
