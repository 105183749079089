import React from 'react';

import { BannerSection } from '../../components/views/HomePage/BannerSection';
import { ChoicesSection } from '../../components/views/HomePage/ChoicesSection';
import { ContactSection } from '../../components/views/HomePage/ContactSection';
import { LogoCloudSection } from '../../components/views/HomePage/LogoCloudSection';

import {
  ChoiceKeys,
  ChoiceType,
} from '../../libraries/sections/models/Choices';

import { useLinks } from '../../hooks/useLinks';

import without from '../../assets/images/without-mobile.jpg';
import withParking from '../../assets/images/with-mobile.jpg';
import owner from '../../assets/images/facade-mobile.jpg';

export const HomePage = () => {
  const links = useLinks();

  const choicesList: ChoiceType[] = [
    {
      key: ChoiceKeys.USER_WITHOUT_PARKING,
      background: without,
      link: links.userWithoutParking().path,
    },
    {
      key: ChoiceKeys.USER_WITH_PARKING,
      background: withParking,
      link: links.userWithParking().path,
    },
    {
      key: ChoiceKeys.OWNER,
      background: owner,
      link: links.owner().path,
    },
  ];
  return (
    <>
      <BannerSection />
      <ChoicesSection choices={choicesList} />
      <LogoCloudSection />
      <ContactSection />
    </>
  );
};
