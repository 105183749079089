import React from 'react';
import { useTranslation } from 'react-i18next';

import { RedirectBtn } from '../../dumps/Buttons/RedirectBtn';
import { useLinks } from '../../../hooks/useLinks';

export const ContactSection = () => {
  const { t } = useTranslation();
  const links = useLinks();

  return (
    <section className="py-5 bg-normal">
      <RedirectBtn
        id="home-contact-btn"
        link={links.contact().path}
        title={t('common.contactBtn')}
      />
    </section>
  );
};
