import { useState, useEffect } from 'react';

// Hook
export const useMobileChecker = () => {
  const [isMobileDisplay, setIsMobileDisplay] = useState<boolean>(false);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set isMobileDisplay in function of the window size
      if (window.innerWidth < 990) {
        setIsMobileDisplay(true);
      } else {
        setIsMobileDisplay(false);
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return isMobileDisplay;
};
