import React from 'react';

import { ContactSection } from '../../components/views/UserWithoutParking/ContactSection';

import { BannerSection } from '../../components/views/UserWithParking/BannerSection';
import { DiscoverSection } from '../../components/views/UserWithParking/DiscoverSection';
import { StepsSection } from '../../components/views/UserWithParking/StepSection';
import { PricesSection } from '../../components/views/UserWithParking/PricesSection';
export const UserWithParking = () => {
  return (
    <>
      <BannerSection />
      <DiscoverSection />
      <StepsSection />
      <PricesSection />
      <ContactSection />
    </>
  );
};
