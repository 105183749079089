import React, { ReactNode } from 'react';

type FormSwitchProps = {
  id?: string;
  name: string;
  value?: boolean;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | ReactNode;
};

export const FormSwitch = ({
  name,
  value,
  label,
  ...restProps
}: FormSwitchProps) => {
  return (
    <div id={name} className="input-switch">
      <label htmlFor={name}>{label}</label>
      <label className="switch">
        <input
          type="checkbox"
          id={name}
          checked={value}
          name={name}
          {...restProps}
        />
        <span className="slider round" />
      </label>
    </div>
  );
};
