import React from 'react';

type RedirectProps = {
  link: string;
  title: string;
  id?: string;
};

export const RedirectBtn = ({ link, title, id }: RedirectProps) => {
  return (
    <div className="container">
      <div className="row max-w-standard mx-auto">
        <a
          id={id}
          href={link}
          rel="noreferrer"
          className="btn btn--primary redirect-btn mx-auto"
        >
          {title}
        </a>
      </div>
    </div>
  );
};
