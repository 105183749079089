import React, { lazy, useCallback, useMemo, useState, Suspense } from 'react';
import { Accordion } from 'react-bootstrap';

import PdfIcon from './../../../assets/images/pdf_file_icon.svg';

import { FAQDataType, useFaq } from './useFaq';

export const FAQList = () => {
  const [activeKey, setActiveKey] = useState('');
  const { faqListByGroup } = useFaq('');

  const handleChangeActiveKey = (newKey: string) => {
    if (activeKey === newKey) {
      setActiveKey('');
    } else {
      setActiveKey(newKey);
    }
  };

  // allow use to normalize the format of title use for testing purposes
  const normalizedKey = useCallback(
    (key: string) =>
      key
        .split(' ')[0]
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase(),
    []
  );

  const answerComponent = (component: string) => {
    return lazy(
      () => import(`./../../dumps/FAQAnswer/${component}/${component}`)
    );
  };

  type DisplayAnswerComponentType = {
    faq: FAQDataType;
  };

  const DisplayAnswerComponent = ({ faq }: DisplayAnswerComponentType) => {
    const AnswerComponent = useMemo(() => answerComponent(faq.answer), [faq]);
    return (
      <Suspense fallback={<div />}>
        <AnswerComponent />
      </Suspense>
    );
  };

  const displayAnswer = (faq: FAQDataType) => {
    if (faq.type === 'component') {
      return <DisplayAnswerComponent faq={faq} />;
    } else if (faq.link && faq.type === 'pdf') {
      return (
        <div>
          <a
            style={{ display: 'inline-flex' }}
            href={faq.link}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <img
              alt={faq.answer}
              style={{ width: '15px', marginRight: '10px' }}
              src={PdfIcon}
            />
            {faq.answer}
          </a>
        </div>
      );
    } else {
      return <div>{faq.answer}</div>;
    }
  };

  return (
    <Accordion activeKey={activeKey} className="faq-list container">
      {(Object.keys(faqListByGroup) || []).map((key: string, i: number) => (
        <div key={i} id={normalizedKey(key)} className="faq-list__group">
          <h3>{key}</h3>
          {(faqListByGroup[key] || []).map((faq: FAQDataType, j: number) => (
            <div key={j} className="faq-list__item">
              <Accordion.Collapse
                className="faq-list__item--answer"
                eventKey={`${i}${j}`}
                data-test={`faq-item-${normalizedKey(key)}-${j}`}
              >
                {displayAnswer(faq)}
              </Accordion.Collapse>
              <Accordion.Toggle
                data-test={`faq-header-${normalizedKey(key)}`}
                className="faq-list__item--question"
                key={j}
                as="button"
                eventKey={`${i}${j}`}
                onClick={() => handleChangeActiveKey(`${i}${j}`)}
              >
                <h3>
                  <span>{faq.question}</span>
                  <span>{activeKey === `${i}${j}` ? '-' : '+'}</span>
                </h3>
              </Accordion.Toggle>
            </div>
          ))}
        </div>
      ))}
    </Accordion>
  );
};
