import { useTranslation } from 'react-i18next';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { Icon } from '../../dumps/Icon/Icon';
import { Subscription } from '../../Formik/FormikContext';

interface ParkingSubscriptionResumeProps {
  onUpdate: () => void;
  onDelete?: () => void;
  parkingInformations: Subscription;
}

export const ParkingSubscriptionResume = ({
  onUpdate,
  onDelete,
  parkingInformations,
}: ParkingSubscriptionResumeProps) => {
  const { t } = useTranslation();

  const translation: LanguageData = t(
    'forms.subscribe.formContent.subscriptionReselect',
    {
      returnObjects: true,
    }
  );

  return (
    <div className="subscription-resume-container">
      <div className="parking-information-container">
        <span className="parking-number">
          {`Place ${parkingInformations.parkingPlaceNumber}`}
        </span>
        <span className="subscription-type">{`${
          translation.options[parkingInformations.subscriptionType]
        }`}</span>
      </div>
      <div className="options-container">
        {parkingInformations.options.activationCard > 1 && (
          <span>
            {`+ ${parkingInformations.options.activationCard - 1} ${t(
              'forms.subscribe.formContent.subscriptionReselect.additionalActivationCard',
              { count: parkingInformations.options.activationCard - 1 }
            )}`}
          </span>
        )}
        {parkingInformations.options.wallOutlet > 0 && (
          <span>
            {`+ ${parkingInformations.options.wallOutlet} ${translation.wallOutlet}`}
          </span>
        )}
        <span>
          {parkingInformations.options.connectionCable !== 'none' &&
            `+ ${translation.connectionCable}`}
        </span>
        <span>
          {parkingInformations.options.supportCable !== 'none' &&
            `+ ${translation.cableSupport}`}
        </span>
      </div>
      <div className="actions-container">
        <button className="actions" onClick={() => onUpdate()}>
          <span className="action-label">
            {t('forms.subscribe.button.modify')}
          </span>
          <Icon type="Pencil" />
        </button>
        {onDelete && (
          <button className="actions" onClick={() => onDelete()}>
            <span className="action-label">
              {t('forms.subscribe.button.delete')}
            </span>
            <Icon type="Cross" />
          </button>
        )}
      </div>
    </div>
  );
};
