import React from 'react';

import { useTranslation } from 'react-i18next';

import { Benefit } from '../../dumps/Benefit/Benefit';

import {
  BenefitWithoutKeys,
  BenefitType,
} from '../../../libraries/sections/models/Benefits';

const color = '#000';
export const benefitsList: BenefitType[] = [
  {
    key: BenefitWithoutKeys.FUNDED,
    icon: 'PiggyBank',
    size: '100',
    color: color,
  },
  {
    key: BenefitWithoutKeys.TURNKEY,
    icon: 'Thumbup',
    size: '80',
    color: color,
  },
  {
    key: BenefitWithoutKeys.SIMPLIFY,
    icon: 'PhoneCart',
    size: '70',
    color: color,
  },
];

export const BenefitSection = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-normal pt-50">
      <div className="benefit-section">
        {benefitsList.map((benefit: BenefitType) => (
          <Benefit
            key={benefit.key}
            color={benefit.color}
            detail={t(
              `userWithoutParkingPage.benefitSection.benefits.${benefit.key}.detail`
            )}
            icon={benefit.icon}
            size={benefit.size}
            title={t(
              `userWithoutParkingPage.benefitSection.benefits.${benefit.key}.title`
            )}
          />
        ))}
      </div>
    </section>
  );
};
