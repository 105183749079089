import React from 'react';
import { Trans } from 'react-i18next';

type CustomLinkProps = {
  link: string;
  text: string;
  required?: boolean;
  name?: string;
};

export const DataUseCustomLink = ({
  text,
  link,
  required,
  name = '',
}: CustomLinkProps) => {
  return (
    <label htmlFor={name}>
      <Trans i18nKey={text}>
        J’accepte sans réserve les conditions générales ci-dessous et autorise
        l’utilisation de mes
        <a
          style={{ textDecoration: 'underline', color: '#BA0029FF' }}
          target="_blank"
          rel="noreferrer"
          href={link}
        >
          données
        </a>
        à des fins de conseil et d’information concernant les bornes de
        recharge.
      </Trans>
      {required && <span className="cl-secondary">*</span>}
    </label>
  );
};
