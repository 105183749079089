import React from 'react';

type IncrementProps = {
  value: number;
  min?: number;
  max?: number;
  onChange: (val: number) => void;
};

export const Increment = ({ value, min, max, onChange }: IncrementProps) => {
  return (
    <div className="increment">
      <button
        type="button"
        className="increment__btn"
        disabled={(!!min || min === 0) && min === value}
        onClick={() => onChange(value - 1)}
      >
        -
      </button>
      <p style={{ flex: 3 }}>{value}</p>
      <button
        type="button"
        className="increment__btn"
        disabled={!!max && max === value}
        onClick={() => onChange(value + 1)}
      >
        +
      </button>
    </div>
  );
};
