import React from 'react';

import { BannerSection } from '../../components/views/UserWithoutParking/BannerSection';
import { DiscoverSection } from '../../components/views/UserWithoutParking/DiscoverSection';
import { BenefitSection } from '../../components/views/UserWithoutParking/BenefitSection';
import { StepsSection } from '../../components/views/UserWithoutParking/StepsSection';
import { OfferSection } from '../../components/views/UserWithoutParking/OfferSection';
import { CallToActionSection } from '../../components/views/UserWithoutParking/CallToActionSection';
import { ContactSection } from '../../components/views/UserWithoutParking/ContactSection';
export const UserWithoutParking = () => {
  return (
    <>
      <BannerSection />
      <StepsSection />
      <CallToActionSection />
      <OfferSection />
      <DiscoverSection />
      <BenefitSection />
      <ContactSection />
    </>
  );
};
