import { RoutePolicy } from '../libraries/router/models/RoutePolicy';

import { Subscriptions } from '../pages/SubscriptionsPage/Subscriptions';
import { Subscribe } from '../pages/SubscribePage/Subscribe';
import { EquipParking } from '../pages/EquipParkingPage/EquipParking';
import { FAQPage } from '../pages/FAQPage/FAQPage';
import { HomePage } from '../pages/HomePage/HomePage';
import { RouteDef } from '../libraries/router/models/RouteDef';
import { UserWithoutParking } from '../pages/UserWithoutParkingPage/UserWithoutParking';
import { UserWithParking } from '../pages/UserWithParkingPage/UserWithParking';
import { Owner } from '../pages/OwnerPage/Owner';
import { ContactPage } from '../pages/ContactPage/ContactPage';
import { QrCodePage } from '../pages/QrCodePage/QrCodePage';

import { AppLinks } from './appLinks';

const generatePublicRoutes = (routes: RouteDef[]): RouteDef[] =>
  routes.map((route: RouteDef) => ({
    ...route,
    policy: RoutePolicy.Public,
  }));

export const getAppRoutes = (links: AppLinks): RouteDef[] => {
  const publicRoutes = generatePublicRoutes([
    {
      path: links.home().path,
      key: 'home',
      component: HomePage,
      exact: true,
    },
    {
      path: links.equipParking().path,
      key: 'equipParking',
      component: EquipParking,
    },
    {
      path: links.faq().path,
      key: 'faq',
      component: FAQPage,
    },
    {
      path: links.owner().path,
      key: 'owner',
      component: Owner,
    },
    {
      path: links.contact().path,
      key: 'contact',
      component: ContactPage,
    },
    {
      path: links.qrCode().path,
      key: 'qrCode',
      component: QrCodePage,
    },
    {
      path: links.userWithParking().path,
      key: 'privateUser.subHeaderLink1',
      component: UserWithParking,
    },
    {
      path: links.userWithoutParking().path,
      key: 'privateUser.subHeaderLink2',
      component: UserWithoutParking,
    },
    {
      path: links.subscribe().path,
      key: 'subscribe',
      component: Subscribe,
    },
    {
      path: links.subscriptions().path,
      key: 'subscriptions',
      component: Subscriptions,
    },
  ]);

  return [...publicRoutes];
};
