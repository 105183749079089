import { useContext, useState } from 'react';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';

import { ParkingSubscriptionResume } from './ParkingSubscriptionResume';
import { SubscriptionForm } from './SubscriptionForm';

export const ParkingSubscriptionResumeList = () => {
  const { data, setData } = useContext(FormikContext);
  const [isUpdating, setIsUpdating] = useState(-1);

  const onDelete = (indexToDelete: number) => {
    setData({
      ...data,
      subscriptions: data.subscriptions.filter(
        (subscription, index) => index !== indexToDelete
      ),
    });
  };

  return (
    <>
      {data.subscriptions.map((subscription, index) => (
        <ParkingSubscriptionResume
          onUpdate={() => setIsUpdating(index)}
          onDelete={() => onDelete(index)}
          parkingInformations={subscription}
        />
      ))}
      <SubscriptionForm index={isUpdating} onUpdate={() => setIsUpdating(-1)} />
    </>
  );
};
