import { Resource } from 'i18next';

import { en } from './../locales/en';
import { fr } from './../locales/fr';

export const getLocalesResource = (): Resource => ({
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
});
