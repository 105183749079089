import { Formik } from 'formik';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import TagManager from 'react-gtm-module';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { EmailRegex } from '../../../libraries/helpers/validator';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';
import { FormField } from '../../dumps/FormikField/FormField';
import { InputLabel } from '../../dumps/FormikField/InputLabel';
import { FormSelect } from '../../dumps/Forms/FormSelect';
import { Coordinates } from '../../Formik/FormikContext';
import { PhoneNumber } from '../../PhoneNumber/PhoneNumber';
import { StepperContext } from '../../Stepper';

import { Tooltip } from '../../dumps/FormikField/Tooltip';

import { PreviousStepResume } from './PreviousStepResume';
import { ClientNumberTutorial } from './ClientNumberTutorial';

export const CoordinatesForm = () => {
  const { onNextStep } = useContext(StepperContext);
  const { data, setData } = useContext(FormikContext);
  const { t } = useTranslation();

  const formTranslation: LanguageData = t('forms.subscribe', {
    returnObjects: true,
  });

  const errorTranslation: LanguageData = t('errorMessage', {
    returnObjects: true,
  });

  const coordinateValidation = object().shape({
    lastName: string()
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    firstName: string()
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    mail: string()
      .matches(new RegExp(EmailRegex), errorTranslation.email)
      .email(errorTranslation.email)
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    phoneNumber: string()
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    denomination: string()
      .oneOf(['M.', 'Mme'], '')
      .required(errorTranslation.required),
    memberId: string(),
  });

  const initialValues = {
    lastName: '',
    firstName: '',
    mail: '',
    phoneNumber: '',
    denomination: 'M.',
    memberId: undefined,
  };

  const onSubmitStep = (values: Coordinates) => {
    setData({ ...data, coordinates: values });
    onNextStep();
  };

  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        content_type: 'step2-contact-info',
        item_id: 'form-subscription',
      },
    });
  }, []);
  return (
    <div className="page-form page-form__form">
      <div>
        <PreviousStepResume propertyKey="parkingInformation" />
        <h2 className="page-form__form__detail title">
          {formTranslation.detail.contact}
        </h2>
        <span>{formTranslation.describe.contact}</span>
      </div>
      <Formik
        initialValues={data.coordinates || initialValues}
        validationSchema={coordinateValidation}
        onSubmit={(v) => onSubmitStep(v)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form className="form-container" onSubmit={handleSubmit} noValidate>
            <InputLabel
              className="custom-label-denomination"
              label={formTranslation.formContent.lastName}
              name="nom"
              required
            />
            <FormField
              classNameContentField="field__denomination"
              inputElement={FormSelect}
              name="denomination"
              required
              options={[
                { value: 'M.', label: 'M.' },
                { value: 'Mme', label: 'Mme' },
              ]}
            />
            <FormField
              classNameContentField="field__last-name"
              className="inp"
              inputElement="input"
              name="lastName"
              required
            />
            <FormField
              className="inp"
              inputElement="input"
              name="firstName"
              required
              label={formTranslation.formContent.firstName}
            />
            <FormField
              className="inp"
              inputElement="input"
              name="mail"
              label={formTranslation.formContent.email}
              required
            />
            <FormField
              inputElement={PhoneNumber}
              name="phoneNumber"
              label={formTranslation.formContent.phone}
              required
            />
            <div className="icon-select-container client-number">
              <InputLabel
                required={false}
                label={formTranslation.formContent.clientNumber}
                className="client-number__label"
              />
              <div className="client-number__container">
                <span className="client-number__container__info1">
                  <b>Je suis déjà client romande énergie</b>
                </span>
                <FormField
                  className="inp"
                  inputElement="input"
                  name="memberId"
                  required={false}
                />
                <span className="client-number__container__info2">
                  Si vous n'êtes pas encore client, vous pouvez ignorer ce
                  champs
                </span>
              </div>
              <Tooltip
                className="icon-select-toolip"
                content={<ClientNumberTutorial />}
                place="right"
              />
            </div>
            <div className="btn-container">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn btn--red btn-next"
              >
                {formTranslation.button.next}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
