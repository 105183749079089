import React from 'react';
import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  Options,
  SingleValue,
  SelectComponentsConfig,
} from 'react-select';

import { ValidationType } from '../../../libraries/helpers/validator';

interface FormAutoCompleteProps<T> extends ValidationType {
  options?: Options<T>;
  onChange: (value: OnChangeValue<T, boolean>, action: ActionMeta<T>) => void;
  className: string;
  name: string;
  value?: OnChangeValue<SingleValue<T> | Options<T>, false>;
  filterOption?: (option: T, rawInput: string) => boolean;
  noOptionMessageText: string;
  disabled?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  tabIndex?: string;
  isOptionSelected?: (option: SingleValue<T> | Options<T>) => boolean;
  components?: Partial<SelectComponentsConfig<T, boolean, GroupBase<T>>>;
  // TODO Type this props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactSelectElement?: any;
  isLoading?: boolean;
  formatCreateLabel?: (inputValue: string) => string;
}

export const FormAutoComplete = <T,>({
  components = { IndicatorSeparator: () => null },
  reactSelectElement: InputElement,
  disabled,
  formatCreateLabel = (inputValue) => inputValue,
  ...props
}: FormAutoCompleteProps<T>): JSX.Element => {
  return (
    <InputElement
      {...props}
      isDisabled={disabled}
      components={components}
      formatCreateLabel={formatCreateLabel}
    />
  );
};
