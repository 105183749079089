import { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Icon } from '../Icon/Icon';

interface TooltipProps {
  content: JSX.Element;
  place?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
}
// Adapt tooltip since automatic close was bugging. It seems to be fix in a latest version
export const Tooltip = ({
  content,
  place = 'bottom',
  className,
}: TooltipProps) => {
  const [tooltip, showTooltip] = useState(true);

  return (
    <>
      {tooltip && (
        <ReactTooltip id="tooltip" effect="solid" place={place}>
          {content}
        </ReactTooltip>
      )}
      <div className={`tooltip-container ${className}`}>
        <span
          data-tip
          data-for="tooltip"
          onMouseEnter={() => showTooltip(true)}
          onMouseLeave={() => {
            showTooltip(false);
            setTimeout(() => showTooltip(true), 50);
          }}
        >
          <Icon type="Tooltip" />
        </span>
      </div>
    </>
  );
};
