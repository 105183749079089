import React from 'react';

import { Link } from 'react-router-dom';

import { useLinks } from '../../../hooks/useLinks';

type SubscribeBtnProps = {
  title: string;
  typeOfSubscription: string;
  id: string;
};

export const SubscribeBtn = ({
  title,
  typeOfSubscription,
  id,
}: SubscribeBtnProps) => {
  const links = useLinks();

  return (
    <div className="row m-auto mt-2 vertical-align">
      <Link
        to={links.subscribe().path}
        state={typeOfSubscription}
        className="subscribe-btn"
        id={id}
      >
        {title}
      </Link>
    </div>
  );
};
