const ShortCuts = {
  CHEMIN: {
    shortCuts: 'ch.',
    regex: new RegExp(/^chemin/i),
    regexFull: new RegExp(/(chemin|chemi|chem|che|ch)\.?/i),
  },
  AVENUE: {
    shortCuts: 'av.',
    regex: new RegExp(/avenue/i),
    regexFull: new RegExp(/(avenue|avenu|aven|ave|av)\.?/i),
  },
  ALLEE: {
    shortCuts: 'al.',
    regex: new RegExp(/allee/i),
    regexFull: new RegExp(/(allee|alle|all|al)\.?/i),
  },
  IMPASSE: {
    shortCuts: 'imp.',
    regex: new RegExp(/impasse/i),
    regexFull: new RegExp(/(impasse|impass|impas|impa|imp|im)\.?/i),
  },
  PASSAGE: {
    shortCuts: 'pass.',
    regex: new RegExp(/passage/i),
    regexFull: new RegExp(/(passage|passag|passa|pass|pas|pa)\.?/i),
  },
  ROUTE: {
    shortCuts: 'rte.',
    regex: new RegExp(/route/i),
    regexFull: new RegExp(/(route|rout|rou|ro|rte)\.?/i),
  },
  PLACE: {
    shortCuts: 'pl.',
    regex: new RegExp(/place/i),
    regexFull: new RegExp(/(place|plac|pla|pl)\.?/i),
  },
  SQUARE: {
    shortCuts: 'sq.',
    regex: new RegExp(/square/i),
    regexFull: new RegExp(/(square|squar|squa|sq)\.?/i),
  },
  FAUBOURG: {
    shortCuts: 'fg.',
    regex: new RegExp(/faubourg/i),
    regexFull: new RegExp(/(faubourg|faubour|faubou|faubo|faub|fau|fa)\.?/i),
  },
  BOULEVARD: {
    shortCuts: 'bd.',
    regex: new RegExp(/boulevard/i),
    regexFull: new RegExp(
      /(boulevard|boulevar|bouleva|boulev|boule|boul|bou|bo|bd)\.?/i
    ),
  },
  COURS: {
    shortCuts: 'crs.',
    regex: new RegExp(/cours/i),
    regexFull: new RegExp(/(cours|cour|cou|co|crs)\.?/i),
  },
  BATIMENT: {
    shortCuts: 'bat.',
    regex: new RegExp(/batiment/i),
    regexFull: new RegExp(/(batiment|batimen|batime|batim|bati|bat|ba)\.?/i),
  },
  SAINT: {
    shortCuts: 'st.',
    regex: new RegExp(/saint/i),
    regexFull: new RegExp(/(saint|sain|sai|sa|st)\.?/i),
  },
  SAINTE: {
    shortCuts: 'ste.',
    regex: new RegExp(/sainte/i),
    regexFull: new RegExp(/(sainte|saint|sain|sai|sa|st|ste)\.?/i),
  },
  SAINTS: {
    shortCuts: 'sts.',
    regex: new RegExp(/saints/i),
    regexFull: new RegExp(/(saints|saint|sain|sai|sa|st|sts)\.?/i),
  },
};

const titleCase = (value: string) =>
  `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

export const toStreetWithShortCuts = (value: string) => {
  const abbreviations = Object.entries(ShortCuts).map(([key, value]) => ({
    key,
    value,
  }));
  let val: string = value;
  for (const i in abbreviations) {
    val = val.replace(
      abbreviations[i].value.regex,
      abbreviations[i].value.shortCuts
    );
  }
  return titleCase(val);
};

export const isValueIncludeShortCuts = (value: string, rawInput: string) => {
  const abbreviations = Object.entries(ShortCuts).map(([key, value]) => ({
    key,
    value,
  }));

  return !!abbreviations.find(
    (abbreviation) =>
      rawInput.search(abbreviation.value.regexFull) !== -1 &&
      value
        .toLocaleLowerCase()
        .includes(
          rawInput
            .toLocaleLowerCase()
            .replace(abbreviation.value.regexFull, abbreviation.value.shortCuts)
        )
  );
};
