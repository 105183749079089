import React from 'react';

import { Increment } from './Increment';
import { FormCheckbox } from './FormCheckbox';

type IncrementWithCheckBoxProps = {
  name: string;
  value: number;
  onChange: (val: number) => void;
  labelInfo: string;
  className: string;
};

export const IncrementWithCheckBox = ({
  name,
  value,
  onChange,
  labelInfo,
  className = '',
}: IncrementWithCheckBoxProps): JSX.Element => {
  return (
    <div className={className}>
      <Increment value={value} onChange={onChange} min={0} />
      <FormCheckbox
        name={name}
        value={value === 0}
        onChange={() => onChange(0)}
        className={`checkbox ${className}__info`}
        label={labelInfo}
      />
    </div>
  );
};
