import React, { useCallback, useReducer } from 'react';

import { FormikErrors, FormikTouched } from 'formik';

import { AxiosResponse } from 'axios';

import { useTranslation } from 'react-i18next';

import { Options } from 'react-select';

import { FormDataKey } from '../../../libraries/sections/models/Subscriptions';

import { LanguageData } from '../../../libraries/sections/models/Common';

import { api } from '../../../Api';

import { InvoicingAddress } from '../../Formik/FormikContext';

import { useError } from '../../../hooks/useError';

import { FormAddress, FormAddressType } from './FormAddress';

type AxiosResponseCity = {
  inZone: boolean;
  name: string;
  postcode: number;
};

type InvoicingFormAddressProps = {
  subscribeFormLangData: LanguageData;
  values: InvoicingAddress;
  errors: FormikErrors<{
    npa?: string;
    city?: string;
    street?: string;
    streetNumber?: string;
  }>;
  touched: FormikTouched<{
    npa?: boolean;
    city?: boolean;
    street?: boolean;
    streetNumber?: boolean;
  }>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  setValues: (
    values: React.SetStateAction<InvoicingAddress>,
    shouldValidate?: boolean
  ) => void;
  disabled: boolean;
  startIndex: number;
};

type ReducerStateProps = {
  cities: Options<{ label: string; value: string }>;
  streets: Options<{ label: string; value: string }>;
  npa: string;
  city: string;
  isFetchingCity: boolean;
  isFetchingStreet: boolean;
};

type ActionProps = {
  type: string;
  payload: Partial<ReducerStateProps>;
};

enum DispatchReducer {
  UPDATE_CITIES = 'UPDATE_CITIES',
  UPDATE_STREETS = 'UPDATE_STREETS',
  UPDATE_NPA = 'UPDATE_NPA',
  UPDATE_CITY = 'UPDATE_CITY',
  UPDATE_FETCHING_CITY = 'UPDATE_FETCHING_CITY',
  UPDATE_FETCHING_STREET = 'UPDATE_FETCHING_STREET',
  UPDATE_CITIES_AND_STREETS = 'UPDATE_CITIES_AND_STREETS',
}

const reducer = (state: Partial<ReducerStateProps>, action: ActionProps) => {
  switch (action.type) {
    case DispatchReducer.UPDATE_CITIES:
      return {
        ...state,
        cities: action.payload.cities,
      };
    case DispatchReducer.UPDATE_STREETS:
      return {
        ...state,
        streets: action.payload.streets,
      };
    case DispatchReducer.UPDATE_NPA:
      return {
        ...state,
        npa: action.payload.npa,
      };
    case DispatchReducer.UPDATE_CITY:
      return {
        ...state,
        city: action.payload.city,
      };
    case DispatchReducer.UPDATE_FETCHING_CITY:
      return {
        ...state,
        isFetchingCity: action.payload.isFetchingCity,
      };
    case DispatchReducer.UPDATE_FETCHING_STREET:
      return {
        ...state,
        isFetchingStreet: action.payload.isFetchingStreet,
      };
    default:
      return state;
  }
};

const initialState = {
  cities: [],
  streets: [],
  npa: '',
  city: '',
  isFetchingCity: false,
  isFetchingStreet: false,
};

export const InvoicingFormAddress = ({
  subscribeFormLangData,
  values,
  setValues,
  setFieldTouched,
  ...restProps
}: InvoicingFormAddressProps) => {
  const { t } = useTranslation();
  const { setErr } = useError();
  const [state, dispatch] = useReducer(reducer, initialState);

  const onBlurCity = useCallback(
    (npa?: string, city?: string) => {
      if (npa && city) {
        dispatch({
          type: DispatchReducer.UPDATE_FETCHING_STREET,
          payload: {
            isFetchingStreet: true,
          },
        });
        api
          .get(`api/addresses/cities/${npa}/streets/`)
          .then(function (response: AxiosResponse<string[]>) {
            if (response && response.data) {
              dispatch({
                type: DispatchReducer.UPDATE_STREETS,
                payload: {
                  streets: response.data
                    .sort((streetA, streetB) => streetA.localeCompare(streetB))
                    .map((street) => {
                      return { value: 'street', label: street };
                    }),
                },
              });
            } else {
              setErr(t('errorMessage.unexpectedError'));
            }
          })
          .catch((error) => setErr(error))
          .finally(() => {
            dispatch({
              type: DispatchReducer.UPDATE_FETCHING_STREET,
              payload: {
                isFetchingStreet: false,
              },
            });
          });
      } else {
        dispatch({
          type: DispatchReducer.UPDATE_STREETS,
          payload: {
            streets: [],
          },
        });
      }
    },
    [setErr, t]
  );

  const onBlurNpa = useCallback(
    (npa?: string) => {
      if (npa && parseInt(npa) >= 1000) {
        dispatch({
          type: DispatchReducer.UPDATE_FETCHING_CITY,
          payload: {
            isFetchingCity: true,
          },
        });
        api
          .get(`api/addresses/cities/?postcode=${npa}`)
          .then(function (response: AxiosResponse<AxiosResponseCity[]>) {
            if (response && response.data) {
              dispatch({
                type: DispatchReducer.UPDATE_CITIES,
                payload: {
                  cities: response.data
                    .sort((cityA, cityB) =>
                      cityA.name.localeCompare(cityB.name)
                    )
                    .map((city) => {
                      return { value: 'city', label: city.name };
                    }),
                },
              });
            } else {
              setErr(t('errorMessage.unexpectedError'));
            }
          })
          .catch((error) => setErr(error))
          .finally(() => {
            dispatch({
              type: DispatchReducer.UPDATE_FETCHING_CITY,
              payload: {
                isFetchingCity: false,
              },
            });
          });
      } else {
        dispatch({
          type: DispatchReducer.UPDATE_CITIES_AND_STREETS,
          payload: {
            cities: [],
            streets: [],
          },
        });
      }
    },
    [setErr, t]
  );

  const updateFieldsValue = (field: string, value: string) => {
    const dataToUpdate: { [key: string]: string | number } = {};
    if (field === FormDataKey.npa) {
      dataToUpdate[field] = value;
      dataToUpdate[FormDataKey.city] = '';
      dataToUpdate[FormDataKey.street] = '';
      dataToUpdate[FormDataKey.streetNumber] = '';
      dispatch({
        type: DispatchReducer.UPDATE_NPA,
        payload: {
          npa: value,
        },
      });
    } else if (field === FormDataKey.city) {
      dataToUpdate[FormDataKey.city] = value;
      dataToUpdate[FormDataKey.street] = '';
      dataToUpdate[FormDataKey.streetNumber] = '';
      dispatch({
        type: DispatchReducer.UPDATE_CITY,
        payload: {
          city: value,
        },
      });
    } else if (field === FormDataKey.street) {
      dataToUpdate[FormDataKey.street] = value;
      dataToUpdate[FormDataKey.streetNumber] = '';
    } else {
      dataToUpdate[FormDataKey.streetNumber] = value;
    }
    setValues({ ...values, ...dataToUpdate });
  };

  return (
    <>
      <FormAddress<FormAddressType>
        componentType="invoicingInfo"
        setFieldValue={updateFieldsValue}
        subscribeFormLangData={subscribeFormLangData}
        values={values}
        cities={state.cities}
        streets={state.streets}
        onBlurNpa={() => {
          setFieldTouched(FormDataKey.npa, true);
          onBlurNpa(values.npa);
        }}
        onBlurCity={() => {
          setFieldTouched(FormDataKey.city, true);
          onBlurCity(values.npa, values.city);
        }}
        isCityLoading={state.isFetchingCity}
        isStreetLoading={state.isFetchingStreet}
        {...restProps}
      />
    </>
  );
};
