import React from 'react';

import NavDropdown from 'react-bootstrap/NavDropdown';

import { useRouter } from '../../../hooks/useRouter';
type DropDownItemComponentProp = {
  link: string;
  dataTest: string;
  title: string;
};

export const DropDownItemComponent = ({
  link,
  dataTest,
  title,
}: DropDownItemComponentProp) => {
  const { pathname } = useRouter();
  const checkThePathname = (linkPath: string) =>
    linkPath.length === 1
      ? linkPath === pathname
      : new RegExp('^/(' + linkPath.substring(1) + ')/*').test(pathname);

  return (
    <NavDropdown.Item
      href={link}
      data-test={`menu-link-${dataTest}`}
      id={`menu-link-${dataTest}`}
      className={`${checkThePathname(link) ? 'active' : ''}`}
    >
      {title}
    </NavDropdown.Item>
  );
};
