import React, { CSSProperties, ReactNode } from 'react';

import { useField } from 'formik';

import {
  ActionMeta,
  GroupBase,
  OnChangeValue,
  Options,
  SingleValue,
  SelectComponentsConfig,
} from 'react-select';

import { InputBase } from './InputBase';
import { InputHint } from './InputHint';
import { InputLabel } from './InputLabel';

type FormFieldProps<T = unknown> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputElement: 'input' | 'select' | ((props: any) => JSX.Element);
  // TODO Type this props
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactSelectElement?: any;
  name: string;
  label?: string | ReactNode;
  required: boolean;
  value?: SingleValue<T> | string | number | boolean;
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | OnChangeValue<T, boolean>,
    action?: ActionMeta<T>
  ) => void;
  error?: string;
  touched?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
  maxLength?: number;
  type?: 'text' | 'number';
  disabled?: boolean;
  tabIndex?: number;
  filterOption?: (option: T, rawInput: string) => boolean;
  options?: Options<T>;
  className?: string;
  classNamePrefix?: string;
  classNameInputField?: string;
  classNameContentField?: string;
  classNameLabelField?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  noOptionMessageText?: string;
  onFocus?: () => void;
  style?: CSSProperties;
  isOptionSelected?: (option: SingleValue<T> | Options<T>) => boolean;
  isLoading?: boolean;
  components?: Partial<SelectComponentsConfig<T, boolean, GroupBase<T>>>;
  info?: string;
  icon?: string;
  innerLabel?: string;
  isCheckBox?: boolean;
  labelInfo?: string;
  displayError?: boolean;
};

export const FormField = <T,>({
  inputElement,
  name,
  label,
  required,
  classNameInputField = '',
  classNameContentField = '',
  classNameLabelField = '',
  isCheckBox = false,
  displayError = true,
  ...inputProps
}: FormFieldProps<T>) => {
  const [field, meta] = useField({ name });
  return (
    <div
      className={`common-input field ${classNameContentField}`}
      style={inputProps.style}
    >
      {!isCheckBox && (
        <InputLabel
          className={classNameLabelField}
          label={label}
          name={name}
          required={required}
        />
      )}
      <div
        className={`common-input__${
          isCheckBox ? 'checkbox' : 'input'
        } ${classNameInputField}`}
      >
        <InputBase
          {...field}
          name={name}
          required={required}
          inputElement={inputElement}
          label={inputProps.innerLabel}
          {...inputProps}
        />
        {!isCheckBox && displayError && (
          <InputHint {...meta} className="error" />
        )}
      </div>
      {isCheckBox && (
        <div className={classNameLabelField}>
          <InputLabel label={label} name={name} required={required} />
          {displayError && <InputHint {...meta} className="error" />}
        </div>
      )}
    </div>
  );
};
