import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';

import { useLinks } from '../../../hooks/useLinks';

import { NavLinkComponent } from './NavLinkComponent';

import logo from './../../../assets/images/logo.png';
import { HamburgerButton } from './HamburgerButton';
import { DropDownItemComponent } from './DropDownItemComponent';

interface ILink {
  name: string;
  path: string;
  dataTest: string;
}
interface IMenu extends ILink {
  subHeader?: ILink[];
}

export const Header = () => {
  const links = useLinks();
  const { t } = useTranslation();
  const headerData: IMenu[] = [
    {
      name: t('common.routes.home.name'),
      path: links.home().path,
      dataTest: 'home',
    },
    {
      name: t('common.routes.privateUser.name'),
      path: '',
      dataTest: 'private',
      subHeader: [
        {
          name: t('common.routes.privateUser.subHeaderLink1.name'),
          path: links.userWithoutParking().path,
          dataTest: 'userWithoutParking',
        },
        {
          name: t('common.routes.privateUser.subHeaderLink2.name'),
          path: links.userWithParking().path,
          dataTest: 'userWithParking',
        },
      ],
    },
    {
      name: t('common.routes.owner.name'),
      path: links.owner().path,
      dataTest: 'owner',
    },
    {
      name: t('common.routes.faq.name'),
      path: links.faq().path,
      dataTest: 'faq',
    },
    {
      name: t('common.routes.contact.name'),
      path: links.contact().path,
      dataTest: 'contact',
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Navbar bg="white" expand="lg" fixed="top" className="shadow-sm">
      <Container className="container_nav">
        <Navbar.Brand href={links.home().path}>
          <img className="brand" alt="Romande energie" src={logo} />
        </Navbar.Brand>
        <div className="d-flex">
          {/* contact link on mobile */}
          <a
            className="contact_link_mobile"
            href={links.contact().path}
            data-test="menu-link-contact-mobile"
          >
            <Icon type="Contact" size="30" color="#fff" />
          </a>

          {/* hamburger on mobile */}
          <button
            aria-controls="basic-navbar-nav"
            type="button"
            aria-label="Toggle navigation"
            className={`navbar-toggler collapsed header__toggle-btn action-btn ${
              isOpen ? 'show' : ''
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <HamburgerButton className={isOpen ? 'arrow' : 'hamburger'} />
          </button>
        </div>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={`justify-content-end navbar-collapse collapse ${
            isOpen ? 'show' : ''
          }`}
        >
          <Nav>
            {headerData.map((header, headerDataIndex) => (
              <React.Fragment key={headerDataIndex}>
                {header.path && (
                  <NavLinkComponent
                    link={header.path}
                    title={header.name}
                    dataTest={header.dataTest}
                  />
                )}
                {header.subHeader && (
                  <NavDropdown
                    title={header.name}
                    id="basic-nav-dropdown"
                    className="nav-link-default"
                  >
                    {header.subHeader.map((subHeader, subHeaderIndex) => (
                      <React.Fragment key={subHeaderIndex}>
                        <DropDownItemComponent
                          link={subHeader.path}
                          title={subHeader.name}
                          dataTest={subHeader.dataTest}
                        />
                      </React.Fragment>
                    ))}
                  </NavDropdown>
                )}
              </React.Fragment>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
