import React from 'react';

import { useTranslation } from 'react-i18next';

import { Banner } from '../../dumps/Banner/Banner';
import { DoYouKnow } from '../../dumps/DoYouKnow/DoYouKnow';

import UserWithParking from './../../../assets/images/banner1.png';
export const BannerSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Banner
        title={t('homePage.banner.title')}
        detail_1={t('homePage.banner.detail1')}
        detail_2={t('homePage.banner.detail2')}
        background={UserWithParking}
      />
      <DoYouKnow />
    </>
  );
};
