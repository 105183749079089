export enum PageName {
  SUBSCRIBE = 'subscribe-modal',
  CALENDLY = 'calendly',
}

export type LanguageData = {
  // eslint-disable-next-line
  [key in string]: string | any;
};

export type PageData = {
  // eslint-disable-next-line
  [key in string]: string | any;
};

export enum ErrorKey {
  largerParkingLot = 'largerParkingLot',
}
