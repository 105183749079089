import { Formik } from 'formik';
import { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { number, object, string } from 'yup';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { FormikContext } from '../../../pages/SubscribePage/Subscribe';
import { FormField } from '../../dumps/FormikField/FormField';
import { Tooltip } from '../../dumps/FormikField/Tooltip';
import { FormIncrement } from '../../dumps/Forms/FormIncrement';
import { FormSelect } from '../../dumps/Forms/FormSelect';
import { Icon } from '../../dumps/Icon/Icon';
import {
  CableType,
  Subscription,
  SupportType,
} from '../../Formik/FormikContext';
import { StepperContext } from '../../Stepper';

import { ConnectionCableTutorial } from './ConnectionCableTutorial';
import { Pricing } from './Pricing';

interface SubscriptionFormProps {
  index?: number;
  onUpdate: () => void;
}

export const SubscriptionForm = ({
  index = 0,
  onUpdate,
}: SubscriptionFormProps) => {
  const { data, setData } = useContext(FormikContext);
  const { onNextStep } = useContext(StepperContext);
  const { t } = useTranslation();

  const formTranslation: LanguageData = t('forms.subscribe.formContent', {
    returnObjects: true,
  });

  const errorTranslation: LanguageData = t('errorMessage', {
    returnObjects: true,
  });

  const { state } = useLocation();

  const initialValue = {
    subscriptionType: state ? state : 'rent',
    parkingPlaceNumber: '',
    options: {
      connectionCable: 'none',
      supportCable: 'none',
      activationCard: 1,
      wallOutlet: 0,
    },
  };
  const onSubmitForm = (
    v: Subscription,
    resetForm: () => void,
    onNext?: () => void
  ) => {
    if (v.parkingPlaceNumber) {
      setData({
        ...data,
        subscriptions:
          index >= 0
            ? data.subscriptions.map((subscription, i) =>
                index === i ? v : subscription
              )
            : [...data.subscriptions, v],
      });
      if (window) {
        window.scrollTo(0, 0);
      }
      onUpdate();
      resetForm();
    }
    if (onNext) {
      onNext();
    }
  };

  const subscriptionValidation = object().shape({
    subscriptionType: string()
      .oneOf(['rent', 'purchase'], '')
      .required(errorTranslation.required),
    parkingPlaceNumber: number()
      .typeError(errorTranslation.parkingPlaceNumberType)
      .max(99999, errorTranslation.numberTooLong)
      .required(errorTranslation.required),
    options: object().shape({
      connectionCable: string()
        .nullable()
        .oneOf([...Object.values(CableType), 'none']),
      supportCable: string()
        .nullable()
        .oneOf([...Object.values(SupportType), 'none']),
      activationCard: number().required(errorTranslation.required),
      wallOutlet: number().required(errorTranslation.required),
    }),
  });
  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        content_type: 'step3-subscription',
        item_id: 'form-subscription',
      },
    });
  }, []);

  return (
    <Formik
      initialValues={data?.subscriptions[index] || initialValue}
      validationSchema={subscriptionValidation}
      onSubmit={(v, { resetForm }) => onSubmitForm(v, resetForm)}
      enableReinitialize
      validateOnMount
    >
      {({ setFieldValue, handleSubmit, values, resetForm, isValid }) => (
        <>
          <form className="form-container" noValidate onSubmit={handleSubmit}>
            <FormField
              label={formTranslation.subscriptionReselect.parkingPlaceNumber}
              className="inp"
              inputElement="input"
              name="parkingPlaceNumber"
              required
            />
            <FormField
              label={formTranslation.subscriptionReselect.subscribe}
              inputElement={FormSelect}
              name="subscriptionType"
              required
              options={[
                {
                  value: 'rent',
                  label: formTranslation.subscriptionReselect.options.rent,
                },
                {
                  value: 'purchase',
                  label: formTranslation.subscriptionReselect.options.purchase,
                },
              ]}
            />
            <FormField
              classNameContentField="field__activation_card"
              inputElement={FormIncrement}
              innerLabel={formTranslation.subscriptionReselect.activationCard}
              icon="Badge"
              name="options.activationCard"
              value={values.options.activationCard}
              onChange={(value) =>
                setFieldValue('options.activationCard', value)
              }
              info={
                values.options.activationCard > 1
                  ? `${formTranslation.chf} ${
                      (values.options.activationCard - 1) *
                      Pricing.activationCard
                    }`
                  : `(${formTranslation.options.included})`
              }
              required={false}
              max={10}
              min={1}
            />
            <FormField
              classNameContentField="field__wall_outlet"
              inputElement={FormIncrement}
              innerLabel={formTranslation.subscriptionReselect.wallOutlet}
              icon="Plug"
              name="options.wallOutlet"
              value={values.options.wallOutlet}
              onChange={(value) => setFieldValue('options.wallOutlet', value)}
              info={
                values.options.wallOutlet > 0
                  ? `${formTranslation.chf} ${
                      values.options.wallOutlet * Pricing.wallOutlet
                    }`
                  : `(${formTranslation.options.notIncluded})`
              }
              required={false}
              max={1}
              min={0}
            />
            <div className="icon-select-container">
              <Icon type="Cable" className="icon-select-icon" />
              <FormField
                inputElement={FormSelect}
                classNameContentField="field__icon_select"
                name="options.connectionCable"
                required
                options={[
                  { value: 'none', label: formTranslation.cables.default },
                  {
                    value: CableType.STANDARD22,
                    label: formTranslation.cables.standardType1,
                  },
                  {
                    value: CableType.LONG22,
                    label: formTranslation.cables.longType1,
                  },
                  {
                    value: CableType.EXTRALONG22,
                    label: formTranslation.cables.extraLongType1,
                  },
                  {
                    value: CableType.TWISTED22,
                    label: formTranslation.cables.twistedType1,
                  },
                  {
                    value: CableType.STANDARD21,
                    label: formTranslation.cables.standardType2,
                  },
                ]}
              />
              <Tooltip
                className="icon-select-toolip"
                content={<ConnectionCableTutorial />}
                place="right"
              />
            </div>

            <div className="icon-select-container">
              <Icon type="SupportCable" className="icon-select-icon" />
              <FormField
                inputElement={FormSelect}
                classNameContentField="field__icon_select"
                name="options.supportCable"
                required
                options={[
                  {
                    value: 'none',
                    label: formTranslation.supportCable.default,
                  },
                  {
                    value: SupportType.CLASSIC,
                    label: formTranslation.supportCable.classic,
                  },
                  {
                    value: SupportType.PREMIUM,
                    label: formTranslation.supportCable.premium,
                  },
                ]}
              />
            </div>

            <button
              type="submit"
              className="btn btn--secondary btn-next btn_add_subscription"
            >
              {t(
                `forms.subscribe.button.${
                  index === -1 ? 'addSubscription' : 'modify'
                }`
              )}
            </button>
          </form>
          <div className="btn-container-subscription">
            <button
              onClick={() => onSubmitForm(values, resetForm, onNextStep)}
              className="btn btn--red btn-next"
              disabled={data?.subscriptions.length < 1 && !isValid}
            >
              {t('forms.subscribe.button.next')}
            </button>
          </div>
        </>
      )}
    </Formik>
  );
};
