import React from 'react';

type HamburgerButtonPropType = {
  className: string;
};

export const HamburgerButton = ({ className }: HamburgerButtonPropType) => {
  return (
    <div
      data-test="mobile-hamburger"
      className={`threebar hamburger ${className}`}
    >
      <div className="bar gap" />
      <div className="bar" />
      <div className="bar gap" />
    </div>
  );
};
