import React, { useContext, useEffect, useState } from 'react';

import { Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { Trans, useTranslation } from 'react-i18next';

import { AxiosResponse } from 'axios';

import TagManager from 'react-gtm-module';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { StepperContext } from '../../Stepper';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';

import { ParkingInformation } from '../../Formik/FormikContext';

import { useLinks } from '../../../hooks/useLinks';

import { api } from '../../../Api';

import { useError } from '../../../hooks/useError';

import { Icon } from '../../dumps/Icon/Icon';

import { ParkingInformationFormAddress } from './ParkingInformationFormAddress';

export const ParkingInformationForm = () => {
  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        content_type: 'step1-pkg-eligibility',
        item_id: 'form-subscription',
      },
    });
  }, []);
  const { t } = useTranslation();
  const { data, setData } = useContext(FormikContext);
  const { onNextStep } = useContext(StepperContext);
  const { equipParking } = useLinks();

  const [displayCheckANewAddress, setDisplayCheckANewAddress] =
    useState<boolean>(false);
  const { setErr } = useError();

  const subscribeFormLangData: LanguageData = t('forms.subscribe', {
    returnObjects: true,
  });
  const InformationParkingValidation = object().shape({
    npa: string()
      .required(subscribeFormLangData.formContent.npa.validation.required)
      .min(4, subscribeFormLangData.formContent.npa.validation.min)
      .max(4, subscribeFormLangData.formContent.npa.validation.max),
    city: string().required(
      subscribeFormLangData.formContent.local.validation.required
    ),
    street: string().required(
      subscribeFormLangData.formContent.address.validation.required
    ),
    streetNumber: string().required(
      subscribeFormLangData.formContent.streetNumber.validation.required
    ),
  });

  const onHandleSubmit = (
    values: ParkingInformation,
    action: FormikHelpers<ParkingInformation>
  ) => {
    api
      .post('api/web/parking-lot/subscribe/validate/', values)
      .then(function (response: AxiosResponse) {
        if (response.data && response.data.isValid) {
          setData({
            ...data,
            parkingInformation: {
              ...values,
            },
          });
          if (response.data.equipmentDealId) {
            api
              .get(
                `api/web/parking-lot/equipment-deal?deal_id=${response.data.equipmentDealId}`
              )
              .then(function (equipementDealResp) {
                if (equipementDealResp.data) {
                  setData({
                    ...data,
                    parkingInformation: {
                      ...values,
                    },
                    equipmentDealId: equipementDealResp.data.isValid
                      ? response.data.equipmentDealId
                      : undefined,
                  });
                } else {
                  setErr(t('errorMessage.unexpectedError'));
                }
              })
              .catch((error) => setErr(error));
          }
          onNextStep();
        } else {
          setErr(t('errorMessage.unexpectedError'));
          action.setSubmitting(false);
        }
      })
      .catch((error) => {
        setErr(error);
        action.setSubmitting(false);
      });
  };

  const onHandleReset = () => {
    setData({
      ...data,
      parkingInformation: { npa: '', city: '', street: '', streetNumber: '' },
    });
    setDisplayCheckANewAddress(false);
  };

  return (
    <div className="page-form page-form__form">
      <h2 className="page-form__form__title">
        {subscribeFormLangData.formContent.checkYourParkingEligibility}
      </h2>
      <h2 className="page-form__form__info">
        {subscribeFormLangData.formContent.fillYourAddress}
      </h2>
      <h2 className="page-form__form__detail title">
        {subscribeFormLangData.detail.contact}
      </h2>
      <Formik
        initialValues={data?.parkingInformation}
        validationSchema={InformationParkingValidation}
        onSubmit={onHandleSubmit}
        onReset={onHandleReset}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleReset, isSubmitting, ...props }) => {
          return (
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="form-container"
              noValidate={true}
            >
              <ParkingInformationFormAddress
                {...props}
                subscribeFormLangData={subscribeFormLangData}
                setDisplayCheckANewAddress={setDisplayCheckANewAddress}
              />
              <div
                className={`${
                  displayCheckANewAddress
                    ? 'btn-check-new-address'
                    : 'btn-container'
                }`}
              >
                <button
                  type={displayCheckANewAddress ? 'reset' : 'submit'}
                  disabled={!displayCheckANewAddress && isSubmitting}
                  className={
                    displayCheckANewAddress
                      ? 'btn btn-check-eligibility'
                      : 'btn btn--red btn-next'
                  }
                >
                  {displayCheckANewAddress
                    ? subscribeFormLangData.formContent.checkANewAddress
                    : subscribeFormLangData.formContent.checkMyEligibility}
                </button>
              </div>
              {displayCheckANewAddress && (
                <div className="click-and-charge-not-installed">
                  <Icon type="Electricity" />
                  <div>
                    <p className="click-and-charge-not-installed__title">
                      {
                        subscribeFormLangData.formContent
                          .clickAndChargeNotIntstalledYet
                      }
                    </p>
                    <br />
                    <Trans i18nKey="forms.subscribe.formContent.contactUs">
                      <p className="click-and-charge-not-installed__detail">
                        <a href={equipParking().path}>
                          Prenez contact avec nous
                        </a>{' '}
                        afin de discuter de votre projet.
                      </p>
                    </Trans>
                  </div>
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
