import { CableType, SupportType } from '../../Formik/FormikContext';
import { SubscriptionType } from '../../../libraries/sections/models/Subscriptions';

export enum Pricing {
  rent = 52,
  purchase = 21,
  standard22ConnectionCable = 295,
  long22ConnectionCable = 355,
  twisted22ConnectionCable = 395,
  standard21ConnectionCable = 295,
  extraLong22ConnectionCable = 385,
  activationCard = 7.5,
  cableSupportClassic = 35,
  cableSupportEasee = 95,
  wallOutlet = 65,
  none = 0,
}

export const displayConnectionCablePrice = (connectionCable: CableType) => {
  switch (connectionCable) {
    case CableType.STANDARD22:
      return Pricing.standard22ConnectionCable;
    case CableType.LONG22:
      return Pricing.long22ConnectionCable;
    case CableType.TWISTED22:
      return Pricing.twisted22ConnectionCable;
    case CableType.STANDARD21:
      return Pricing.standard21ConnectionCable;
    case CableType.EXTRALONG22:
      return Pricing.extraLong22ConnectionCable;
    case CableType.NONE:
      return Pricing.none;
    default:
      return Pricing.twisted22ConnectionCable;
  }
};

export const displayCableSupportPrice = (supportCable: SupportType) => {
  switch (supportCable) {
    case SupportType.CLASSIC:
      return Pricing.cableSupportClassic;
    case SupportType.PREMIUM:
      return Pricing.cableSupportEasee;
    case SupportType.NONE:
      return Pricing.none;
    default:
      return Pricing.cableSupportEasee;
  }
};

export const selectSubscriptionPrice = (subscriptionType: string) =>
  subscriptionType === SubscriptionType.RENT ? Pricing.rent : Pricing.purchase;
