import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Error } from '../../dumps/Error/Error';
import { FAQPage } from '../../../pages/FAQPage/FAQPage';
import { Footer } from '../../dumps/Footer/Footer';
import { Header } from '../../dumps/Header/Header';
import { HomePage } from '../../../pages/HomePage/HomePage';
import { RouteDef } from '../../../libraries/router/models/RouteDef';
import { Subscribe } from '../../../pages/SubscribePage/Subscribe';
import { Subscriptions } from '../../../pages/SubscriptionsPage/Subscriptions';
import { UserWithoutParking } from '../../../pages/UserWithoutParkingPage/UserWithoutParking';
import { UserWithParking } from '../../../pages/UserWithParkingPage/UserWithParking';
import { useRouter } from '../../../hooks/useRouter';
import { useRoutes } from '../../../hooks/useRoutes';

import './Layout.scss';
import { Owner } from '../../../pages/OwnerPage/Owner';
import { EquipParking } from '../../../pages/EquipParkingPage/EquipParking';
import { ContactPage } from '../../../pages/ContactPage/ContactPage';
import { QrCodePage } from '../../../pages/QrCodePage/QrCodePage';

interface ErrorContextProps {
  error?: string;
  setError: Dispatch<SetStateAction<string | undefined>>;
}

export const ErrorContext = createContext<ErrorContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setError: () => {},
});

export const NormalLayout = () => {
  const routes: RouteDef[] = useRoutes();
  const { pathname } = useRouter();
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();

  const routeKey = useMemo(
    () => routes.find((route: RouteDef) => route.path === pathname),
    [routes, pathname]
  );

  return (
    <>
      <Helmet>
        <title>
          {t(`common.routes.${routeKey?.key || ''}.title`)} -{' '}
          {t('common.footer.contact.name')}
        </title>
        <meta
          name="facebook-domain-verification"
          content="y95o1zpxbgh8or6x1yt9fgnds76zqv"
        />
      </Helmet>
      <Header />
      <main>
        <ErrorContext.Provider value={{ error, setError }}>
          <Error />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/abonnements" element={<Subscriptions />} />
            <Route path="/abonnements/souscrire" element={<Subscribe />} />
            <Route path="/equiper-parking" element={<EquipParking />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/qrcode_borne" element={<QrCodePage />} />
            <Route path="/parking-nonequipe" element={<UserWithoutParking />} />
            <Route path="/parking-equipe" element={<UserWithParking />} />
            <Route path="/acteur-immobilier" element={<Owner />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        </ErrorContext.Provider>
      </main>
      <Footer />
    </>
  );
};
