import React, { createContext, useState } from 'react';

import { AxiosResponse } from 'axios';

import { useTranslation } from 'react-i18next';

import {
  FormikContextProps,
  Reason,
  SubscriptionData,
  ThirdPartyType,
} from '../../components/Formik/FormikContext';
import { Stepper } from '../../components/Stepper';
import { api } from '../../Api';
import { SubscribeForm } from '../../components/views/PageForms/SubscribeForm';
import { useError } from '../../hooks/useError';
import { CompletedStep } from '../../components/views/PageForms/CompletedStep';
import { ActiveSubscribeForm } from '../../libraries/sections/models/Subscriptions';

export const FormikContext = createContext<
  FormikContextProps<SubscriptionData>
>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmitForm: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setData: () => {},
  data: {
    parkingInformation: {},
    coordinates: {},
    invoicingAddress: {
      isUseParkingAddressChecked: true,
      reason: Reason.ANOTHER_ADDRESS,
      thirdPartyType: ThirdPartyType.SOMEONE,
    },
    isUseDataCommercialChecked: false,
    isConsentChecked: false,
    subscriptions: [],
    calendlyUrl: '',
    utmContent: '',
  },
});

export const Subscribe = () => {
  const [data, setData] = useState<SubscriptionData>({
    parkingInformation: {
      npa: '',
      city: '',
      street: '',
      streetNumber: '',
    },
    coordinates: {
      firstName: '',
      lastName: '',
      mail: '',
      phoneNumber: '',
      denomination: 'M.',
    },
    invoicingAddress: {
      npa: '',
      city: '',
      street: '',
      streetNumber: '',
      isUseParkingAddressChecked: true,
      reason: Reason.ANOTHER_ADDRESS,
      thirdPartyType: ThirdPartyType.SOMEONE,
      thirdPartyPayment: {
        firstName: '',
        lastName: '',
        mail: '',
        phoneNumber: '',
        denomination: 'M.',
        companyName: '',
      },
    },
    calendlyUrl: '',
    utmContent: '',
    subscriptions: [],
    isUseDataCommercialChecked: false,
    isConsentChecked: false,
  });
  const { setErr } = useError();

  const { t } = useTranslation();

  const onSubmitForm = (onSuccess: () => void, onError: () => void) => {
    const payload = {
      ...data.parkingInformation,
      title: data.coordinates.denomination === 'M.' ? 'Monsieur' : 'Madame',
      firstName: data.coordinates.firstName,
      lastName: data.coordinates.lastName,
      email: data.coordinates.mail,
      telephone: data.coordinates.phoneNumber,
      memberId: data.coordinates.memberId,
      subscriptions: data.subscriptions.map((subscription) => ({
        subscriptionType: subscription.subscriptionType,
        parkingPlaceNum: subscription.parkingPlaceNumber,
        options: {
          activationCard: subscription.options.activationCard,
          wallOutlet: subscription.options.wallOutlet,
          supportCable:
            subscription.options.supportCable !== 'none'
              ? subscription.options.supportCable
              : undefined,
          connectionCable:
            subscription.options.connectionCable !== 'none'
              ? subscription.options.connectionCable
              : undefined,
        },
      })),
      isConsentChecked: data.isConsentChecked,
      isUseDataCommercialChecked: data.isUseDataCommercialChecked,
      residence: {
        ...data.invoicingAddress,
        thirdPartyPayment: {
          lastName: data.invoicingAddress.thirdPartyPayment?.lastName,
          firstName: data.invoicingAddress.thirdPartyPayment?.firstName,
          email: data.invoicingAddress.thirdPartyPayment?.mail,
          telephone: data.invoicingAddress.thirdPartyPayment?.phoneNumber,
          memberId: data.invoicingAddress.thirdPartyPayment?.memberId,
          companyName: data.invoicingAddress.thirdPartyPayment?.companyName,
          denomination:
            data.invoicingAddress.thirdPartyPayment?.denomination === 'M.'
              ? 'Monsieur'
              : 'Madame',
        },
      },
    };
    api
      .post('api/web/parking-lot/subscribe/submit/', payload)
      .then(function (respSubmit: AxiosResponse) {
        if (
          respSubmit.data &&
          respSubmit.data.calendlyAppointmentUrl &&
          respSubmit.data.dealNumbers
        ) {
          if (data.equipmentDealId) {
            let dealNumbers = '';
            respSubmit.data.dealNumbers.forEach(
              (dealNb: number, index: number) =>
                (dealNumbers = dealNumbers.concat(
                  `${index === 0 ? '' : ';'}${dealNb}`
                ))
            );
            setData({
              ...data,
              calendlyUrl: respSubmit.data.calendlyAppointmentUrl,
              utmContent: dealNumbers,
            });
          }
        }
        onSuccess();
      })
      .catch((error) => setErr(error))
      .finally(() => onError());
  };

  return (
    <FormikContext.Provider
      value={{
        data,
        onSubmitForm,
        setData,
      }}
    >
      <Stepper
        title={t(
          `forms.subscribe.title.${[ActiveSubscribeForm.PARKING_SUBSCRIPTION]}`
        )}
        initialStep={true}
        finalStep={<CompletedStep />}
      >
        {SubscribeForm(data)}
      </Stepper>
    </FormikContext.Provider>
  );
};
