import React, { ChangeEvent, useContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Formik, FormikHelpers } from 'formik';

import { boolean, object } from 'yup';

import TagManager from 'react-gtm-module';

import { FormDataKey } from '../../../libraries/sections/models/Subscriptions';

import { LanguageData } from '../../../libraries/sections/models/Common';

import { FormCheckbox } from '../../dumps/Forms/FormCheckbox';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';

import { FormField } from '../../dumps/FormikField/FormField';

import { StepperContext } from '../../Stepper';

import { PreviousStepResume } from './PreviousStepResume';
import { DataUseCustomLink } from './DataUseCustomLink';
import { ValidationResumeList } from './ValidationResumeList';
import {
  displayCableSupportPrice,
  displayConnectionCablePrice,
  Pricing,
  selectSubscriptionPrice,
} from './Pricing';
import { FormatPrice } from './FormatPrice';

export const ValidationForm = () => {
  const { onNextStep } = useContext(StepperContext);
  const { t } = useTranslation();
  const validationFormLangData: LanguageData = t('forms.subscribe', {
    returnObjects: true,
  });
  const errorFormLangData: LanguageData = t('errorMessage', {
    returnObjects: true,
  });
  const Validation = object().shape({
    isUseDataCommercialChecked: boolean().required(errorFormLangData.required),
    isConsentChecked: boolean()
      .required(errorFormLangData.required)
      .oneOf([true], errorFormLangData.consent),
  });

  const { data, setData, onSubmitForm } = useContext(FormikContext);

  const selectOptionsPrice = useMemo(() => {
    return data.subscriptions
      .map((sub) => {
        let price = 0;
        if (sub.options.connectionCable) {
          price += displayConnectionCablePrice(sub.options.connectionCable);
        }
        if (sub.options.activationCard > 1) {
          price += (sub.options.activationCard - 1) * Pricing.activationCard;
        }
        if (sub.options.supportCable) {
          price += displayCableSupportPrice(sub.options.supportCable);
        }
        if (sub.options.wallOutlet > 0) {
          price += sub.options.wallOutlet * Pricing.wallOutlet;
        }
        if (sub.subscriptionType === 'purchase') {
          price += 2120;
        }
        price += 380;
        return price;
      })
      .reduce((prev, current) => prev + current, 0);
  }, [data.subscriptions]);

  const onSubmit = (
    values: { isUseDataCommercialChecked: boolean; isConsentChecked: boolean },
    actions: FormikHelpers<{
      isUseDataCommercialChecked: boolean;
      isConsentChecked: boolean;
    }>
  ) => {
    setData({ ...data, ...values });
    const onSuccess = () => {
      actions.setSubmitting(false);
      onNextStep();
    };

    const onError = () => {
      actions.setSubmitting(false);
    };
    onSubmitForm(onSuccess, onError);
  };

  const onCheckBoxChange = (
    setFieldTouched: (field: string, isTouched?: boolean) => void,
    setFieldValue: (field: string, isTouched?: boolean) => void,
    check: boolean,
    name: string
  ) => {
    setFieldTouched(name, true);
    setFieldValue(name, check);
    setData({ ...data, isConsentChecked: check });
  };

  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        content_type: 'step5-validate',
        item_id: 'form-subscription',
      },
    });
  }, []);

  return (
    <div className="page-form page-form__form">
      <PreviousStepResume propertyKey="validation" />
      <h2 className="page-form__form__detail title">
        {validationFormLangData.detail.summary}
      </h2>
      <Formik
        initialValues={{
          isUseDataCommercialChecked: data.isUseDataCommercialChecked,
          isConsentChecked: data.isConsentChecked,
        }}
        validationSchema={Validation}
        onSubmit={onSubmit}
      >
        {({ setFieldTouched, setFieldValue, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ValidationResumeList />
              <div className="summary">
                <span className="title full">
                  {validationFormLangData.formContent.totalPrice}
                </span>
                <span className="summary--label">
                  {validationFormLangData.formContent.uniquePrice}
                </span>
                <span className="summary--value title important">
                  <FormatPrice price={selectOptionsPrice} />
                </span>
                <span className="summary--label">
                  {validationFormLangData.formContent.monthly}
                </span>
                <span className="summary--value title important">
                  <FormatPrice
                    price={data.subscriptions
                      .map((sub) =>
                        selectSubscriptionPrice(sub.subscriptionType as string)
                      )
                      .reduce((prev, current) => prev + current, 0)}
                  />
                  {validationFormLangData.formContent.byMonth}
                </span>
              </div>
              <div className="summary-checkboxes">
                <FormField
                  inputElement={FormCheckbox}
                  name={FormDataKey.isConsentChecked}
                  required={true}
                  className="checkbox"
                  classNameContentField="summary-checkboxes__checkbox"
                  onChange={(event) => {
                    const check = (event as ChangeEvent<HTMLInputElement>)
                      .target['checked'];
                    onCheckBoxChange(
                      setFieldTouched,
                      setFieldValue,
                      check,
                      FormDataKey.isConsentChecked
                    );
                  }}
                  label={validationFormLangData.formContent.acceptRule}
                  isCheckBox={true}
                />
                <FormField
                  inputElement={FormCheckbox}
                  name={FormDataKey.isUseDataCommercialChecked}
                  required={false}
                  className="checkbox"
                  classNameContentField="summary-checkboxes__checkbox"
                  isCheckBox={true}
                  onChange={(event) => {
                    const check = (event as ChangeEvent<HTMLInputElement>)
                      .target['checked'];
                    onCheckBoxChange(
                      setFieldTouched,
                      setFieldValue,
                      check,
                      FormDataKey.isUseDataCommercialChecked
                    );
                  }}
                  label={
                    <DataUseCustomLink
                      link="https://www.romande-energie.ch/conditions-internet"
                      text="forms.subscribe.formContent.acceptUseDataCommercial"
                      name={FormDataKey.isUseDataCommercialChecked}
                    />
                  }
                />
              </div>
              <button
                disabled={isSubmitting}
                className="btn-summary btn btn--red"
                id="subscribe-button"
              >
                {validationFormLangData.formContent.next}
              </button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
