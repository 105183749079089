import React from 'react';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import { NormalLayout } from './components/views/Layouts/NormalLayout';
import { useContainer } from './hooks/useContainer';
import ScrollToTop from './components/utils/scrollToTop';

const tagManagerArgs = {
  gtmId: RUNTIME_CONFIG.GTM_ID,
};

// Google Tag Manager initialization
TagManager.initialize(tagManagerArgs);

// eslint-disable-next-line
function App() {
  const container = useContainer();
  return (
    <I18nextProvider i18n={container.translator}>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <NormalLayout />
        </Router>
      </HelmetProvider>
    </I18nextProvider>
  );
}

export default App;
