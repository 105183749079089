import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';

type ContactProps = {
  email: boolean;
  icon: string;
  iconSize: string;
  iconColor: string;
  question: string;
};

export const ContactBloc = ({
  email,
  icon,
  iconSize,
  iconColor,
  question,
}: ContactProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="contact-bloc-mail bg-dark-grey m-auto">
        <div className="d-flex contact-bloc-mail__header">
          <Icon type={icon} size={iconSize} color={iconColor} />
          <h2 className="contact-bloc-mail__title">{question}</h2>
        </div>
        {email && (
          <>
            <a
              className="contact-bloc-mail__email"
              href={`mailto:clickandcharge@romande-energie.ch?subject=Contact - Click%26Charge`}
              target="_blank"
              rel="noreferrer"
            >
              clickandcharge@romande-energie.ch
            </a>
            <p className="contact-bloc-mail__description">
              {t('contactPage.emailBloc.description')}
            </p>
          </>
        )}
        {!email && (
          <>
            <h3 className="contact-bloc__phone-number">021 802 90 90</h3>
            <p className="contact-bloc__schedules">
              {t('contactPage.phoneBloc.subtitle1')}
            </p>
            <p className="contact-bloc__schedules">
              {t('contactPage.phoneBloc.subtitle2')}
            </p>
          </>
        )}
      </div>
    </>
  );
};
