import React from 'react';

import { useTranslation } from 'react-i18next';

import { Step } from '../../dumps/Step/Step';
import { DownloadBtn } from '../../dumps/Buttons/DownloadBtn';

import {
  StepOwnerKeys,
  StepType,
} from '../../../libraries/sections/models/Steps';
import { RedirectBtn } from '../../dumps/Buttons/RedirectBtn';
import { useLinks } from '../../../hooks/useLinks';

export const stepsList: StepType[] = [
  {
    key: StepOwnerKeys.ANALYSE,
    icon: 'Analyse',
    size: '80',
    color: '#000',
  },
  {
    key: StepOwnerKeys.CONTRACT,
    icon: 'Note',
    size: '80',
    color: '#000',
  },
  {
    key: StepOwnerKeys.PREEQUIPE,
    icon: 'Wrench',
    size: '80',
    color: '#000',
  },
];

export const StepsSection = () => {
  const { t } = useTranslation();
  const links = useLinks();

  return (
    <section className="bg-normal pb-64">
      <div className="container">
        <div className="row max-w-standard mx-auto">
          <h2 className="text-center text-red-re pt-64 pb-50">
            {t(`ownerPage.stepSection.title`)}
          </h2>
        </div>
      </div>
      <div className="step-section">
        {stepsList.map((step: StepType, index: number) => (
          <Step
            key={step.key}
            title={t(`ownerPage.stepSection.steps.${step.key}.title`)}
            detail={t(`ownerPage.stepSection.steps.${step.key}.detail`)}
            icon={step.icon}
            color={step.color}
            size={step.size}
            index={index + 1}
          />
        ))}
      </div>
      <div className="pt-64">
        <RedirectBtn
          link={links.equipParking().path}
          title={t('userWithoutParkingPage.callToActionSection.btnToForm')}
        />
      </div>
      <DownloadBtn />
    </section>
  );
};
