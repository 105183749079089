import React, { useContext } from 'react';

import { Step, StepperContext } from '../../Stepper';
import { Icon } from '../../dumps/Icon/Icon';

import { SubscriptionData } from '../../Formik/FormikContext';

import { ParkingInformationForm } from './ParkingInformationForm';
import { CoordinatesForm } from './CoordinatesForm';
import { SubscriptionStep } from './SubscriptionStep';
import { InvoicingForm } from './InvoicingForm';
import { ValidationForm } from './ValidationForm';
import { AppointmentForm } from './AppointmentForm';

interface ISubscriptionFormSteps {
  iconName: string;
  title: string;
  component: JSX.Element;
  checkEquipmentStatus?: boolean;
}

const subscribtionFormSteps: ISubscriptionFormSteps[] = [
  {
    iconName: 'ParkingPin',
    title: 'Informations parking',
    component: <ParkingInformationForm />,
  },
  {
    iconName: 'Coordinates',
    title: 'Coordonnées',
    component: <CoordinatesForm />,
  },
  {
    iconName: 'Subscription',
    title: 'Souscription',
    component: <SubscriptionStep />,
  },
  {
    iconName: 'Home',
    title: 'Adresse de facturation',
    component: <InvoicingForm />,
  },
  {
    iconName: 'Package',
    title: 'Validation',
    component: <ValidationForm />,
  },
  {
    iconName: 'Calendar',
    title: 'Prise de RDV',
    component: <AppointmentForm />,
    checkEquipmentStatus: true,
  },
];

export const SubscribeForm = (data: SubscriptionData) => {
  return subscribtionFormSteps
    .filter(
      ({ checkEquipmentStatus }) =>
        !checkEquipmentStatus || !!data.equipmentDealId
    )
    .map(({ component, iconName, title }, index) => (
      <Step
        id={index + 1}
        icon={<BuildIcon iconName={iconName} id={index + 1} />}
        title={title}
      >
        {component}
      </Step>
    ));
};

const BuildIcon = ({ id, iconName }: { id: number; iconName: string }) => {
  const { currentStep } = useContext(StepperContext);
  const iconColor = () => {
    if (currentStep === id) {
      return '#ffffff';
    } else if (currentStep > id) {
      return '#6FCF97';
    } else {
      return '#828282';
    }
  };
  return (
    <Icon type={currentStep > id ? 'Success' : iconName} color={iconColor()} />
  );
};
