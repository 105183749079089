import i18next, { Resource } from 'i18next';

import { initReactI18next } from 'react-i18next';

import { RouteDef } from '../libraries/router/models/RouteDef';

import { getAppRoutes } from './appRoutes';
import { AppLinks, getAppLinks } from './appLinks';

import { getLocalesResource } from './locales';

export type AppContainer = ReturnType<typeof getAppContainer>;

// eslint-disable-next-line
export const getAppContainer = () => {
  const links: AppLinks = getAppLinks();
  const routes: RouteDef[] = getAppRoutes(links);
  const resources: Resource = getLocalesResource();

  const translator = i18next
    .createInstance({
      debug: false,
      fallbackLng: 'fr',
      supportedLngs: Object.keys(resources),
      resources,
      interpolation: { escapeValue: false },
    })
    .use(initReactI18next);

  // initialize translator immediately
  translator.init({
    compatibilityJSON: 'v3',
  });

  return {
    links,
    routes,
    translator,
  };
};
