import React, {
  ChangeEvent,
  ComponentType,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { FieldArrayRenderProps, FormikProps } from 'formik';

import { InputLabel } from '../../dumps/FormikField/InputLabel';
import { FormDataKey } from '../../../libraries/sections/models/Subscriptions';
import { FormField } from '../../dumps/FormikField/FormField';
import { LanguageData } from '../../../libraries/sections/models/Common';
import { ConfirmationModal } from '../PageForms/ConfirmationModal';

import { toStreetWithShortCuts } from '../../../libraries/helpers/shortCuts';

import { InputWithInfo } from '../../dumps/Forms/InputWithInfo';

import { IncrementWithCheckBox } from '../../dumps/Forms/IncrementWithCheckBox';

import { FormValues } from './EquipParkingForm';

interface ParkingListProps extends Omit<FieldArrayRenderProps, 'form'> {
  form: FormikProps<FormValues>;
}

export const ParkingList: ComponentType<FieldArrayRenderProps> = ({
  form,
  remove,
  push,
}: ParkingListProps) => {
  const { t } = useTranslation();
  const formTranslation: LanguageData = t('forms.subscribe', {
    returnObjects: true,
  });
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const onDeleteClick = (index: number) => {
    if (form.errors.parkings && form.errors.parkings[index]) {
      remove(index);
    } else {
      setShowConfirmModal(true);
    }
  };

  const buildTitle = useMemo(() => {
    return `${
      form.values.parkings[form.values.parkings.length - 1][FormDataKey.street]
    } ${
      form.values.parkings[form.values.parkings.length - 1][
        FormDataKey.streetNumber
      ]
    }, ${
      form.values.parkings[form.values.parkings.length - 1][FormDataKey.npa]
    } ${
      form.values.parkings[form.values.parkings.length - 1][FormDataKey.city]
    }`;
  }, [form.values.parkings]);

  const onValidate = useCallback(
    (index: number) => {
      setShowConfirmModal(false);
      remove(index);
    },
    [remove]
  );

  return (
    <>
      {form.values.parkings.length > 0 &&
        form.values.parkings.map((parking, index: number) => {
          return (
            <div className="field parking">
              {index > 0 && <hr className="parking__separator" />}
              <InputLabel
                className="address-form__label"
                label={formTranslation.formContent.address.label}
                name={`${FormDataKey.parkings}.${index}.${FormDataKey.street}`}
                required
              />
              <FormField
                className="inp"
                classNameContentField="field__street"
                inputElement="input"
                name={`${FormDataKey.parkings}.${index}.${FormDataKey.street}`}
                onChange={(event) => {
                  form.setFieldValue(
                    `${FormDataKey.parkings}.${index}.${FormDataKey.street}`,
                    event
                      ? toStreetWithShortCuts(
                          (event as ChangeEvent<HTMLInputElement>).target.value
                        )
                      : ''
                  );
                }}
                required
              />
              <FormField
                className="inp"
                classNameContentField="field__street-number"
                inputElement="input"
                name={`${FormDataKey.parkings}.${index}.${FormDataKey.streetNumber}`}
                required
              />
              <FormField
                className="inp"
                inputElement="input"
                name={`${FormDataKey.parkings}.${index}.${FormDataKey.npa}`}
                type="text"
                min={1000}
                max={9999}
                required
                label={formTranslation.formContent.npa.label}
              />
              <FormField
                className="inp"
                inputElement="input"
                name={`${FormDataKey.parkings}.${index}.${FormDataKey.city}`}
                required
                label={formTranslation.formContent.local.label}
              />
              <FormField
                className="field__total-parking-places"
                inputElement={InputWithInfo}
                name={`${FormDataKey.parkings}.${index}.${FormDataKey.totalParkingPlaces}`}
                required={false}
                label={formTranslation.formContent.parkingSpaceNumber.label}
                labelInfo={formTranslation.formContent.canBeUpdateAfter}
                onChange={(event) => {
                  form.setFieldTouched(
                    `${FormDataKey.parkings}.${index}.${FormDataKey.totalParkingPlaces}`,
                    true
                  );
                  form.setFieldValue(
                    `${FormDataKey.parkings}.${index}.${FormDataKey.totalParkingPlaces}`,
                    (event as ChangeEvent<HTMLInputElement>).target.value
                  );
                }}
                onBlur={() => {
                  if (!parking.totalParkingPlaces) {
                    form.setFieldValue(
                      `${FormDataKey.parkings}.${index}.${FormDataKey.totalParkingPlaces}`,
                      0
                    );
                  }
                }}
              />
              <FormField
                className="field__people-number"
                inputElement={IncrementWithCheckBox}
                name={`${FormDataKey.parkings}.${index}.${FormDataKey.totalPeopleInterested}`}
                required={false}
                labelInfo={formTranslation.formContent.iDontKnow}
                label={formTranslation.formContent.peopleNumber.label}
                onChange={(value) => {
                  form.setFieldTouched(
                    `${FormDataKey.parkings}.${index}.${FormDataKey.totalPeopleInterested}`,
                    true
                  );
                  form.setFieldValue(
                    `${FormDataKey.parkings}.${index}.${FormDataKey.totalPeopleInterested}`,
                    value
                  );
                }}
              />
              {form.values.parkings.length - 1 === index && (
                <button
                  type="button"
                  className="btn btn--secondary parking__btn-add"
                  disabled={!!form.errors.parkings}
                  onClick={() =>
                    push({
                      street: '',
                      streetNumber: '',
                      npa: '',
                      city: '',
                      totalParkingPlaces: 0,
                      totalPeopleInterested: 0,
                    })
                  }
                >
                  {formTranslation.formContent.addParkingBtn}
                </button>
              )}
              {form.values.parkings.length > 1 &&
                form.values.parkings.length - 1 === index && (
                  <>
                    <button
                      type="button"
                      className="btn btn--secondary parking__btn-delete"
                      onClick={() => onDeleteClick(index)}
                    >
                      {formTranslation.formContent.delParkingBtn}
                    </button>
                    <ConfirmationModal
                      show={showConfirmModal}
                      onValidate={() => onValidate(index)}
                      onCancel={() => setShowConfirmModal(false)}
                      title={`${formTranslation.formContent.confirmationParkingDeletion.title} ${buildTitle}`}
                      content={`${formTranslation.formContent.confirmationParkingDeletion.content}`}
                      validationButton={
                        formTranslation.formContent.confirmationParkingDeletion
                          .delete
                      }
                      cancelButton={
                        formTranslation.formContent.confirmationParkingDeletion
                          .cancel
                      }
                    />
                  </>
                )}
            </div>
          );
        })}
    </>
  );
};
