import React from 'react';

type BlocProps = {
  title: string;
  detail: string;
  price: string;
  detail2: string;
  detail3: string;
};
// Bloc component is used in the offer section to display the different textual information
export const Bloc = ({ title, detail, price, detail2, detail3 }: BlocProps) => {
  return (
    <>
      <div className="container max-w-standard pt-48 pb-48">
        <div className="row m-auto bg-normal">
          <h2 className="text-red-re text-center py-24">{title}</h2>
          <div className="col-lg-6 col-12">
            <h2 className="bloc-section__price">{price}</h2>
            <hr />
            <h3 className="bloc-section__detail">{detail}</h3>
          </div>
          <div className="col-lg-6 col-12 pt-3 pt-lg-0">
            <p className="bloc-section__description">{detail2}</p>
            <p className="bloc-section__description">{detail3}</p>
          </div>
        </div>
      </div>
    </>
  );
};
