import React, { useContext, useEffect, useRef } from 'react';

import { StepperContext } from './StepperContext';

interface StepperProgressBarPropTypes {
  steps: {
    id: number;
    title: string;
    icon: JSX.Element;
  }[];
  formEnd: boolean;
  formTitle: string;
}

/**
 * First mobile size (sm)
 */
const MOBILE_SIZE = 576;

export const StepperProgressBar = ({
  steps,
  formEnd,
  formTitle,
}: StepperProgressBarPropTypes) => {
  const { changeStep, currentStep } = useContext(StepperContext);
  const stepperProgressWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (window) {
      window.scroll(0, 0);
    }
  }, [currentStep]);

  // TODO Check if we should not set the scrollLeft according to the currentStep
  useEffect(() => {
    if (
      stepperProgressWrapper &&
      stepperProgressWrapper.current &&
      stepperProgressWrapper.current.clientWidth <= MOBILE_SIZE
    ) {
      stepperProgressWrapper.current.scrollLeft +=
        (stepperProgressWrapper.current.scrollWidth -
          stepperProgressWrapper.current.offsetWidth) /
        steps.length;
    }
  }, [stepperProgressWrapper, currentStep, steps.length]);

  return (
    <div ref={stepperProgressWrapper} className="stepper-progress-container">
      <h1 className="page-form__form__title subscription">{formTitle}</h1>
      <div className="stepper-progess-wrapper">
        {steps.map(({ title, id, icon }) => (
          <div
            className={`stepper-progress-step${formEnd ? ' disabled' : ''}`}
            onClick={() =>
              !formEnd && currentStep > id ? changeStep(id) : null
            }
          >
            <div
              className={`stepper-progress-content ${
                currentStep > id && 'step-done'
              } ${currentStep === id && 'step-current'}`}
            >
              <div className="stepper-progress-icon">{icon}</div>
              <span>{title}</span>
            </div>
            <div
              className={`${
                currentStep > id && 'step-done'
              } stepper-progress-skein
              ${currentStep === id && 'step-current'}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
