import React from 'react';

import { Icon } from '../Icon/Icon';

import { Increment } from './Increment';

type FormIncrementProps = {
  icon: string;
  label: string;
  info: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (val: number) => void;
};

export const FormIncrement = ({
  icon,
  label,
  info,
  ...props
}: FormIncrementProps) => {
  return (
    <div className="form-increment">
      <div className="form-increment__icon">
        <Icon type={icon} />
      </div>
      <div className="form-increment__container">
        <div className="form-increment__container__info">
          <p className="mb-0">{label}</p>
          <p className="mb-0 fw-bold">{info}</p>
        </div>
        <div className="form-increment__container__content">
          <Increment {...props} />
        </div>
      </div>
    </div>
  );
};
