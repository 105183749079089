import { useTranslation } from 'react-i18next';

import { array, boolean, number, object, string } from 'yup';

import { FieldArray, Formik, FormikHelpers } from 'formik';

import { AxiosResponse } from 'axios';

import React, { useEffect, useMemo } from 'react';

import TagManager from 'react-gtm-module';

import {
  FormDataKey,
  OwnerType,
} from '../../../libraries/sections/models/Subscriptions';
import { useError } from '../../../hooks/useError';
import { LanguageData } from '../../../libraries/sections/models/Common';
import { EmailRegex } from '../../../libraries/helpers/validator';

import { api } from '../../../Api';
import { DataUseCustomLink } from '../PageForms/DataUseCustomLink';
import { FormCheckbox } from '../../dumps/Forms/FormCheckbox';
import { FormField } from '../../dumps/FormikField/FormField';

import { PhoneNumber } from '../../PhoneNumber/PhoneNumber';

import { InputLabel } from '../../dumps/FormikField/InputLabel';
import { FormSelect } from '../../dumps/Forms/FormSelect';

import { ParkingList } from './ParkingList';

export const roleSelectData = [
  {
    label: OwnerType.TENANT,
    value: OwnerType.TENANT,
  },
  {
    label: OwnerType.BUILDING_OWNER,
    value: OwnerType.BUILDING_OWNER,
  },
  {
    label: OwnerType.COOWNER,
    value: OwnerType.COOWNER,
  },
  {
    label: OwnerType.PPE,
    value: OwnerType.PPE,
  },
  {
    label: OwnerType.TRAFIC_IMMEUBLE,
    value: OwnerType.TRAFIC_IMMEUBLE,
  },
];

export interface FormValues {
  [FormDataKey.ownerType]: OwnerType;
  [FormDataKey.raisonSocial]?: string;
  [FormDataKey.lastName]: string;
  [FormDataKey.firstName]: string;
  [FormDataKey.email]: string;
  [FormDataKey.telephone]: string;
  [FormDataKey.title]: 'Monsieur' | 'Madame';
  [FormDataKey.isConsentSearch]: boolean;
  [FormDataKey.isConsentChecked]: boolean;
  [FormDataKey.parkings]: {
    [FormDataKey.street]: string;
    [FormDataKey.streetNumber]: string;
    [FormDataKey.npa]: string;
    [FormDataKey.city]: string;
    [FormDataKey.totalParkingPlaces]: number;
    [FormDataKey.totalPeopleInterested]: number;
  }[];
}

type EquipParkingFormProps = {
  onFormSubmitted: (url: string, email: string, fullName: string) => void;
};

export const EquipParkingForm = ({
  onFormSubmitted,
}: EquipParkingFormProps) => {
  const { setErr, clearErr } = useError();
  const { t } = useTranslation();
  const formTranslation: LanguageData = t('forms.subscribe', {
    returnObjects: true,
  });
  const errorTranslation: LanguageData = t('errorMessage', {
    returnObjects: true,
  });
  const InformationParkingValidation = object().shape({
    ownerType: string()
      .oneOf(
        [
          OwnerType.TENANT,
          OwnerType.BUILDING_OWNER,
          OwnerType.COOWNER,
          OwnerType.PPE,
          OwnerType.TRAFIC_IMMEUBLE,
        ],
        ''
      )
      .required(errorTranslation.required),
    raisonSociale: string(),
    lastName: string()
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    firstName: string()
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    email: string()
      .matches(new RegExp(EmailRegex), errorTranslation.email)
      .email(errorTranslation.email)
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    telephone: string()
      .max(60, errorTranslation.tooLong)
      .required(errorTranslation.required),
    title: string()
      .oneOf(['Monsieur', 'Madame'], '')
      .required(errorTranslation.required),
    isConsentSearch: boolean().required(errorTranslation.required),
    isConsentChecked: boolean()
      .required(errorTranslation.required)
      .oneOf([true], errorTranslation.consent),
    parkings: array()
      .of(
        object().shape({
          npa: number()
            .typeError(errorTranslation.mustBeANumber)
            .required(errorTranslation.required)
            .min(1000, formTranslation.formContent.npa.validation.min)
            .max(9999, formTranslation.formContent.npa.validation.max),
          city: string().required(errorTranslation.required),
          street: string().required(errorTranslation.required),
          streetNumber: string().required(errorTranslation.required),
          totalParkingPlaces: number()
            .typeError(errorTranslation.mustBeANumber)
            .min(
              0,
              formTranslation.formContent.parkingSpaceNumber.validation.min
            )
            .max(
              10000,
              formTranslation.formContent.parkingSpaceNumber.validation.max
            ),
          totalPeopleInterested: object().when(
            'totalParkingPlaces',
            (totalParkingPlaces: number) =>
              number()
                .typeError(errorTranslation.mustBeANumber)
                .min(0, formTranslation.formContent.peopleNumber.validation.min)
                .max(
                  totalParkingPlaces,
                  formTranslation.formContent.peopleNumber.validation.max
                )
          ),
        })
      )
      .min(1)
      .required(errorTranslation.required),
  });
  const roleLangData = useMemo(
    () =>
      roleSelectData.map((role) => ({
        label: formTranslation.formContent.roleSelect[role.label],
        value: role.value,
      })),
    [formTranslation.formContent.roleSelect]
  );

  const initialValues: FormValues = {
    [FormDataKey.ownerType]: OwnerType.TENANT,
    [FormDataKey.raisonSocial]: undefined,
    [FormDataKey.lastName]: '',
    [FormDataKey.firstName]: '',
    [FormDataKey.email]: '',
    [FormDataKey.telephone]: '',
    [FormDataKey.title]: 'Monsieur',
    [FormDataKey.isConsentSearch]: false,
    [FormDataKey.isConsentChecked]: false,
    [FormDataKey.parkings]: [
      {
        [FormDataKey.street]: '',
        [FormDataKey.streetNumber]: '',
        [FormDataKey.npa]: '',
        [FormDataKey.city]: '',
        [FormDataKey.totalParkingPlaces]: 0,
        [FormDataKey.totalPeopleInterested]: 0,
      },
    ],
  };

  const trimmedValues = (values: {
    // eslint-disable-next-line
    [key in string]: string | number | any;
  }) => {
    if (values && typeof values === 'object') {
      Object.keys(values).forEach((key) => {
        if (typeof values[key] === 'object') {
          values[key] = trimmedValues(values[key]);
        } else if (typeof values[key] === 'string') {
          values[key] = values[key].trim();
        }
      });
    }
    return values;
  };
  const onHandleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    clearErr();
    api
      .post('api/web/parking-lot/register/submit/', trimmedValues(values))
      .then(function (response: AxiosResponse) {
        if (response.data && response.data.calendlyAppointmentUrl) {
          onFormSubmitted(
            response.data.calendlyAppointmentUrl,
            values[FormDataKey.email],
            `${values[FormDataKey.firstName]} ${values[FormDataKey.lastName]}`
          );
        }
      })
      .catch((error) => setErr(error))
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        item_id: 'form-equiper-pkg',
      },
    });
  }, []);

  return (
    <>
      <div className="page-form page-form__form">
        <h2 className="page-form__form__title subscribe-modal__form__title">
          {formTranslation.title['parking-register']}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={InformationParkingValidation}
          onSubmit={onHandleSubmit}
        >
          {({ handleSubmit, values, isValid, dirty, isSubmitting }) => {
            return (
              <form
                noValidate={true}
                onSubmit={handleSubmit}
                className="form-container"
              >
                <FormField
                  classNameContentField="field"
                  inputElement={FormSelect}
                  name={FormDataKey.ownerType}
                  required
                  options={roleLangData}
                  label={formTranslation.formContent.areYou}
                />
                <h2 className="page-form__form__detail subscribe-modal__form__detail field">
                  {formTranslation.detail.contact}
                </h2>
                <p className="page-form__form__describe subscribe-modal__form__describe field">
                  {formTranslation.describe.contact}
                </p>
                {(values.ownerType === OwnerType.PPE ||
                  values.ownerType === OwnerType.BUILDING_OWNER ||
                  values.ownerType === OwnerType.TRAFIC_IMMEUBLE) && (
                  <FormField
                    classNameContentField="field"
                    inputElement="input"
                    className="inp"
                    name={FormDataKey.raisonSocial}
                    label={formTranslation.formContent.socialReason}
                    required={false}
                  />
                )}
                <InputLabel
                  className="custom-label-denomination"
                  label={formTranslation.formContent.lastName}
                  name={FormDataKey.lastName}
                  required
                />
                <FormField
                  classNameContentField="field__denomination"
                  inputElement={FormSelect}
                  name={FormDataKey.title}
                  required
                  options={[
                    { value: 'Monsieur', label: 'M.' },
                    { value: 'Madame', label: 'Mme' },
                  ]}
                />
                <FormField
                  classNameContentField="field__last-name"
                  className="inp"
                  inputElement="input"
                  name={FormDataKey.lastName}
                  required
                />
                <FormField
                  className="inp"
                  inputElement="input"
                  name={FormDataKey.firstName}
                  required
                  label={formTranslation.formContent.firstName}
                />
                <FormField
                  className="inp"
                  inputElement="input"
                  name={FormDataKey.email}
                  required
                  label={formTranslation.formContent.email}
                />
                <FormField
                  inputElement={PhoneNumber}
                  name={FormDataKey.telephone}
                  required
                  label={formTranslation.formContent.phone}
                />
                <h2 className="page-form__form__detail subscribe-modal__form__detail field">
                  {formTranslation.detail.info}
                </h2>
                <FieldArray
                  name={FormDataKey.parkings}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  component={ParkingList}
                />
                <FormField
                  inputElement={FormCheckbox}
                  name={FormDataKey.isConsentSearch}
                  required={false}
                  label={formTranslation.formContent.isConsentSearch}
                  className="checkbox"
                  isCheckBox={true}
                  classNameContentField="equip-parking__checkbox"
                />
                <FormField
                  inputElement={FormCheckbox}
                  name={FormDataKey.isConsentChecked}
                  required={true}
                  className="checkbox"
                  isCheckBox={true}
                  classNameContentField="equip-parking__checkbox"
                  classNameLabelField="common-input__link equip-parking__checkbox__consent-use-data__label"
                  classNameInputField="equip-parking__checkbox__consent-use-data__input"
                  label={
                    <DataUseCustomLink
                      link="https://www.romande-energie.ch/conditions-internet"
                      text="forms.subscribe.formContent.isConsentChecked"
                      required={true}
                      name={FormDataKey.isConsentChecked}
                    />
                  }
                />
                <div className="equip-parking__btn">
                  <button
                    type="submit"
                    className="btn btn--secondary btn--red"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    {formTranslation.formContent.scheduleTelephoneBtn}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
