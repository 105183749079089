import React from 'react';
import Nav from 'react-bootstrap/Nav';

import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';

import { useRouter } from '../../../hooks/useRouter';
type NavLinkComponentProp = {
  link: string;
  dataTest: string;
  title: string;
};

export const NavLinkComponent = ({
  link,
  dataTest,
  title,
}: NavLinkComponentProp) => {
  const { pathname } = useRouter();
  const { t } = useTranslation();

  const checkThePathname = (linkPath: string) =>
    linkPath.length === 1
      ? linkPath === pathname
      : new RegExp('^/(' + linkPath.substring(1) + ')/*').test(pathname);

  return (
    <Nav.Link
      href={link}
      id={`menu-link-${dataTest}`}
      data-test={`menu-link-${dataTest}`}
      className={`${checkThePathname(link) ? 'active' : ''} ${
        link != '/contact' ? 'nav-link-default' : 'contact_nav_link'
      } nav-link-default`}
    >
      {link === 'contact' && (
        <>
          <Icon
            type="Contact"
            data-test="menu-link-contact-mobile"
            size="30"
            color="#fff"
          />
          <span
            className="contact_nav_link_text"
            data-test="menu-link-contact-desktop"
          >
            {t(`common.routes.contact.name`)}
          </span>
        </>
      )}
      {link != 'contact' && title}
    </Nav.Link>
  );
};
