import React from 'react';

type InputHintProps = {
  error?: string;
  touched: boolean;
  className: string;
};

export const InputHint = ({
  error = '',
  touched,
  className,
}: InputHintProps) => {
  return !!error && touched ? <div className={className}>{error}</div> : null;
};
