import React from 'react';
import { useTranslation } from 'react-i18next';

import Adminppe from '../../../assets/images/adminppe.png';
import BernardNico from '../../../assets/images/bernardNico.png';
import Bolliger from '../../../assets/images/bolliger.png';
import ComptoirImmobilier from '../../../assets/images/comptoirImmobilier.png';
import Domicim from '../../../assets/images/domicim.png';
import Galland from '../../../assets/images/galland.png';
import Gerofinance from '../../../assets/images/gerofinance.png';
import GroupeSpg from '../../../assets/images/groupeSpg.png';
import Naef from '../../../assets/images/naef.png';
import Rilsa from '../../../assets/images/rilsa.png';
import Roland from '../../../assets/images/roland.png';
import Rytze from '../../../assets/images/rytze.png';

export const LogoCloudSection = () => {
  const { t } = useTranslation();

  return (
    <section className="container-fluid pb-64 text-center max-w-section">
      <h2 className="text-red-re pt-64 pb-64">
        {t(`homePage.logoCloud.title`)}
      </h2>
      <div className="row">
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Naef} alt="Naef" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Roland} alt="Roland" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Domicim} alt="Domicim" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={GroupeSpg} alt="GroupeSpg" />
        </div>
      </div>
      <div className="row logo-pt-50">
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Rilsa} alt="Rilsa" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Gerofinance} alt="Gerofinance" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Rytze} alt="Rytze" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Galland} alt="Galland" />
        </div>
      </div>
      <div className="row logo-pt-50">
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={BernardNico} alt="BernardNico" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Adminppe} alt="Adminppe" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={Bolliger} alt="Bolliger" />
        </div>
        <div className="col-md-6 col-lg-3 pt-md-20">
          <img src={ComptoirImmobilier} alt="ComptoirImmobilier" />
        </div>
      </div>
    </section>
  );
};
