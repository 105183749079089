import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import fr from './../../locales/countries/fr_CH.json';
import en from './../../locales/countries/en_US.json';

type PhoneNumberProps = {
  name: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder?: string;
};

export const PhoneNumber = ({
  name,
  value = '',
  onChange,
  className = '',
  placeholder = '078 000 00 00',
}: PhoneNumberProps) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [phoneValue, setPhoneValue] = useState('');

  const onPhoneChange = (selectValue: string) => {
    if (!onChange) {
      return setPhoneValue(selectValue);
    }

    const fakeEvent = {
      target: {
        value: selectValue,
        name,
      },
    };
    return onChange(fakeEvent as React.ChangeEvent<HTMLInputElement>);
  };

  const getInputLabels = () => {
    switch (language) {
      case 'en':
        return en;
      default:
        return fr;
    }
  };

  return (
    <PhoneInput
      defaultCountry="CH"
      value={onChange ? value : phoneValue}
      className={className}
      placeholder={placeholder}
      onChange={onPhoneChange}
      labels={getInputLabels()}
    />
  );
};
