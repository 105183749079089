import React from 'react';

import { useTranslation } from 'react-i18next';

import { Banner } from '../../dumps/Banner/Banner';

import Owner from '../../../assets/images/facade.jpg';

export const BannerSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner
        title={t('ownerPage.banner.title')}
        detail_1={t('ownerPage.banner.detail1')}
        detail_2={t('ownerPage.banner.detail2')}
        detail_3={t('ownerPage.banner.detail3')}
        background={Owner}
        overlay={true}
      />
    </>
  );
};
