import { useTranslation } from 'react-i18next';

import { Icon } from '../../dumps/Icon/Icon';
import { LanguageData } from '../../../libraries/sections/models/Common';

export const ClientNumberTutorial = () => {
  const { t } = useTranslation();

  const formTranslation: LanguageData = t('forms.subscribe.formContent', {
    returnObjects: true,
  });
  return (
    <div className="tutorial">
      <Icon type="Bulb" color="#fff" />
      <div>
        <h1 className="tutorial__title">
          {formTranslation.whereToFindClientNumber}
        </h1>
        <div className="tutorial__container">
          <span>{formTranslation.onYourInvoicings}</span>
          <div className="icon">
            <Icon type="InvoiceSample" />
          </div>
        </div>
      </div>
    </div>
  );
};
