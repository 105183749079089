import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';

type SubscribeBtnProps = {
  title: string;
  notRent?: boolean;
  notPurchase?: boolean;
  tooltip?: boolean;
};

export const DesktopRow = ({
  title,
  notRent,
  notPurchase,
  tooltip,
}: SubscribeBtnProps) => {
  const { t } = useTranslation();

  return (
    <div className="row m-auto bg-normal p-2 vertical-align">
      <div className="col-6">
        <div className="d-flex justify-content-between">
          <div>{t(`userWithParkingPage.pricesSection.service.${title}`)}</div>
          {tooltip && (
            <>
              <div className="prices_tooltip">
                <Icon type="QuestionMark" size="20" color="#000" />
                <div className="prices_tooltiptext">
                  {t(
                    `userWithParkingPage.pricesSection.service.tooltips.${title}`
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {!notRent && (
        <div className="col-3 text-center">
          <Icon type="Valid" size="17" color="#000" />
        </div>
      )}
      {!notPurchase && (
        <div className="col-3 text-center">
          <Icon type="Valid" size="17" color="#000" />
        </div>
      )}
    </div>
  );
};
