import React from 'react';

import { useTranslation } from 'react-i18next';

import { Step } from '../../dumps/Step/Step';

import {
  StepWithoutKeys,
  StepType,
} from '../../../libraries/sections/models/Steps';

const color = '#000';
export const stepsList: StepType[] = [
  {
    key: StepWithoutKeys.CHOICE,
    icon: 'ClickAndChargeBubble',
    size: '70',
    color: color,
  },
  {
    key: StepWithoutKeys.INSTALLATION,
    icon: 'Wrench',
    size: '70',
    color: color,
  },
  {
    key: StepWithoutKeys.SOUSCRIPTION,
    icon: 'Souscription',
    size: '130',
    color: color,
  },
];

export const StepsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-normal">
      <div className="container">
        <div className="row max-w-standard m-auto">
          <h2 className="text-center text-red-re pb-3 pt-64">
            {t('userWithoutParkingPage.stepSection.title')}
          </h2>
        </div>
      </div>

      <div className="step-section">
        {stepsList.map((step: StepType, index: number) => (
          <Step
            key={step.key}
            title={t(
              `userWithoutParkingPage.stepSection.steps.${step.key}.title`
            )}
            detail={t(
              `userWithoutParkingPage.stepSection.steps.${step.key}.detail`
            )}
            icon={step.icon}
            color={step.color}
            size={step.size}
            index={index + 1}
          />
        ))}
      </div>
    </section>
  );
};
