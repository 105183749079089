import React, { ChangeEvent } from 'react';

import Select, { OnChangeValue, Options, SingleValue } from 'react-select';

import Creatable from 'react-select/creatable';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { FormField } from '../../dumps/FormikField/FormField';
import { FormDataKey } from '../../../libraries/sections/models/Subscriptions';

import { FormAutoComplete } from '../../dumps/Forms/FormAutoComplete';
import {
  isValueIncludeShortCuts,
  toStreetWithShortCuts,
} from '../../../libraries/helpers/shortCuts';
import { InputLabel } from '../../dumps/FormikField/InputLabel';

export interface FormAddressType {
  label: string;
  value: string;
  data?: {
    defaultOption: boolean;
  };
}

type FormAddressProps<T> = {
  subscribeFormLangData: LanguageData;
  cities?: Options<T>;
  streets?: Options<T>;
  streetNumbers?: Options<T>;
  onBlurNpa: () => void;
  onBlurCity?: () => void;
  values: {
    npa?: string;
    city?: string;
    street?: string;
    streetNumber?: string;
  };
  setFieldValue: (
    field: string,
    value: string,
    defaultOption?: boolean
  ) => void;
  componentType: 'parkingInfo' | 'invoicingInfo';
  isCityLoading?: boolean;
  isStreetLoading?: boolean;
  disabled?: boolean;
  startIndex?: number;
};

export const FormAddress = <T extends FormAddressType>({
  subscribeFormLangData,
  values,
  setFieldValue,
  onBlurNpa,
  onBlurCity = undefined,
  cities,
  streets,
  streetNumbers,
  componentType,
  isCityLoading = false,
  isStreetLoading = false,
  disabled = false,
  startIndex = 1,
}: FormAddressProps<T>) => {
  const checkNpa = (newValue: string, oldValue?: string) => {
    if (/^[0-9]{0,4}$/.test(newValue)) {
      return newValue;
    }
    return oldValue ? oldValue : '';
  };

  const autoCompleteFormFilter = (option: T, rawInput: string) => {
    return (
      isValueIncludeShortCuts(option.label, rawInput) ||
      option.label.toLocaleLowerCase().includes(rawInput.toLocaleLowerCase()) ||
      option.label === subscribeFormLangData.formContent.cityNotFound ||
      option.label === subscribeFormLangData.formContent.addressNotFound ||
      option.label === subscribeFormLangData.formContent.numberNotFound
    );
  };

  const onStreetChangePostProcess = (value: OnChangeValue<T, boolean>) =>
    value ? toStreetWithShortCuts((value as T).label) : '';

  return (
    <>
      <FormField
        label={subscribeFormLangData.formContent.npa.label}
        inputElement="input"
        name={FormDataKey.npa}
        required
        value={values.npa}
        className="inp"
        classNameContentField="address-form__npa-input"
        autoFocus={false}
        type="text"
        min={1000}
        max={9999}
        tabIndex={startIndex}
        onBlur={onBlurNpa}
        onChange={(event) => {
          setFieldValue(
            FormDataKey.npa,
            checkNpa(
              (event as ChangeEvent<HTMLInputElement>).target.value,
              values.npa
            )
          );
        }}
        disabled={disabled}
      />
      <FormField<T>
        label={subscribeFormLangData.formContent.local.label}
        inputElement={FormAutoComplete}
        reactSelectElement={
          componentType === 'parkingInfo' ? Select : Creatable
        }
        name={FormDataKey.city}
        required
        value={
          values.city
            ? ({
                value: FormDataKey.city,
                label: values.city,
              } as SingleValue<T>)
            : ''
        }
        onChange={(value) => {
          const val = value as SingleValue<T>;
          setFieldValue(
            FormDataKey.city,
            val ? val.label : '',
            val ? val.data?.defaultOption : false
          );
        }}
        placeholder={subscribeFormLangData.formContent.local.placeholder}
        className="autocomplete col full"
        classNamePrefix="separator"
        classNameContentField="address-form__city-input"
        autoFocus={false}
        maxLength={100}
        tabIndex={startIndex + 1}
        onBlur={onBlurCity}
        filterOption={autoCompleteFormFilter}
        options={isCityLoading ? [] : cities}
        noOptionMessageText={subscribeFormLangData.formContent.noCityOption}
        isOptionSelected={(value) => {
          return (
            !Array.isArray(value) &&
            (value as SingleValue<T>)?.label === values.city
          );
        }}
        isLoading={isCityLoading}
        disabled={disabled}
      />
      <InputLabel
        label={subscribeFormLangData.formContent.address.label}
        name={FormDataKey.street}
        required={true}
        className="address-form__label"
      />
      <FormField<T>
        inputElement={FormAutoComplete}
        reactSelectElement={
          componentType === 'parkingInfo' ? Select : Creatable
        }
        name={FormDataKey.street}
        required
        value={
          values.street
            ? ({
                value: FormDataKey.street,
                label: values.street,
              } as SingleValue<T>)
            : ''
        }
        onChange={(value) => {
          const val = value as SingleValue<T>;
          setFieldValue(
            FormDataKey.street,
            onStreetChangePostProcess(val),
            val ? val.data?.defaultOption : false
          );
        }}
        placeholder={subscribeFormLangData.formContent.address.placeholder}
        className="autocomplete col full"
        classNamePrefix="separator"
        classNameContentField="field__street"
        autoFocus={false}
        maxLength={100}
        tabIndex={startIndex + 2}
        filterOption={autoCompleteFormFilter}
        options={isStreetLoading ? [] : streets}
        noOptionMessageText={subscribeFormLangData.formContent.noStreetOption}
        isOptionSelected={(value) =>
          !Array.isArray(value) &&
          (value as SingleValue<T>)?.label === values.street
        }
        isLoading={isStreetLoading}
        disabled={disabled}
      />
      {streetNumbers ? (
        <FormField<T>
          inputElement={FormAutoComplete}
          reactSelectElement={
            componentType === 'parkingInfo' ? Select : Creatable
          }
          name={FormDataKey.streetNumber}
          required
          value={
            values.streetNumber
              ? ({
                  value: FormDataKey.streetNumber,
                  label: values.streetNumber,
                } as SingleValue<T>)
              : ''
          }
          onChange={(value) => {
            const val = value as SingleValue<T>;
            setFieldValue(
              FormDataKey.streetNumber,
              val ? val.label : '',
              val ? val.data?.defaultOption : false
            );
          }}
          placeholder={
            subscribeFormLangData.formContent.streetNumber.placeholder
          }
          className="autocomplete col full"
          classNamePrefix="separator"
          classNameContentField="field__street-number"
          autoFocus={false}
          maxLength={100}
          tabIndex={startIndex + 3}
          filterOption={autoCompleteFormFilter}
          options={streetNumbers}
          noOptionMessageText={
            subscribeFormLangData.formContent.noStreetNumberOption
          }
          isOptionSelected={(value) => {
            return (
              !Array.isArray(value) &&
              (value as SingleValue<T>)?.label === values.streetNumber
            );
          }}
          components={
            componentType === 'parkingInfo'
              ? {
                  IndicatorSeparator: () => null,
                }
              : {
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }
          }
          disabled={disabled}
        />
      ) : (
        <FormField
          inputElement="input"
          name={FormDataKey.streetNumber}
          required
          value={values.streetNumber}
          className="inp"
          classNameContentField="field__street-number"
          autoFocus={false}
          type="text"
          maxLength={7}
          tabIndex={startIndex + 3}
          placeholder={
            subscribeFormLangData.formContent.streetNumber.placeholder
          }
          onChange={(event) => {
            setFieldValue(
              FormDataKey.streetNumber,
              (event as ChangeEvent<HTMLInputElement>).target.value
            );
          }}
          disabled={disabled}
        />
      )}
    </>
  );
};
