import { ActiveSubscribeForm, OwnerType ,
  SubscriptionAdvantage,
  SubscriptionOptionIncludeSubList,
  SubscriptionType,
  GenderTitle,
} from '../libraries/sections/models/Subscriptions';
import { BenefitWithoutKeys } from '../libraries/sections/models/Benefits';
import { ItemWithoutKeys } from '../libraries/sections/models/Items';
import { StepWithoutKeys, StepWithKeys, StepOwnerKeys } from '../libraries/sections/models/Steps';
import { ChoiceKeys } from '../libraries/sections/models/Choices';

import { ErrorKey } from '../libraries/sections/models/Common';

export const fr = {
  common: {
    routes: {
      home: {
        name: "Accueil",
        title: "Accueil",
        dataTest: "home",
      },
      privateUser: {
        name: "Particuliers",
        subHeaderLink1: {
          name: "Découvrir la solution",
          title: "Découvrir la solution",
        },
        subHeaderLink2: {
          name: "Souscrire à l'abonnement",
          title: "Souscrire à l'abonnement",
        }
      },
      owner: {
        name: "Acteur de l'immobilier",
        title: "Acteur de l'immobilier",
      },
      subscriptions: {
        name: "Abonnements",
        title: "Abonnements",
      },
      faq: {
        name: "Questions fréquentes",
        title: "Questions fréquentes",
      },
      subscribe: {
        name: "Souscrire",
        title: "Souscrire",
      },
      equipParking: {
        name: "Équiper son parking",
        title: "Équiper son parking",
      },
      contact: {
        name: "Nous contacter",
        title: "Nous contacter",
      }
    },
    header: {
      openMenu: "Ouvrir le menu",
      needHelp: "Nous contacter",
      dropDownTitle: "Particuliers",
      needInformation: {
        title: "Besoin d'information ?",
        phoneNumber: {
          label: "0848 802 900",
            value: "0848802900"
        },
        schedule: "Du lundi au vendredi de 7h30 à 17h30"
      },
      technicalIssue: {
        title: "Un problème technique ?",
        phoneNumber: {
          label: "021 651 20 00",
          value: "0216512000"
        },
        schedule: "Accessible 24h/24 et 7j/7"
      }
    },
    footer: {
      corp: "Romande energie en direct",
      contact: {
        title: "Nous contacter",
        name: "Romande Énergie",
        address: "Rue de Lausanne 53 - 1110 MORGES",
        email: "Par email: ",
        email_value: "clickandcharge@romande-energie.ch",
        phone: "Par tél: ",
        phone_value: "021 802 90 90 / du lundi au vendredi de 7h30 à 17h30",
        copyright: "2024 © ROMANDE ÉNERGIE",
        protect: "Protection de vos données",
        conditions_generales: "Conditions générales",
      },
    },
    doYouKnow: {
      title_min: "Le saviez-vous ?",
      title_max: "Pourquoi des bornes \"intelligentes\" ?",
      detail: "Installer plusieurs bornes dans un parking peut générer une surcharge électrique. Avec Click&Charge, aucun risque : les bornes sont équipées d'un système de gestion intelligente de la charge. Aucun renforcement de l'installation électrique n'est donc nécessaire."
    },
    currency: "CHF",
    currencySymbol: ".-",
    terminalAt: "coût de la borne à",
    month: "mois",
    downloadBtn: "Je télécharge la fiche produit",
    faqBtn: "Questions fréquentes",
    contactBtn: "Nous contacter"
  },
  homePage: {
    logoCloud: {
      title: "Ils nous font confiance",
    },
    banner: {
      title: "Click&Charge",
      detail1: "La mobilité électrique accessible à tous !",
      detail2:
        "Click&Charge permet d'équiper en bornes de recharge privées tout parking collectif que ce soit en PPE ou en immeuble locatif.",
      viewSubBtn: "Voir le détail des Abonnements",
      doYouKnow: "Le saviez-vous ?",
    },
    discoverSection: {
      title: "Découvrez le service Click&Charge",
      describe:"Spécialement conçue pour optimiser la puissance de recharge disponible dans les parkings collectifs et éviter le risque de surcharge électrique, <strong>Click&Charge est une solution clé en main intégrant le financement de l'équipement, la réalisation des installations et la gestion des utilisateurs.</strong>",
      houseOwnerBtn: "Je cherche une solution pour ma maison individuelle",
      choices: {
        [ChoiceKeys.USER_WITHOUT_PARKING]: {
          title:
            "<strong>Mon parking n'est pas équipé</strong> de la solution Click&Charge",
          dataTest: "non-equiped-button",
          button:
            "Je veux équiper mon parking",
        },
        [ChoiceKeys.USER_WITH_PARKING]: {
          title: "<strong>Mon parking est équipé</strong> de la solution Click&Charge",
          dataTest: "equiped-button",
          button:
            "Je veux souscrire au service",
        },
        [ChoiceKeys.OWNER]: {
          title:
            "<strong>Acteur de l'immobilier</strong> à la recherche d'une solution de recharge",
          dataTest: "ra-actors-button",
          button:
            "Je veux équiper un parking",
        },
      },
      contact: {
        title: "Nous contacter",
      },
    },
  },
  userWithoutParkingPage:{
    banner: {
      title: "Vous aimeriez installer des bornes de recharge <br> dans un parking collectif ?",
      detail1: "C'est aujourd'hui possible !",
      detail2: "Click&Charge permet d'équiper en bornes de recharge tout parking collectif. Romande Energie prend en charge tous les coûts d'installation d'une infrastructure standard et les utilisateurs paient pour le service de recharge incluant la borne.",
      detail3: "*Hors cas complexes",
    },
    discoverSection: {
      title: "Une démarche simplifiée pour les propriétaires et les locataires",
      describe: "Installer des bornes de recharge dans un immeuble collectif pourrait paraître compliqué, nécessitant des investissements pour les propriétaires et un long processus de validation. Click&Charge simplifie tout cela grâce à une solution financée par Romande Énergie qui pré-équipe le parking afin de proposer aux occupants un service de recharge personnalisé.",
    },
    benefitSection: {
      benefits: {
        [BenefitWithoutKeys.FUNDED]: {
          title: "Une installation financée par Romande Énergie",
          detail:
            "Le parking est pré-équipé aux frais de Romande Energie. Seuls les utilisateurs des bornes de recharge paient pour le service et l'électricité consommée.",
        },
        [BenefitWithoutKeys.TURNKEY]: {
          title: "Une solution clés en main",
          detail:
            "Pré-équipement du parking, installation des bornes, facturation et dépannage : Romande Énergie se charge de tout.",
        },
        [BenefitWithoutKeys.SIMPLIFY]: {
          title: "Un accès simplifié aux bornes de recharge",
          detail:
            "Une fois l'infrastructure installée, chaque occupant souhaitant une borne souscrit en ligne, sans passer par les propriétaires.",
        },
      },
    },
    offerSection:{
      title1: "Pré-équipement du parking",
      title2: "Nos abonnements",
      alreadyBtn: "Clicl&Charge est déjà installé dans mon parking",
      bloc: {
        title:"Investissement de l'infrastructure",
        detail:"(hors frais de génie civil ou installations complexes)",
        price:"Pris en charge par Romande Énergie",
        detail2:"Dans un premier temps Romande Energie pré-équipe la totalité du parking, à ses frais, à l'aide d'un câble plat sur lequel seront installées les bornes Click&Charge individuelles.",
        detail3:"Chaque utilisateur pourra ensuite souscrire à l'abonnement de son choix dans lequel est compris le pré-équipement du parking et sa borne.",
      },
      items: {
        [ItemWithoutKeys.RENT]: {
          title:
            "Location de la borne",
          detail:
            "CHF 52.-/Mois",
        },
        [ItemWithoutKeys.BUY]: {
          title: "Achat de la borne",
          detail:
            "CHF 21.-/Mois",
        },
      },
    },
    stepSection: {
      steps: {
        [StepWithoutKeys.CHOICE]: {
          title:
            "Demandez une offre",
          detail:
            "A l'aide du formulaire de demande d'offre, <strong>transmettez-nous votre contact</strong> (gérance ou propriétaire), nous gérons l'offre avec eux et lançons les travaux.",
        },
        [StepWithoutKeys.INSTALLATION]: {
          title: "Installation dans votre parking",
          detail:
            "Nos installateurs pré-équiperont le parking de votre immeuble, <strong>pour accueillir les futures bornes de recharge.</strong>",
        },
        [StepWithoutKeys.SOUSCRIPTION]: {
          title:
            "Souscription et installation de votre borne",
          detail:
            "Souscrivez en ligne à l'abonnement qui vous convient le mieux. <strong>Nous installerons votre borne privée sur votre place de parc.</strong> <br> Rechargez en toute simplicité !",
        },
      },
      title: "Comment bénéficier du service Click&Charge",
    },
    callToActionSection: {
      question : "Intéressé par notre solution ?",
      btnToForm : "Je demande une offre",
    },
  },
  userWithParkingPage:{
    banner: {
      title: "Votre parking est déjà pré-équipé avec Click&Charge ?",
      detail2: "L'entier de votre parking dispose de l'infrastructure pour pouvoir installer votre borne de recharge. Passez à la prochaine étape !",
    },
    discoverSection: {
      title: "Souscrire au service",
    },
    stepSection: {
      steps: {
        [StepWithKeys.SOUSCRIPTION]: {
          title: "Choisissez l'offre qui vous convient",
          detail:
            "Achetez ou louez votre borne, puis souscrivez à l'abonnement.",
        },
        [StepWithKeys.PLANIFICATION]: {
          title: "Planifiez l'installation de la borne",
          detail:
            "Nous installons votre borne de recharge sous 2 semaines.",
        },
        [StepWithKeys.RECHARGE]: {
          title: "Rechargez votre véhicule",
          detail:
            "Sans risque de surcharge et avec de l'énergie renouvelable à prix compétitif.",
        },
      },
    },
    pricesSection: {
      header: {
        title: "Nos abonnements:",
        titleCol1: "Location",
        titleCol2: "Achat",
      },
      bornePrice: {
        title: "Prix de la borne",
        describe: "Borne privée intelligente d'une puissance de 22kw sur votre place de parc",
        titleCol1: "CHF 0.-",
        descCol1: "inclus",
        titleCol2: "CHF 2120.-",
      },
      service: {
        title: "Service Click&Charge",
        titleCol1: "CHF 52.-/mois",
        titleCol2: "CHF 21.-/mois*",
        item1: "Location du pré-équipement du parking",
        item2: "Gestion intelligente de la charge",
        item3: "Application mobile",
        item4: "Facturation individuelle de la consommation",
        item5:"Service de piquet accessible 24h/24",
        item6:"Énergie 100% renouvelable",
        item7:"Frais de dépannage compris",
        tooltips:{
          item6:"Nous sélectionnons le tarif 100% renouvelable le moins cher de votre fournisseur d'énergie local. Essentiellement de l'énergie hydraulique.",
          item7:"Coût d'une intervention de dépannage: entre CHF 100.- et CHF 300.-, dépendant de l'heure/jour.",
        },
        asterisk: "*CHF 15.-/mois pour les parkings signés avant le 31.03.24."
      },
      activation: {
        title: "Frais d'activation",
        rentPrice: "CHF 380.-",
        buyPrice: "CHF 380.-",
      },
      bonus: "Votre consommation d'électricité n'est pas comprise dans votre abonnement et dépend du tarif de votre fournisseur local.",
      btnRent:"Je choisis la location",
      btnOwn:"Je choisis l'achat",
      downloadCguBtn: "Télécharger les conditions générales",
    },
  },
  ownerPage: {
    banner: {
      title: "Acteurs de l'immobilier : répondez aux besoins de vos clients",
      detail1: "Bornes de recharge sans investissement pour les propriétaires*",
      detail2:
        "Click&Charge permet aux propriétaires de faire face à la demande croissante de bornes de recharge pour véhicules électriques, ceci sans investissement de leur part. Seuls les utilisateurs des bornes paient pour le service.",
      detail3: "*Hors cas complexes",
    },
    discoverSection: {
      title: "Le service Click&Charge",
      describe1: "Click&Charge est une solution technologique évolutive pour les logements collectifs, permettant à chaque résident qui le souhaite de faire installer rapidement une borne de recharge individuelle sur sa place de parc.",
      describe2: "Disposant d'un système de recharge intelligente, les bornes communiquent entre elles et permettent de répartir équitablement la puissance disponible dans le parking pour tous les utilisateurs, évitant toute dépense de renforcement électrique pour les propriétaires",
    },
    benefitSection: {
      benefits: {
        [BenefitWithoutKeys.FUNDED]: {
          title: "Aucun investissement",
          detail:
            "Le parking est pré-équipé aux frais de Romande Énergie. Seuls les utilisateurs des bornes de recharge paient pour le service.",
        },
        [BenefitWithoutKeys.TURNKEY]: {
          title: "Une démarche simplifiée",
          detail:
            "Financement, installation, gestion des utilisateurs : Romande Énergie se charge de tout avec chaque partie prenante.",
        },
        [BenefitWithoutKeys.SIMPLIFY]: {
          title: "À la pointe de la technologie",
          detail:
            "Une solution technique conforme aux exigences des normes, pérenne et évolutive.",
        },
      },
    },
    stepSection: {
      steps: {
        [StepOwnerKeys.ANALYSE]: {
          title:
            "Analyse du parking",
          detail:
            "Contactez-nous pour déterminer si le projet répond aux critères du pré-équipement.",
        },
        [StepOwnerKeys.CONTRACT]: {
          title: "Contrat",
          detail:
            "Nous vous soumettons un contrat à faire signer au propriétaire ou à la PPE.",
        },
        [StepOwnerKeys.PREEQUIPE]: {
          title:
            "Pré-équipement",
          detail:
            "Nous pré-équipons le parking pour que chaque utilisateur puisse faire installer sa borne personelle.",
        },
      },
      title: "Comment bénéficier du service Click&Charge ?",
    },
    callToActionSection:{
      question:"Intéressé par notre solution ?",
      btn:"Je demande une offre",
    },
  },
  forms: {
    subscribe: {
      title: {
        [ActiveSubscribeForm.PARKING_SUBSCRIPTION]: "Souscrire et planifier l'installation de ma borne",
        [ActiveSubscribeForm.PARKING_ADDRESS]: "Souscrire et planifier l'installation de ma borne",
        [ActiveSubscribeForm.NOT_FOUND]: "Souscrire et planifier l'installation de ma borne",
        [ActiveSubscribeForm.PARKING_REGISTRATION]: "Pré-équiper un parking",
      },
      detail: {
        contact: "Coordonnées",
        contactWithout: "Vos Coordonnées",
        info: "Informations sur votre parking souterrain",
        notFound: "Click&Charge n'est pas encore installé dans votre parking",
        subscription: "Votre souscription",
        invoicing: "Votre adresse de facturation",
        summary: "Récapitulatif",
        facturation: "Adresse de facturation"
      },
      describe: {
        info: "Afin de vérifier si le service Click&Charge est déjà disponible dans votre parking souterrain, veuillez entrer votre adresse de parking.",
        contact:"Afin de pouvoir installer Click&Charge dans votre parking, nous avons besoin de vos coordonnées.",
        facturation: "Afin de pouvoir facturer le service Click&Charge, nous avons besoin de votre adresse de domicile.",
        notFound: "Prenez contact avec nous afin de discuter de votre projet.",
      },
      formContent: {
        noCityOption: "Ma ville n'existe pas dans la liste",
        noStreetOption: "Ma rue n'existe pas dans la liste",
        noStreetNumberOption: "Mon numéro de rue n'existe pas dans la lite",
        noParkingAvailable: "Click&Charge n'est pas encore installé dans votre parking. ",
        cityNotFound: "Je ne trouve pas ma localité",
        cityNotAvailable: "Aucun parking disponible dans cette localité",
        addressNotFound: "Je ne trouve pas mon adresse",
        numberNotFound: "Je ne trouve pas mon numéro",
        npa: {
          label: "NPA",
          validation: {
            required: "Le Npa est requis",
            min: "Le Npa devrait être supérieur ou égal à 1000",
            max: "Le Npa devrait être inférieur ou égal à 9999"
          }
        },
        local: {
          label: "Localité",
          placeholder: "Sélectionnez dans la liste",
          validation: {
            required: "La localité est requise"
          }
        },
        address: {
          label: "Rue et numéro",
          placeholder: "Sélectionnez dans la liste",
          validation: {
            required: "La rue est requise"
          }
        },
        streetNumber: {
          placeholder: "123",
          validation: {
            required: "Le numéro de rue est requis"
          }
        },
        required: "Champs obligatoires",
        validate: "Valider",
        lastName: "Nom",
        firstName: "Prénom",
        email: "Email",
        phone: "Téléphone mobile",
        parkingPlaceNum: "N° de la place de parc",
        acceptRule:
          "J'accepte les conditions générales ci-dessous.",
        acceptUseDataCommercial:
            "J'autorise Romande Énergie SA à utiliser mes <1>données</1> personnelles, y compris mes données de consommation, afin de me proposer des services complémentaires dans le domaine de l'énergie.",
        condition: "Télécharger les conditions générales",
        genderPlaceholder: "M.,Mme",
        gender: {
          [GenderTitle.MR]: "M.",
          [GenderTitle.MS]: "Mme",
        },
        checkANewAddress: "Vérifier une nouvelle adresse",
        areYou: "Êtes-vous ?",
        roleSelect: {
          [OwnerType.TENANT]: "Locataire",
          [OwnerType.COOWNER]: "Copropriétaire",
          [OwnerType.PPE]: "Administrateur PPE",
          [OwnerType.BUILDING_OWNER]: "Propriétaire d'un immeuble",
          [OwnerType.TRAFIC_IMMEUBLE]: "Gérant d'immeubles",
        },
        socialReason: "Raison sociale",
        postCodeAndTown: "NPA et localité",
        parkingSpaceNumber: {
          label: "Nombre de places",
          validation: {
            min: "Ce champs devrait être supérieur ou égal à 0",
            max: "Ce champs devrait être inférieur ou égal à 10000"
          }
        },
        canBeUpdateAfter: "Ce nombre peut être ajusté par la suite",
        peopleNumber: {
          label: "Nombre de personnes\nintéressées par une borne",
          validation: {
            min: "Ce champs devrait être supérieur ou égal à 0",
            max: "Ce champs devrait être inférieur ou égal aux nombres de places"
          }
        },
        addParkingBtn: "Ajouter un parking supplémentaire",
        delParkingBtn: "Supprimer",
        confirmationParkingDeletion: {
          title: "Supprimer le parking:",
          content: "Veuillez confirmer que vous souhaitez supprimer ce parking.",
          delete: "Supprimer",
          cancel: "Annuler",
        },
        iDontKnow: "Je ne sais pas",
        isConsentChecked:
          "J'autorise l'utilisation de mes <1>données</1> à des fins de conseil et d'information concernant les bornes de recharge.",
        isConsentSearch:
          "J'autorise Romande Énergie à aller chercher les plans du parking auprès du registre foncier ainsi que la puissance de raccordement du bâtiment auprès du gestionnaire de réseau.",
        scheduleTelephoneBtn: "Suivant",
        calendlyHeader: "Choisissez un horaire",
        calendlyCloseBtn: "Fermer",
        subscriptionReselect: {
          bornPrice: "Prix de la borne",
          title: "Votre souscription",
          subscribe: "Votre abonnement",
          parkingPlaceNumber: "N° de la place de parc",
          options: {
            rent: "Location de la borne",
            purchase: "Achat de la borne",
          },
          cableSupport: "Support de cable",
          connectionCable: "Cable de branchement",
          activationCard: "Carte d'activation",
          additionalActivationCard: "Carte d'activation supplémentaire",
          additionalActivationCard_plural: "Cartes d'activations supplémentaires",
          wallOutlet: "Support de prise murale",
        },
        cables: {
          default: "Ajouter un cable de branchement",
          standardType1: "Type 2 Type 2 Forme standard 5 m 295.- CHF",
          longType1: "Type 2 Type 2 Forme standard 7.5 m 355.- CHF",
          twistedType1: "Type 2 Type 2 Forme torsadée 5 m 395.- CHF",
          standardType2: "Type 2 Type 1 Forme standard 5 m 295.- CHF",
          extraLongType1: "Type 2 Type 2 Forme standard 10 m 385.- CHF",
        },
        supportCable: {
          default: "Ajouter un support de cable",
          classic: "Classique 35.- CHF",
          premium: "Support Premium (Easee) 95.- CHF",
        },
        autoComplete: {
          noResults: "Pas de résultat pour",
        },
        isUseParkingAddressChecked: "Utiliser cette même adresse pour la facturation",
        next: "Valider et Continuer",
        options: {
          badge: "Badge d'utilisation",
          support: "Support de prise murale",
          included: "Inclus",
          notIncluded: "Non inclus",
        },
        appointment: {
          info: "Nous avons maintenant toutes les infos nécessaires à votre souscription.<1 /><2>Il ne vous reste plus qu'à prendre RDV pour installer votre borne.</2>",
        },
        whichCable: "Quel cable choisir ?",
        cableType1: "Type 2 Type 2",
        cableType2: "Type 2 Type 1",
        standard: "Standard",
        twisted: "Torsadé",
        totalPrice: "Coût total",
        uniquePrice: "Tarif unique",
        activationPrice: "Coût d'activation",
        chf: "CHF",
        pointChf: ".-CHF",
        monthly: "Mensuel",
        byMonth: " / mois",
        clientNumber: "Numéro client",
        whereToFindClientNumber: "Où trouver mon numéro client ?",
        onYourInvoicings: "Il se trouve sur vos factures",
        companyName: "Nom de l'entreprise",
        reason: {
          anotherAddress: "Je suis le payeur mais j'ai une autre adresse",
          thirdParty: "Adresse d'un tiers payeur",
        },
        clickAndChargeNotIntstalledYet: "Click&Charge n'est pas encore installé dans votre parking",
        contactUs: "<0><0>Prenez contact avec nous</0> afin de discuter de votre projet.</0>",
        checkMyEligibility: "Vérifier mon éligibilité",
        checkYourParkingEligibility: "Vérifions l'éligibilité de votre parking",
        fillYourAddress: "Avant de souscrire à nos abonnements nous devons connaitre l'adresse de votre parking afin de savoir si il est déjà pré-équipé",

      },
      success: {
        title: "Votre souscription a bien été prise en compte.<1 /><2>Nous reviendrons vers vous pour la prise de rendez-vous.</2>",
        information: "Vous allez être redirigés vers la page d'accueil dans {{timer}} secondes. Si cela ne fonctionne pas, veuillez cliquer <3>ici</3>...",
      },
      button: {
        next: "Valider et Continuer",
        modify: "Modifier",
        delete: "Supprimer",
        previousStep: "Étape précédente",
        addSubscription: "Ajouter une place supplémentaire"
      }
    },
    validation: {
      title: "Bravo",
      titleBis: "Vous avez complété votre souscription",
      subtitle: "La confirmation de votre souscription ainsi que toutes les infos relatives à la pose de votre borne vous ont été envoyées par mail",
      btn: "RETOUR ACCUEUIL"
    },
  },
  faq: {
    billings: {
      differenceBetweenBillingAndEaseeApp: "<0>Votre borne de recharge easee est associée à un compte utilisateur easee, accessible à travers l'application mobile easee. L'application vous permet entre autre, de suivre l'état de charge de votre batterie et de suivre votre consommation totale dans le temps, par mois ou par année.</0><1 /><2>D'autre part, vous recevrez chaque mois une facture Romande Énergie dans laquelle figurent votre consommation totale pour le mois ainsi que le tarif au kWh propre à votre contrat.</2><3 /><4>Les données de votre facture et de l'application easee peuvent différer pour les raisons suivantes\:</4><5 /><6><0>Votre facture tient compte de la consommation de la borne pour recharger votre véhicule ainsi que de la consommation d'électricité propre à la borne (hors recharge). L'application easee n'indique pas la consommation propre à la borne, qui est de l'ordre de 0.1 à 0.5 kWh/jour.</0><1>D'autre part, Romande Énergie facture l'entier de la consommation du mois jusqu'au dernier jour du mois à 0h00, alors que l'application easee affiche les données de consommation en fonction de la <1>date de fin de recharge</1>. Sur l'application easee, la dernière session de recharge enregistrée sur la période est donc en général reportée sur la période suivante.</1></6><7 /><8>Par exemple, si la dernière recharge de la période 01.01.2022 au 31.01.2022 a eu lieu entre le 31.01.2022 à 21:30 et le 01.02.2022 à 09:45, pour une quantité totale d'électricité consommée de 10 kWh, une partie de cette électricité a été consommée le 31.01 (4 kWh) et le reste le 01.02 (6 kWh). La portion d'électricité effectivement consommée le 31.01 (4 kWh) est comptabilisée dans la facture de janvier 2022 alors que la session de recharge de 10 kWh apparait sur février sur l'application easee.</8>",
      fullHour: "Heures pleines",
      offPeakHours: "Heures creuses",
      simpleHours: "Heures simples",
      electricPower: "Énergie électrique",
      regionalRouting: {
        title: "Acheminement régional",
        info: "Acheminement régional - <1><0>abonnement mensuel au compteur du fournisseur d'énergie locale</0></1>"
      },
      nationalTransportAndSystemServices: "Transport national et services système",
      totalExcludingTaxesAndExcludingSubscription: "TOTAL hors taxes et hors abonnement",
      federalTaxes: "Taxes fédérales",
      cantonalTaxes: "Taxes cantonales (non assujetties à la TVA)",
      cantonalFee: "Émolument cantonal",
      specificMunicipalTax: "Taxe communale spécifique - exemple (non assujettie à la TVA)",
      municipalFee: "Émolument communal - exemple",
      totalExcludingSubscription: "TOTAL - hors abonnement",
      ctsKwh: "Cts./kWh",
      chfMonthly: "CHF/mois"
    },
    grants: {
      fribourgCanton: {
        infoLink: "<0>Les personnes qui choisissent d'acheter une borne peuvent solliciter une subvention cantonale, à partir du 1er juillet 2022. La demande de subventions se fait directement auprès des autorités cantonales, sur présentation de la facture d'achat de la borne. Nous vous invitons à vous référer au site : <2>www.fr.ch/deef/sde/borne-de-recharge-pour-vehicules-electriques-mesure-fr-br1</2> pour plus d'informations. Les demandes de subventions doivent être déposées sur <6>www.leprogrammebatiments-fr.ch</6>.</0>",
        noGuarantee: "Romande Énergie ne peut en aucun cas garantir l'obtention de subventions auprès du Canton. Le Canton est le seul décideur dans l'octroi ou non des subventions, et dans la définition du montant final de celles-ci.",
        noImpactOnRate: "L'obtention, ou non, d'une subvention cantonale, n'a pas d'impact sur les tarifs de l'offre Click&Charge."
      },
      geneveCanton: {
        info: "<0>Les personnes qui choisissent d'acheter une borne peuvent solliciter une subvention cantonale. La demande de subventions se fait directement auprès des autorités cantonales, sur présentation de la facture d'achat de la borne. Nous vous invitons à vous référer au site : <2>www.ge.ch/subventions-borne-electromobilite/subvention-borne-electromobilite-parking-collectif</2> pour plus d'informations.</0>",
        noGuarantee: "Romande Énergie ne peut en aucun cas garantir l'obtention de subventions auprès du Canton. Le Canton est le seul décideur dans l'octroi ou non des subventions, et dans la définition du montant final de celles-ci.",
        noImpactOnRate: "L'obtention, ou non, d'une subvention cantonale, n'a pas d'impact sur les tarifs de l'offre Click&Charge."
      },
      valaisCanton: {
        info: "<0>Les personnes qui choisissent d'acheter une borne peuvent solliciter une subvention cantonale. La demande de subventions se fait directement auprès des autorités cantonales, sur présentation de la facture d'achat de la borne. Nous vous invitons à vous référer au site : <2>www.vs.ch/web/agenda2030/demander-une-prime</2> pour plus d'informations.</0>",
        infoFromCanton: "Le Canton a annoncé que le programme de soutien à la mobilité électrique, qui a connu un franc succès, se terminera à fin septembre 2022.",
        noGuarantee: "Romande Énergie ne peut en aucun cas garantir l'obtention de subventions auprès du Canton. Le Canton est le seul décideur dans l'octroi ou non des subventions, et dans la définition du montant final de celles-ci.",
        noImpactOnRate: "L'obtention, ou non, d'une subvention cantonale, n'a pas d'impact sur les tarifs de l'offre Click&Charge."
      },
      vaudCanton: {
        uniqueGrantRequest: "Dans le cas des parkings situés dans le Canton de Vaud, Romande Énergie réalise une demande unique de subvention, pour l'ensemble du parking, conformément aux exigences cantonales.",
        withBorneLocation: "Avec location de la borne",
        withBornePurchase: "Avec achat de la borne",
        bornePrice: "Coût de la borne",
        activationFee: "Frais d'activation",
        monthlySubscription: "Abonnement mensuel",
        noGuarantee: "Romande Énergie ne peut en aucun cas garantir l'obtention de subventions auprès du Canton. Le Canton est le seul décideur dans l'octroi ou non des subventions, et dans la définition du montant final de celles-ci.",
        grantCondition: "Les conditions d'octroi exigent notamment :",
        grantCondition1: "La subvention est dédiée aux places de parc existantes construites avant 2021",
        grantCondition2: "Les places à équiper sont dédiées à un bâtiment existant, immeuble d'habitation collectif (PPE ou locatif) d'au moins 3 unités. Il est à noter que les villas contigües (ou villas en chainette) sont considérées comme de l'habitat individuel et ne peuvent donc pas prétendre à une subvention.",
        grantForSubscribers: "Si le Canton décide d'octroyer la subvention pour le parking, Romande Énergie perçoit l'entier des subventions, et fait bénéficier les occupants du parking d'une réduction du prix :",
        activationFeeReduction: "Réduction sur le prix de la borne : Tous les occupants du parking qui souscrivent dans les trois mois après la fin des travaux de pré-équipement dans le parking peuvent bénéficier des rabais suivants :",
      }
    }
  },
  contactPage: {
    title: "Nous contacter",
    emailBloc: {
      description: "N'hésitez pas à nous écrire si vous avez des questions. Vous serez contactés dans les 48 heures (jours ouvrés).",
      question: "Demander une offre ou poser une question",
    },
    phoneBloc: {
      question:"Une question ou une urgence ?",
      phone_number:"021 802 90 90",
      subtitle1:"Administration : du lundi au vendredi de 7h30 à 17h30",
      subtitle2:"Service de piquet : 24/7",
    },
  },
  errorMessage: {
    [ErrorKey.largerParkingLot]:
      "Le nombre de personnes intéressées doit être inférieur ou égal au nombre de places dans le parking",
    required: "Ce champ est requis",
    email: "Une adresse email valide est nécessaire",
    phone: "Un numéro de téléphone valide est nécéssaire",
    tooLong: "Merci de rentrer moins de 60 charactères",
    numberTooLong: "Merci de rentrer moins de 99999",
    unexpectedError: "Une erreur s'est produite durant la recherche de votre parking. Veuillez contacter le support de l'application",
    consent: "Vous devez accepter les conditions génerales d'utilisation pour continuer",
    mustBeANumber: "Ce champs doit être un nombre",
    parkingPlaceNumberType: "Votre numéro de place doit être un nombre",
  },
};
