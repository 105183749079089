import React, { useContext, useEffect } from 'react';

import { Icon } from '../Icon/Icon';
import { ErrorContext } from '../../views/Layouts/NormalLayout';

export const Error = () => {
  const { error, setError } = useContext(ErrorContext);

  useEffect(() => {
    if (error && window) {
      window.scrollTo(0, 0);
    }
  }, [error]);

  return (
    <div className={`error-baner${!error ? ' disabled' : ''}`}>
      <span>{error}</span>
      <button onClick={() => setError(undefined)} className="error-baner__btn">
        <Icon type="Cross" color="#fff" />
      </button>
    </div>
  );
};
