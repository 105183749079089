import { useTranslation } from 'react-i18next';

import { LanguageData } from '../../../libraries/sections/models/Common';

import { ParkingSubscriptionResumeList } from './ParkingSubscriptionResumeList';

import { PreviousStepResume } from './PreviousStepResume';

export const SubscriptionStep = () => {
  const { t } = useTranslation();

  const formTranslation: LanguageData = t('forms.subscribe', {
    returnObjects: true,
  });

  return (
    <div className="page-form page-form__form">
      <div>
        <PreviousStepResume propertyKey="coordinates" />
        <h2 className="page-form__form__detail title">
          {formTranslation.detail.subscription}
        </h2>
      </div>
      <ParkingSubscriptionResumeList />
    </div>
  );
};
