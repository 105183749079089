import React from 'react';

import { useTranslation } from 'react-i18next';

export const DiscoverSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-normal">
      <div className="container">
        <div className="row max-w-standard m-auto pt-64">
          <h2 className="text-center text-red-re py-3">
            {t('userWithParkingPage.discoverSection.title')}
          </h2>
        </div>
      </div>
    </section>
  );
};
