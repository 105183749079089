//#region Basic key
export enum SubscriptionOptionKeys {
  RENT = 'rent',
  PURCHASE = 'purchase',
}

export enum SubscriptionOptionIncludeSubList {
  CONFIG = 'config',
  PLUG = 'plug',
  REMOTECONTROL = 'remoteControl',
  INTELLIGENT = 'intelligent',
  BILLING = 'billing',
  ALLOFDAY = 'allOfDay',
  RENEWABLE = 'renewable',
  BREAKDOWNCOST = 'breakdownCosts',
}

export enum SubscriptionAdvantage {
  WITH_OR_WITHOUT_PURCHASE = 'withWithoutPurchase',
  FULLTANK = 'fullTank',
  FINALLY = 'finally',
}

export enum ActiveSubscribeForm {
  PARKING_ADDRESS = 'parking-address',
  PARKING_SUBSCRIPTION = 'parking-subscription',
  PARKING_SUBSCRIPTION_SUCCESS = 'parking-subscription-success',
  NOT_FOUND = 'not-found',
  PARKING_REGISTRATION = 'parking-register',
}

export enum GenderTitle {
  MS = 'Madame',
  MR = 'Monsieur',
}

export enum SubscriptionType {
  RENT = 'rent',
  PURCHASE = 'purchase',
}

export enum OwnerType {
  COOWNER = 'coproprietaire',
  PPE = 'PPE',
  TENANT = 'locataire',
  BUILDING_OWNER = 'proprietaire_immeuble',
  TRAFIC_IMMEUBLE = 'gerant_immeuble',
}

export enum FormDataKey {
  npa = 'npa',
  city = 'city',
  street = 'street',
  streetNumber = 'streetNumber',
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  telephone = 'telephone',
  subscription = 'subscription',
  parkingPlaceNum = 'parkingPlaceNum',
  isConsentChecked = 'isConsentChecked',
  ownerType = 'ownerType',
  raisonSocial = 'raisonSocial',
  parkings = 'parkings',
  totalParkingPlaces = 'totalParkingPlaces',
  totalPeopleInterested = 'totalPeopleInterested',
  isConsentSearch = 'isConsentSearch',
  isUseDataCommercialChecked = 'isUseDataCommercialChecked',
  equipmentDealId = 'equipmentDealId',
  residence = 'residence',
  isUseParkingAddressChecked = 'isUseParkingAddressChecked',
}
//#endregion Basic key

//#region Forms type
export type SubscriptionOptionDataType = {
  key: SubscriptionType;
  priceInCHF: number;
  addOnPriceInCHF?: number;
  options: SubscriptionOptionIncludeSubList[];
};

export type SubscriptionAdvantageDataType = {
  [key in string]: string;
};
//#endregion Forms type

//#region Request interface
export interface ParkingRegisInfo {
  [FormDataKey.npa]: string;
  [FormDataKey.city]: string;
  [FormDataKey.street]: string;
  [FormDataKey.streetNumber]: string;
  [FormDataKey.totalParkingPlaces]: number;
  [FormDataKey.totalPeopleInterested]: number;
}

export interface ResidenceInfo {
  [FormDataKey.npa]: string;
  [FormDataKey.city]: string;
  [FormDataKey.street]: string;
  [FormDataKey.streetNumber]: string;
}

export interface FormDataInf {
  [FormDataKey.npa]: string;
  [FormDataKey.city]: string;
  [FormDataKey.street]: string;
  [FormDataKey.streetNumber]: string;
  [FormDataKey.title]?: GenderTitle | null;
  [FormDataKey.firstName]: string;
  [FormDataKey.lastName]: string;
  [FormDataKey.email]: string;
  [FormDataKey.telephone]: string;
  [FormDataKey.subscription]: SubscriptionType | null;
  [FormDataKey.parkingPlaceNum]: number;
  [FormDataKey.isConsentChecked]: boolean;
  [FormDataKey.ownerType]: OwnerType | null;
  [FormDataKey.raisonSocial]: string;
  [FormDataKey.parkings]: ParkingRegisInfo[];
  [FormDataKey.isConsentSearch]: boolean;
  [FormDataKey.isUseDataCommercialChecked]: boolean;
  [FormDataKey.equipmentDealId]?: number;
  [FormDataKey.residence]: ResidenceInfo;
}

export interface ParkingAddressCheck {
  [FormDataKey.npa]: string;
  [FormDataKey.city]: string;
  [FormDataKey.street]: string;
  [FormDataKey.streetNumber]: string;
}

export interface ParkingSubscriptionParams {
  [FormDataKey.npa]: string;
  [FormDataKey.city]: string;
  [FormDataKey.street]: string;
  [FormDataKey.streetNumber]: string;
  [FormDataKey.title]?: GenderTitle | null;
  [FormDataKey.firstName]: string;
  [FormDataKey.lastName]: string;
  [FormDataKey.email]: string;
  [FormDataKey.telephone]: string;
  [FormDataKey.subscription]: SubscriptionType;
  [FormDataKey.parkingPlaceNum]: number;
  [FormDataKey.isConsentChecked]: boolean;
  [FormDataKey.isUseDataCommercialChecked]: boolean;
  [FormDataKey.equipmentDealId]?: number | null;
  [FormDataKey.residence]: {
    [FormDataKey.npa]: string;
    [FormDataKey.city]: string;
    [FormDataKey.street]: string;
    [FormDataKey.streetNumber]: string;
  };
}

export interface ParkingLotRegistrationParams {
  [FormDataKey.ownerType]?: OwnerType;
  [FormDataKey.raisonSocial]?: string;
  [FormDataKey.title]?: GenderTitle | null;
  [FormDataKey.firstName]: string;
  [FormDataKey.lastName]: string;
  [FormDataKey.email]: string;
  [FormDataKey.telephone]: string;
  [FormDataKey.parkings]: {
    [FormDataKey.npa]: string;
    [FormDataKey.city]: string;
    [FormDataKey.street]: string;
    [FormDataKey.totalParkingPlaces]: number;
    [FormDataKey.totalPeopleInterested]: number;
  }[];
  [FormDataKey.isConsentSearch]: boolean;
  [FormDataKey.isConsentChecked]: boolean;
}
//#endregion Request interface
