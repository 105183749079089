import React from 'react';
import { Modal } from 'react-bootstrap';

type ConfirmationModalProps = {
  show: boolean;
  onValidate: () => void;
  onCancel: () => void;
  title: string;
  content: string;
  zIndex?: number;
  className?: string;
  validationButton: string;
  cancelButton: string;
};

export const ConfirmationModal = ({
  show,
  onValidate,
  onCancel,
  title,
  content,
  className = 'confirm-modal',
  validationButton,
  cancelButton,
}: ConfirmationModalProps) => {
  return (
    <Modal
      backdropClassName="confirm-modal-backdrop"
      show={show}
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
      className={className}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn--secondary btn--red" onClick={onValidate}>
          {validationButton}
        </button>
        <button className="btn btn--secondary" onClick={onCancel}>
          {cancelButton}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
