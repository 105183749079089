import React from 'react';

import { useTranslation } from 'react-i18next';

import { useLinks } from '../../../hooks/useLinks';
import { RedirectBtn } from '../../dumps/Buttons/RedirectBtn';

export const CallToActionSection = () => {
  const { t } = useTranslation();
  const links = useLinks();

  return (
    <>
      <section className="bg-normal pb-5">
        <div className="container">
          <div className="row max-w-standard m-auto pt-48">
            <p className="text-center text-black">
              {t('ownerPage.callToActionSection.question')}
            </p>
            <RedirectBtn
              link={links.equipParking().path}
              title={t('userWithoutParkingPage.callToActionSection.btnToForm')}
            />
          </div>
        </div>
      </section>
    </>
  );
};
