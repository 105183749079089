import React from 'react';

import { useTranslation } from 'react-i18next';

import { Banner } from '../../dumps/Banner/Banner';

import FaqCar from './../../../assets/images/faq_car.jpg';

export const BannerSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <Banner title={t('common.routes.faq.title')} background={FaqCar} />
    </>
  );
};
