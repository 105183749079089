import React from 'react';

import { useTranslation } from 'react-i18next';

import { useMobileChecker } from '../../../hooks/useMobileChecker';

import { DesktopPrices } from '../../dumps/Prices/DesktopPrices';
import { MobilePrices } from '../../dumps/Prices/MobilePrices';

export const PricesSection = () => {
  const { t } = useTranslation();
  const isMobileDisplay = useMobileChecker();

  return (
    <section className="bg-white pt-64">
      {isMobileDisplay ? (
        <MobilePrices hasBtn={true} />
      ) : (
        <DesktopPrices hasBtn={true} />
      )}
      <div className="container">
        <div className="row max-w-standard m-auto">
          <a
            className="text-center text-black pb-64 text-decoration-underline text-sm"
            href="https://www.romande-energie.ch/images/files/service-recharge-parking-prive/cg-click-and-charge.pdf"
          >
            {t('userWithParkingPage.pricesSection.downloadCguBtn')}
          </a>
        </div>
      </div>
    </section>
  );
};
