import React from 'react';

import { useTranslation } from 'react-i18next';

import { Banner } from '../../dumps/Banner/Banner';

import UserWithParking from '../../../assets/images/with.jpg';

export const BannerSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner
        title={t('userWithParkingPage.banner.title')}
        detail_2={t('userWithParkingPage.banner.detail2')}
        background={UserWithParking}
        overlay={true}
      />
    </>
  );
};
