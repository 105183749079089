import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import { Choice } from '../../dumps/Choice/Choice';
import { ChoiceType } from '../../../libraries/sections/models/Choices';

interface ChoicesSectionProps {
  choices: ChoiceType[];
}

export const ChoicesSection = ({ choices }: ChoicesSectionProps) => {
  const { t } = useTranslation();

  return (
    <section className="bg-normal">
      <div className="container">
        <div className="row max-w-standard m-auto">
          <h2 className="text-center text-red-re pt-64">
            {t('homePage.discoverSection.title')}
          </h2>
          <p className="text-left">
            <Trans i18nKey="homePage.discoverSection.describe" />
          </p>
        </div>
      </div>

      <div className="choice-section__options">
        {choices.map((choice: ChoiceType) => (
          <Choice
            key={choice.key}
            background={choice.background}
            button={t(`homePage.discoverSection.choices.${choice.key}.button`)}
            dataTest={t(
              `homePage.discoverSection.choices.${choice.key}.dataTest`
            )}
            title={t(`homePage.discoverSection.choices.${choice.key}.title`)}
            link={choice.link}
          />
        ))}
      </div>
      <div className="row max-w-standard m-auto pt-2 pb-64">
        <a
          href="https://www.romande-energie.ch/particuliers/habitat-et-services-energetiques/mobilite-electrique/borne-de-recharge-pour-vehicule-particuliers"
          className="text-center text-black text-decoration-underline"
        >
          {t('homePage.discoverSection.houseOwnerBtn')}
        </a>
      </div>
    </section>
  );
};
