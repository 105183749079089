import React from 'react';

import { useNavigate } from 'react-router-dom';

import {
  SubscriptionOptionDataType,
  SubscriptionOptionIncludeSubList,
} from '../../../libraries/sections/models/Subscriptions';

import './sub-options.scss';
import { useLinks } from '../../../hooks/useLinks';

import { Icon } from '../../dumps/Icon/Icon';

import {
  questionMarkIcon,
  subscriptionOptionsData,
  subscriptionOptionsIcon,
  useSubscription,
} from './useSubscription';

export const Options = () => {
  const {
    l: { subscriptionLangData, commonLangData },
  } = useSubscription();

  const navigate = useNavigate();
  const { subscribe, faq } = useLinks();

  return (
    <section className="sub-options bg-normal">
      <div className="sub-options__container container">
        <h2 className="sub-options__title cl-secondary">
          {subscriptionLangData.title}
        </h2>
        <div className="sub-options__options">
          {subscriptionOptionsData.map(
            (subscriptionOption: SubscriptionOptionDataType) => (
              <div key={subscriptionOption.key} className="sub-options__item">
                <div
                  className={`sub-options__item__cost ${
                    !subscriptionOption.addOnPriceInCHF ? 'rent' : ''
                  }`}
                >
                  <h2 className="sub-options__item__cost--title cl-secondary">
                    {subscriptionLangData.options[subscriptionOption.key]}
                  </h2>
                  <h3 className="sub-options__item__cost--cost">
                    {commonLangData.currency} {subscriptionOption.priceInCHF}
                    {commonLangData.currencySymbol}/{commonLangData.month}
                  </h3>
                  <p className="sub-options__item__cost--sub-cost">
                    {subscriptionLangData.clickAndChargeServices}
                  </p>
                  {!subscriptionOption.addOnPriceInCHF && (
                    <>
                      <p className="sub-options__item__cost--sub-cost">+</p>
                      <p className="sub-options__item__cost--sub-cost">
                        {subscriptionLangData.borneSubscription}
                      </p>
                    </>
                  )}
                </div>
                {subscriptionOption.addOnPriceInCHF && (
                  <div className="sub-options__item__info">
                    <Icon type="SubPlug" />
                    <span className="sub-options__item__info--rent">
                      {subscriptionLangData[`${subscriptionOption.key}Detail`]}
                    </span>
                    <span className="sub-options__item__info--price">
                      {commonLangData.currency}{' '}
                      {subscriptionOption.addOnPriceInCHF}.-
                    </span>
                  </div>
                )}
                <div className="sub-options__item__info">
                  <Icon type="MaleFemalePlug" />
                  <span className="sub-options__item__info--detail">
                    {subscriptionLangData.electricityConsumptionNotIncluded}
                  </span>
                </div>
                <div className="sub-options__item__info">
                  <Icon type="PiggyBank" />
                  <span className="sub-options__item__info--detail">
                    {subscriptionLangData.getAGrantFotSubscription}
                    <br />
                    <a href={`${faq().path}#subventions`}>
                      {subscriptionLangData.options.checkMyEligibility}
                    </a>
                  </span>
                </div>
                <div className="sub-options__item__offer-detail">
                  <div className="sub-options__item__btn">
                    <button
                      onClick={() =>
                        navigate(subscribe().path, {
                          state: subscriptionOption.key,
                        })
                      }
                      className="btn btn--secondary"
                    >
                      {subscriptionLangData.options.installBtn}
                    </button>
                  </div>
                  <div className="sub-options__item__include">
                    <h5 className="sub-options__item__include__title">
                      {subscriptionLangData.options.includeSub}
                    </h5>
                    <ul className="sub-options__item__include__list">
                      {Object.keys(
                        subscriptionLangData.options.includeSubList
                      ).map((option: string, index: number) => {
                        const optionData =
                          subscriptionLangData.options.includeSubList[option];
                        const isOptionActive =
                          subscriptionOption.options.indexOf(
                            option as SubscriptionOptionIncludeSubList
                          ) > -1;
                        const hasDetailInfo = optionData.detail !== undefined;

                        return (
                          <li
                            key={index}
                            className={`sub-options__item__include__list__item`}
                          >
                            <div
                              className={`sub-options__item__include__list__item--content ${
                                isOptionActive ? 'active' : ''
                              }`}
                            >
                              <div
                                className={`sub-options__item__include__list__item--content__icon`}
                              >
                                {
                                  subscriptionOptionsIcon[
                                    option as SubscriptionOptionIncludeSubList
                                  ]
                                }
                              </div>
                              <div
                                className={`sub-options__item__include__list__item--content__text`}
                              >
                                {isOptionActive
                                  ? optionData.title || optionData
                                  : optionData.titleNotActive}
                              </div>
                            </div>
                            <div
                              className={`sub-options__item__extra ${
                                hasDetailInfo ? 'active' : ''
                              }`}
                            >
                              <img src={questionMarkIcon} alt="" />
                            </div>
                            <div className={`sub-options__item__extra__detail`}>
                              <div
                                className={`sub-options__item__extra__detail__icon`}
                              >
                                <img src={questionMarkIcon} alt="" />
                              </div>
                              <div
                                className={`sub-options__item__extra__detail__text`}
                              >
                                {optionData.detail}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="sub-options__item__condition">
                    <h5 className="sub-options__item__condition--title">
                      {subscriptionLangData.conditions.title}
                    </h5>
                    <div className="sub-options__item__condition--detail">
                      {subscriptionLangData.conditions.detail}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <div className="sub-options__conditions-generales">
          <p>
            <a
              href="https://www.romande-energie.ch/images/files/service-recharge-parking-prive/cg-click-and-charge.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>{subscriptionLangData.conditions_generales}</b>
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};
