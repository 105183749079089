import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContactBloc } from '../../components/dumps/Contact/ContactBloc';

export const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
        <div className="row max-w-standard m-auto">
          <h1 className="text-center text-red-re pt-64 pb-3">
            {t('contactPage.title')}
          </h1>
        </div>
        <div className="row p-4">
          <ContactBloc
            icon="Email"
            iconSize="30"
            iconColor="white"
            email={true}
            question={t('contactPage.emailBloc.question')}
          />
        </div>
        <div className="row p-4">
          <ContactBloc
            icon="Phone"
            iconSize="30"
            iconColor="white"
            email={false}
            question={t('contactPage.phoneBloc.question')}
          />
        </div>
      </div>
    </>
  );
};
