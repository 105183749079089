import React from 'react';

import { useTranslation } from 'react-i18next';

import { Bloc } from '../../dumps/Bloc/Bloc';
import { useMobileChecker } from '../../../hooks/useMobileChecker';
import { DesktopPrices } from '../../dumps/Prices/DesktopPrices';
import { MobilePrices } from '../../dumps/Prices/MobilePrices';
import { RedirectBtn } from '../../dumps/Buttons/RedirectBtn';
import { useLinks } from '../../../hooks/useLinks';

export const OfferSection = () => {
  const { t } = useTranslation();
  const isMobileDisplay = useMobileChecker();
  const links = useLinks();

  return (
    <>
      <section className="bg-white">
        <div className="container">
          <div className="row max-w-standard mx-auto">
            <h2 className="text-center text-red-re pt-64">
              {t('userWithoutParkingPage.offerSection.title1')}
            </h2>
          </div>
        </div>
        <Bloc
          detail={t('userWithoutParkingPage.offerSection.bloc.detail')}
          detail2={t('userWithoutParkingPage.offerSection.bloc.detail2')}
          detail3={t('userWithoutParkingPage.offerSection.bloc.detail3')}
          price={t('userWithoutParkingPage.offerSection.bloc.price')}
          title={t('userWithoutParkingPage.offerSection.bloc.title')}
        />
        {isMobileDisplay ? (
          <MobilePrices hasBtn={false} />
        ) : (
          <DesktopPrices hasBtn={false} />
        )}
      </section>
      <section className="py-5 bg-white">
        <RedirectBtn
          id="offer-redirect-btn"
          link={links.contact().path}
          title={t('userWithoutParkingPage.callToActionSection.btnToForm')}
        />
      </section>
    </>
  );
};
