import React from 'react';

import { BannerSection } from '../../components/views/Owner/BannerSection';
import { DiscoverSection } from '../../components/views/Owner/DiscoverSection';
import { BenefitSection } from '../../components/views/Owner/BenefitSection';
import { OfferSection } from '../../components/views/Owner/OfferSection';
import { StepsSection } from '../../components/views/Owner/StepSection';
import { CallToActionSection } from '../../components/views/Owner/CallToActionSection';
export const Owner = () => {
  return (
    <>
      <BannerSection />
      <DiscoverSection />
      <BenefitSection />
      <CallToActionSection />
      <OfferSection />
      <StepsSection />
    </>
  );
};
