export enum BenefitWithoutKeys {
  FUNDED,
  TURNKEY,
  SIMPLIFY,
}

export type BenefitType = {
  color: string;
  icon: string;
  key: number;
  size: string;
};
