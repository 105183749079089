import React from 'react';

import { Icon } from '../../dumps/Icon/Icon';

type BenefitProps = {
  icon: string;
  title: string;
  detail: string;
  size: string;
  color: string;
};
// Benefit component is used to promote a specific subject in a benefit section
export const Benefit = ({ icon, title, detail, size, color }: BenefitProps) => {
  return (
    <>
      <div className="benefit">
        <div className="benefit__icon">
          <Icon type={icon} size={size} color={color} />
        </div>
        <h3 className="benefit__title">{title}</h3>
        <p className="benefit__detail">{detail}</p>
      </div>
    </>
  );
};
