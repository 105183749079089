import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const StyledSelect = styled(Form.Control)`
  padding: 1.7rem;
  background: none;
  font-size: 1.7rem;
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 2px;
`;

type Options = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type FormSelectProps = {
  name: string;
  options: Options[];
  value?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
};

export const FormSelect = ({
  options,
  className = '',
  name,
  placeholder = '',
  value,
  onChange,
}: FormSelectProps) => {
  useEffect(() => {
    options.unshift({
      value: '',
      label: placeholder,
      disabled: true,
    });
  }, [options, placeholder]);

  return (
    <StyledSelect
      className={`custom-form-control ${className}`}
      as="select"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
    >
      {(options || []).map((option: Options) => (
        <option
          key={option.value}
          disabled={option.disabled || false}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};
