import { useTranslation } from 'react-i18next';

import { LanguageData } from '../../../libraries/sections/models/Common';

import faq_fr from './../../../locales/faq_fr.json';

export type FAQDataType = {
  group: string;
  question: string;
  answer: string;
  link?: string;
  type?: string;
};

export type FAQDataByGroup = {
  [key in string]: FAQDataType[];
};

const getFaqData = (source: string) => {
  switch (source) {
    default:
      return faq_fr;
  }
};

export const useFaq = (sourceFile: string) => {
  const { t } = useTranslation();
  const faqData = getFaqData(sourceFile);

  const faqListByGroup: FAQDataByGroup = (faqData || []).reduce(
    (faqList: FAQDataByGroup, faq: FAQDataType) => {
      const { group = '' } = faq;

      if (!faqList[group] || !faqList[group]) {
        faqList[group] = [faq];
      } else {
        faqList[group].push(faq);
      }

      return faqList;
    },
    {}
  );

  const bannerLangData: LanguageData = t('homePage.banner', {
    returnObjects: true,
  });

  return {
    faqListByGroup,
    bannerLangData,
  };
};
