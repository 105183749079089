import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';
export const DownloadBtn = () => {
  const { t } = useTranslation();
  return (
    <div className="container pt-50">
      <div className="row max-w-standard mx-auto">
        <a
          href="https://www.romande-energie.ch/images/files/service-recharge-parking-prive/fp-click-and-charge.pdf"
          download="C&C_FP.pdf"
          target="_blank"
          rel="noreferrer"
          className="btn btn--secondary mx-auto download-btn"
        >
          <Icon type="Download" size="20" color="#BA0029" />
          {t('common.downloadBtn')}
        </a>
      </div>
    </div>
  );
};
