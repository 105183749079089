import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const QrCodePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      '/contact?utm_source=borne&utm_medium=sticker&utm_campaign=recurring',
      { replace: true }
    );
  }, [navigate]);
  return <></>;
};
