import { useTranslation } from 'react-i18next';

import { LanguageData } from '../../../libraries/sections/models/Common';
import { Icon } from '../../dumps/Icon/Icon';

export const ConnectionCableTutorial = () => {
  const { t } = useTranslation();

  const formTranslation: LanguageData = t('forms.subscribe.formContent', {
    returnObjects: true,
  });

  return (
    <div className="tutorial">
      <Icon type="Bulb" color="#fff" />
      <div>
        <h1 className="tutorial__title">{formTranslation.whichCable}</h1>
        <div className="tutorial__container">
          <span>{formTranslation.cableType1}</span>
          <div className="icon">
            <Icon type="Plug" color="#fff" />
            <Icon type="Plug" color="#fff" />
          </div>
        </div>
        <div className="tutorial__container">
          <span>{formTranslation.cableType2}</span>
          <div className="icon">
            <Icon type="Plug" color="#fff" />
            <Icon type="SecondaryPlug" color="#fff" />
          </div>
        </div>
        <div className="tutorial__container">
          <span>{formTranslation.twisted}</span>
          <div className="icon">
            <Icon type="TwistedCable" color="#fff" />
          </div>
        </div>
        <div className="tutorial__container">
          <span>{formTranslation.standard}</span>
          <div className="icon">
            <Icon type="StraightCable" color="#fff" />
          </div>
        </div>
      </div>
    </div>
  );
};
