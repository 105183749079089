import { Dispatch, SetStateAction } from 'react';

export enum CableType {
  STANDARD22 = 'standard22',
  LONG22 = 'long22',
  TWISTED22 = 'twisted22',
  STANDARD21 = 'standard21',
  EXTRALONG22 = 'extralong22',
  NONE = 'none',
}

export enum SupportType {
  CLASSIC = 'classic',
  PREMIUM = 'premium',
  NONE = 'none',
}
export interface FormikContextProps<T> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: T;
  onSubmitForm: (onSuccess: () => void, onError: () => void) => void;
  setData: Dispatch<SetStateAction<T>>;
}

export interface ParkingInformation {
  npa?: string;
  city?: string;
  street?: string;
  streetNumber?: string;
}

export interface Coordinates {
  lastName?: string;
  firstName?: string;
  mail?: string;
  phoneNumber?: string;
  denomination?: string;
  memberId?: string;
  companyName?: string;
}

export enum Reason {
  ANOTHER_ADDRESS = 'anotherAddress',
  THIRD_PARTY = 'thirdParty',
}

export enum ThirdPartyType {
  SOMEONE = 'someone',
  COMPANY = 'company',
}

export interface InvoicingAddress {
  npa?: string;
  city?: string;
  street?: string;
  streetNumber?: string;
  isUseParkingAddressChecked: boolean;
  reason: Reason;
  thirdPartyType: ThirdPartyType;
  thirdPartyPayment?: Coordinates;
}
export interface SubscriptionOptions {
  connectionCable: CableType;
  supportCable: SupportType;
  activationCard: number;
  wallOutlet: number;
}

export interface Subscription {
  subscriptionType: 'rent' | 'purchase';
  parkingPlaceNumber: string;
  options: SubscriptionOptions;
}

export interface CalendlyInformation {
  url?: string;
  email?: string;
  fullName?: string;
  utmContent?: string;
}

export interface RegistrationData {
  areYou?: 'locataire';
  npa: string;
  city: string;
  street: string;
  streetNumber: string;
  lastName?: string;
  firstName?: string;
  mail?: string;
  phoneNumber?: string;
  parkingPlaceNumber?: string;
}

export interface SubscriptionData {
  parkingInformation: ParkingInformation;
  coordinates: Coordinates;
  invoicingAddress: InvoicingAddress;
  isUseDataCommercialChecked: boolean;
  isConsentChecked: boolean;
  subscriptions: Subscription[];
  equipmentDealId?: number;
  calendlyUrl: string;
  utmContent: string;
}
