import React from 'react';

import { useTranslation } from 'react-i18next';

import { Step } from '../../dumps/Step/Step';
import {
  StepWithKeys,
  StepType,
} from '../../../libraries/sections/models/Steps';

const color = '#5C5C5D';
const stepsList: StepType[] = [
  {
    key: StepWithKeys.SOUSCRIPTION,
    icon: 'Souscription',
    size: '120',
    color: color,
  },
  {
    key: StepWithKeys.PLANIFICATION,
    icon: 'Plan',
    size: '60',
    color: color,
  },
  {
    key: StepWithKeys.RECHARGE,
    icon: 'SubPlug',
    size: '60',
    color: color,
  },
];

export const StepsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-normal pb-64">
      <div className="step-section">
        {stepsList.map((step: StepType, index: number) => (
          <Step
            key={step.key}
            title={t(`userWithParkingPage.stepSection.steps.${step.key}.title`)}
            detail={t(
              `userWithParkingPage.stepSection.steps.${step.key}.detail`
            )}
            icon={step.icon}
            color={step.color}
            size={step.size}
            index={index + 1}
          />
        ))}
      </div>
    </section>
  );
};
