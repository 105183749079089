import React from 'react';

import { Icon } from '../Icon/Icon';
import { InputLabel } from '../FormikField/InputLabel';

type InputWithInfoProps = {
  name: string;
  value: string;
  labelInfo: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  className?: string;
};

export const InputWithInfo = ({
  name,
  value,
  labelInfo,
  onChange,
  onBlur,
  className = '',
}: InputWithInfoProps): JSX.Element => {
  return (
    <div className={className}>
      <input
        value={value}
        name={name}
        required={true}
        onChange={onChange}
        onBlur={onBlur}
        className={`inp ${className}__info`}
        type="number"
        defaultValue={0}
        min={0}
        max={350}
      />
      <Icon type="Info" />
      <InputLabel label={labelInfo} name={name} required={false} />
    </div>
  );
};
