import { createContext } from 'react';

interface StepperContextProps {
  currentStep: number;
  changeStep: (step: number) => void;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

export const StepperContext = createContext<StepperContextProps>({
  currentStep: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeStep: (_: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onNextStep: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onPreviousStep: () => {},
});
