// eslint-disable-next-line
export const debounce = (callback: Function, timmer = 250) => {
  let timeOut: NodeJS.Timeout;

  // eslint-disable-next-line
  return (...args: any) => {
    const waiter = () => {
      timeOut = null as unknown as NodeJS.Timeout;

      callback(...args);
    };

    clearTimeout(timeOut);

    timeOut = setTimeout(waiter, timmer);
  };
};
