export enum ChoiceKeys {
  USER_WITHOUT_PARKING,
  USER_WITH_PARKING,
  OWNER,
}

export type ChoiceType = {
  background: string;
  key: number;
  link: string;
};
