import React from 'react';

import { useTranslation } from 'react-i18next';

export const DiscoverSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-normal">
      <div className="container">
        <div className="row max-w-standard m-auto">
          <h2 className="text-center text-red-re pb-3 pt-64">
            {t('ownerPage.discoverSection.title')}
          </h2>
          <p className="text-left">
            {t('ownerPage.discoverSection.describe1')}
          </p>
          <p className="text-left">
            {t('ownerPage.discoverSection.describe2')}
          </p>
        </div>
      </div>
    </section>
  );
};
