import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';

import { StepperContext } from '../../Stepper';
import { Icon } from '../../dumps/Icon/Icon';

type PreviousStepResumeProps = {
  propertyKey:
    | 'parkingInformation'
    | 'coordinates'
    | 'invoicing'
    | 'validation';
};

export const PreviousStepResume = ({
  propertyKey,
}: PreviousStepResumeProps) => {
  const { onPreviousStep } = useContext(StepperContext);
  const { data } = useContext(FormikContext);
  const { t } = useTranslation();

  const dataKey = {
    parkingInformation: {
      title: t('forms.subscribe.detail.info'),
      data: [
        `${data.parkingInformation.street} ${data.parkingInformation.streetNumber}`,
        `${data.parkingInformation.npa} ${data.parkingInformation.city}`,
      ],
    },
    coordinates: {
      title: t('forms.subscribe.detail.contact'),
      data: [
        ` ${data.coordinates.denomination} ${data.coordinates.firstName} ${data.coordinates.lastName}`,
        data.coordinates.mail,
        data.coordinates.phoneNumber,
      ],
    },
    invoicing: {
      title: t('forms.subscribe.detail.subscription'),
      data: [
        ...data.subscriptions.map(
          (sub) =>
            `Place ${sub.parkingPlaceNumber} ${
              sub.options.activationCard > 1 ||
              sub.options.wallOutlet > 0 ||
              sub.options.supportCable !== 'none' ||
              sub.options.connectionCable !== 'none'
                ? '+ options'
                : 'sans options'
            }`
        ),
      ],
    },
    validation: {
      title: t('forms.subscribe.detail.invoicing'),
      data: [
        `${data.invoicingAddress.street} ${data.invoicingAddress.streetNumber}`,
        `${data.invoicingAddress.npa} ${data.invoicingAddress.city}`,
      ],
    },
  };

  return (
    <div className="previous-step-container">
      <div className="previous-step-content">
        <span className="previous-step-details-title">
          {dataKey[propertyKey].title}
        </span>
        <div className="previous-step-details">
          {dataKey[propertyKey].data.map(
            (data: string[] | (string | undefined)) => (
              <span>{data}</span>
            )
          )}
        </div>
        <button
          type="submit"
          className="actions actions__edit"
          onClick={onPreviousStep}
        >
          <span className="action-label">
            {t('forms.subscribe.button.modify')}
          </span>
          <Icon type="Pencil" />
        </button>
      </div>
      <div className="previous-step-details__mobile">
        {dataKey[propertyKey].data.map(
          (data: string[] | (string | undefined)) => (
            <span>{data}</span>
          )
        )}
      </div>
    </div>
  );
};
