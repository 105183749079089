import React, { memo, useEffect } from 'react';
import { InlineWidget } from 'react-calendly';

type InlineCalendlyProps = {
  url: string;
  email: string;
  fullName: string;
  utmContent?: string;
  onChangeButtonDisplay: () => void;
  onFormLoaded?: () => void;
};

const areCalendarPropsEqual = (
  prevCalendar: InlineCalendlyProps,
  nextCalendar: InlineCalendlyProps
) => {
  return (
    prevCalendar.url === nextCalendar.url &&
    prevCalendar.email === nextCalendar.email &&
    prevCalendar.fullName === nextCalendar.fullName
  );
};

export const InlineCalendly = memo(
  ({
    url,
    email,
    fullName,
    utmContent,
    onChangeButtonDisplay,
    onFormLoaded,
  }: InlineCalendlyProps) => {
    useEffect(() => {
      const handleCalendlyEvent = (e: MessageEvent) => {
        if (e.data.event && e.data.event.indexOf('calendly') !== 0) {
          return;
        }
        if (e.data.event === 'calendly.event_type_viewed' && onFormLoaded) {
          onFormLoaded();
        }
        if (e.data.event === 'calendly.event_scheduled') {
          onChangeButtonDisplay();
        }
      };

      window.addEventListener('message', handleCalendlyEvent);
      return () => {
        window.removeEventListener('message', handleCalendlyEvent);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <InlineWidget
        url={`${url}?primary_color=BA0029`}
        prefill={{ email: email, name: fullName }}
        utm={{ utmContent: utmContent }}
      />
    );
  },
  areCalendarPropsEqual
);
