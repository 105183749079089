import React from 'react';
import { Trans } from 'react-i18next';

import { Icon } from '../../dumps/Icon/Icon';

type StepProps = {
  color: string;
  detail: string;
  icon: string;
  index: number;
  size: string;
  title: string;
};

// Step component is used in a step section to display the specific step information
export const Step = ({
  color,
  detail,
  icon,
  index,
  size,
  title,
}: StepProps) => {
  return (
    <div className="step">
      <div className="step__content">
        <div className="step__content__icon">
          <span style={{ color: color }}>{index}.</span>
          <Icon type={icon} size={size} color={color} />
        </div>
        <h2 className="step__content__title">{title}</h2>
        <p className="step__content__detail">
          <Trans i18nKey={detail} />
        </p>
      </div>
      <div className="step__chevron"></div>
    </div>
  );
};
