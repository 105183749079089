import React from 'react';

import { ActionMeta, OnChangeValue } from 'react-select';

type InputBaseProps<T = void> = {
  inputElement: 'input' | 'select' | ((props: unknown) => JSX.Element);
  name: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onChange: (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
      | OnChangeValue<T, boolean>,
    action?: ActionMeta<T>
  ) => void;
  required: boolean;
  label?: string;
  className?: string;
  classNamePrefix?: string;
};

export const InputBase = <T,>({
  inputElement: InputElement,
  ...inputProps
}: InputBaseProps<T>) => {
  return <InputElement {...inputProps} />;
};
