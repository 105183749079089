export enum StepWithoutKeys {
  CHOICE,
  INSTALLATION,
  SOUSCRIPTION,
}

export enum StepWithKeys {
  SOUSCRIPTION,
  PLANIFICATION,
  RECHARGE,
}

export enum StepOwnerKeys {
  ANALYSE,
  CONTRACT,
  PREEQUIPE,
}

export type StepType = {
  color: string;
  icon: string;
  key: number;
  size: string;
};
