export type AppLinks = ReturnType<typeof getAppLinks>;

// hide links inside a factory to prevent global imports
// eslint-disable-next-line
export const getAppLinks = () => ({
  equipParking: () => {
    return {
      path: '/equiper-parking',
      displayInMenu: false,
    };
  },
  home: () => {
    return { path: '/', displayInMenu: true };
  },
  privateDropDown: () => {
    return { path: '#', displayInMenu: true };
  },
  userWithoutParking: () => {
    return {
      path: '/parking-nonequipe',
      displayInMenu: false,
    };
  },
  userWithParking: () => {
    return {
      path: '/parking-equipe',
      displayInMenu: false,
    };
  },
  owner: () => {
    return {
      path: '/acteur-immobilier',
      displayInMenu: true,
    };
  },
  faq: () => {
    return { path: '/faq', displayInMenu: true };
  },
  contact: () => {
    return { path: '/contact', displayInMenu: true };
  },
  subscribe: () => {
    return {
      path: '/abonnements/souscrire',
      displayInMenu: false,
    };
  },
  subscriptions: () => {
    return { path: '/abonnements', displayInMenu: false };
  },
  qrCode: () => {
    return {
      path: '/qrcode_borne',
      displayInMenu: false,
    };
  },
});
