import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';
import { debounce } from '../../../libraries/helpers/debounce';

import BulbIcon from './../../../assets/images/bulb.svg';

export const DoYouKnow = () => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const handleScrollEvent = debounce(() => {
    const yPosition = window.scrollY;

    if (yPosition >= 10) {
      setIsExpanded(false);
    } else if (yPosition < 10) {
      setIsExpanded(true);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScrollEvent]);

  return (
    <div
      data-test="doyouknow-section"
      className={`home-banner__question fixed-block ${
        isExpanded ? '' : 'collapsed'
      }`}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="home-banner__question__icon">
        <img src={BulbIcon} alt={t('common.doYouKnow.title')} />
      </div>
      <div className="home-banner__question__content">
        <h2
          className="home-banner__question__title"
          data-test="doyouknow-button"
        >
          <span>
            {isExpanded
              ? t('common.doYouKnow.title_max')
              : t('common.doYouKnow.title_min')}
          </span>
          <button
            data-test="doyouknow-close-button"
            onClick={() => setIsExpanded(false)}
          >
            <Icon type="Close" size="20" color="#FFF" />
          </button>
        </h2>
        <p data-test="doyouknow-text" className="home-banner__question__detail">
          {t('common.doYouKnow.detail')}
        </p>
      </div>
    </div>
  );
};
