import { useTranslation } from 'react-i18next';

type FormatPricePropTypes = {
  price: number;
};

export const FormatPrice = ({ price }: FormatPricePropTypes) => {
  const { t } = useTranslation();
  return (
    <>
      {`${t('forms.subscribe.formContent.chf')} ${price}${
        Number.isInteger(price) ? '.-' : ''
      }`}
    </>
  );
};
