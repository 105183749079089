import React, { useCallback, useEffect, useReducer } from 'react';

import { AxiosResponse } from 'axios';

import { FormikErrors, FormikTouched } from 'formik';

import { useTranslation } from 'react-i18next';

import { Options } from 'react-select';

import { LanguageData } from '../../../libraries/sections/models/Common';

import { api } from '../../../Api';

import {
  FormDataKey,
  ParkingAddressCheck,
} from '../../../libraries/sections/models/Subscriptions';

import { ParkingInformation } from '../../Formik/FormikContext';

import { useError } from '../../../hooks/useError';

import { FormAddress, FormAddressType } from './FormAddress';

type ParkingInformationFormAddressProps = {
  subscribeFormLangData: LanguageData;
  values: ParkingInformation;
  errors: FormikErrors<{
    npa?: string;
    city?: string;
    street?: string;
    streetNumber?: string;
  }>;
  touched: FormikTouched<{
    npa?: boolean;
    city?: boolean;
    street?: boolean;
    streetNumber?: boolean;
  }>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  setValues: (
    values: React.SetStateAction<ParkingInformation>,
    shouldValidate?: boolean
  ) => void;
  setDisplayCheckANewAddress: (val: boolean) => void;
};

type ReducerStateProps = {
  cities: Options<{ label: string; value: string }>;
  streets: Options<{ label: string; value: string }>;
  streetNumbers: Options<{ label: string; value: string }>;
  city: string;
  street: string;
  isFetchingCity: boolean;
  customParkingAddress: ParkingAddressCheck[];
};

type ActionProps = {
  type: string;
  payload: Partial<ReducerStateProps>;
};

enum DispatchReducer {
  UPDATE_CITIES = 'UPDATE_CITIES',
  UPDATE_STREETS = 'UPDATE_STREETS',
  UPDATE_STREET_NUMBERS = 'UPDATE_STREET_NUMBERS',
  UPDATE_CITY = 'UPDATE_CITY',
  UPDATE_STREET = 'UPDATE_STREET',
  UPDATE_FETCHING_CITY = 'UPDATE_FETCHING_CITY',
  UPDATE_CM_PARKING_ADDRESS = 'UPDATE_CM_PARKING_ADDRESS',
}

const reducer = (state: Partial<ReducerStateProps>, action: ActionProps) => {
  switch (action.type) {
    case DispatchReducer.UPDATE_CITIES:
      return {
        ...state,
        cities: action.payload.cities,
      };
    case DispatchReducer.UPDATE_STREETS:
      return {
        ...state,
        streets: action.payload.streets,
      };
    case DispatchReducer.UPDATE_STREET_NUMBERS:
      return {
        ...state,
        streetNumbers: action.payload.streetNumbers,
      };
    case DispatchReducer.UPDATE_CITY:
      return {
        ...state,
        city: action.payload.city,
      };
    case DispatchReducer.UPDATE_STREET:
      return {
        ...state,
        street: action.payload.street,
      };
    case DispatchReducer.UPDATE_FETCHING_CITY:
      return {
        ...state,
        isFetchingCity: action.payload.isFetchingCity,
      };
    case DispatchReducer.UPDATE_CM_PARKING_ADDRESS:
      return {
        ...state,
        customParkingAddress: action.payload.customParkingAddress,
      };
    default:
      return state;
  }
};

const initialState = {
  cities: [],
  streets: [],
  streetNumbers: [],
  city: '',
  street: '',
  isFetchingCity: false,
  customParkingAddress: [],
};

/**
 * If the city does not have pre equipped parking
 */
const CITY_NOT_AVAILABLE = 'CITY_NOT_AVAILABLE';

/**
 * If the customer did not found his city
 */
const CITY_NOT_FOUND = 'CITY_NOT_FOUND';

/**
 * If the customer did not found his street
 */
const ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND';

/**
 * If the customer did not found his street number
 */
const NUMBER_NOT_FOUND = 'NUMBER_NOT_FOUND';

export const ParkingInformationFormAddress = ({
  subscribeFormLangData,
  values,
  setValues,
  setDisplayCheckANewAddress,
}: ParkingInformationFormAddressProps) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setErr } = useError();

  const removeDuplicatesFilter = (
    val: string,
    index: number,
    self: string[]
  ) => {
    return self.indexOf(val) === index;
  };

  const onBlurNpa = useCallback(
    (npa?: string) => {
      if (npa && parseInt(npa) >= 1000) {
        dispatch({
          type: DispatchReducer.UPDATE_FETCHING_CITY,
          payload: {
            isFetchingCity: true,
          },
        });
        api
          .get(`api/web/parking-lot/addresses?postcode=${npa}`)
          .then(function (response: AxiosResponse) {
            if (response && response.data) {
              dispatch({
                type: DispatchReducer.UPDATE_CM_PARKING_ADDRESS,
                payload: {
                  customParkingAddress: response.data,
                },
              });
            } else {
              setErr(t('errorMessage.unexpectedError'));
            }
          })
          .catch((error) => {
            setErr(error);
          })
          .finally(() => {
            dispatch({
              type: DispatchReducer.UPDATE_FETCHING_CITY,
              payload: {
                isFetchingCity: false,
              },
            });
          });
      } else {
        dispatch({
          type: DispatchReducer.UPDATE_CM_PARKING_ADDRESS,
          payload: {
            customParkingAddress: [],
          },
        });
      }
    },
    [setErr, t]
  );

  useEffect(() => {
    // Set the cities found
    const cmParkings: string[] = (
      state.customParkingAddress ? state.customParkingAddress : []
    )
      .map((add) => add[FormDataKey.city])
      .filter(removeDuplicatesFilter)
      .sort((cityA, cityB) => cityA.localeCompare(cityB));
    cmParkings.push(
      cmParkings.length === 0 ? CITY_NOT_AVAILABLE : CITY_NOT_FOUND
    );
    dispatch({
      type: DispatchReducer.UPDATE_CITIES,
      payload: {
        cities: cmParkings.map((val) => {
          if (val === CITY_NOT_FOUND) {
            return {
              value: FormDataKey.city,
              label: subscribeFormLangData.formContent.cityNotFound,
              data: { defaultOption: true },
            };
          } else if (val === CITY_NOT_AVAILABLE) {
            return {
              value: FormDataKey.city,
              label: subscribeFormLangData.formContent.cityNotAvailable,
              data: { defaultOption: true },
            };
          }
          return { value: FormDataKey.city, label: val, data: null };
        }),
      },
    });

    // Set the streets found
    const filteredByCity: string[] = (
      state.customParkingAddress ? state.customParkingAddress : []
    )
      .filter((cpAddress) => cpAddress[FormDataKey.city] === state.city)
      .map((val) => val[FormDataKey.street])
      .filter(removeDuplicatesFilter)
      .sort((streetA, streetB) => streetA.localeCompare(streetB));
    filteredByCity.push(ADDRESS_NOT_FOUND);
    dispatch({
      type: DispatchReducer.UPDATE_STREETS,
      payload: {
        streets: filteredByCity.map((filter) => {
          if (filter === ADDRESS_NOT_FOUND) {
            return {
              value: FormDataKey.street,
              label: subscribeFormLangData.formContent.addressNotFound,
              data: { defaultOption: true },
            };
          }
          return {
            value: FormDataKey.street,
            label: filter,
            data: null,
          };
        }),
      },
    });

    // Set the streetNumbers found
    const filteredByStreet: string[] = (
      state.customParkingAddress ? state.customParkingAddress : []
    )
      .filter((cpAddress) => cpAddress[FormDataKey.street] === state.street)
      .map((val) => val[FormDataKey.streetNumber])
      .filter(removeDuplicatesFilter)
      .sort((streetNumberA, streetNumberB) =>
        streetNumberA.localeCompare(streetNumberB, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );
    filteredByStreet.push(NUMBER_NOT_FOUND);
    dispatch({
      type: DispatchReducer.UPDATE_STREET_NUMBERS,
      payload: {
        streetNumbers: filteredByStreet.map((filter) => {
          if (filter === NUMBER_NOT_FOUND) {
            return {
              value: FormDataKey.streetNumber,
              label: subscribeFormLangData.formContent.numberNotFound,
              data: { defaultOption: true },
            };
          }
          return {
            value: FormDataKey.streetNumber,
            label: filter,
            data: null,
          };
        }),
      },
    });
  }, [
    state.customParkingAddress,
    state.city,
    state.street,
    subscribeFormLangData.formContent.numberNotFound,
    subscribeFormLangData.formContent.cityNotFound,
    subscribeFormLangData.formContent.cityNotAvailable,
    subscribeFormLangData.formContent.addressNotFound,
  ]);

  // TODO Optimize this part
  const updateFieldsValue = (
    field: string,
    value: string,
    defaultOption = false
  ) => {
    const dataToUpdate: { [key: string]: string | number } = {};
    if (field === FormDataKey.npa) {
      dataToUpdate[field] = value;
      dataToUpdate[FormDataKey.city] = '';
      dataToUpdate[FormDataKey.street] = '';
      dataToUpdate[FormDataKey.streetNumber] = '';
    } else if (field === FormDataKey.city) {
      dataToUpdate[FormDataKey.city] = value;
      dataToUpdate[FormDataKey.street] = '';
      dataToUpdate[FormDataKey.streetNumber] = '';
      dispatch({
        type: DispatchReducer.UPDATE_CITY,
        payload: {
          city: value,
        },
      });
    } else if (field === FormDataKey.street) {
      dataToUpdate[FormDataKey.street] = value;
      dataToUpdate[FormDataKey.streetNumber] = '';
      dispatch({
        type: DispatchReducer.UPDATE_STREET,
        payload: {
          street: value,
        },
      });
    } else {
      dataToUpdate[FormDataKey.streetNumber] = value;
    }
    setDisplayCheckANewAddress(defaultOption);
    setValues({ ...values, ...dataToUpdate });
  };

  return (
    <FormAddress<FormAddressType>
      componentType="parkingInfo"
      setFieldValue={updateFieldsValue}
      subscribeFormLangData={subscribeFormLangData}
      values={values}
      cities={state.cities}
      streets={state.streets}
      streetNumbers={state.streetNumbers}
      onBlurNpa={() => {
        onBlurNpa(values.npa);
      }}
      isCityLoading={state.isFetchingCity}
    />
  );
};
