import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { LanguageData } from '../../../libraries/sections/models/Common';
import {
  SubscriptionAdvantage,
  SubscriptionAdvantageDataType,
  SubscriptionOptionDataType,
  SubscriptionOptionIncludeSubList,
  SubscriptionType,
} from '../../../libraries/sections/models/Subscriptions';

import { ReactComponent as SubConfigIcon } from './../../../assets/images/sub-config.svg';
import { ReactComponent as SubPlugIcon } from './../../../assets/images/sub-plug.svg';
import { ReactComponent as SubRemoteIcon } from './../../../assets/images/sub-remoteControl.svg';
import { ReactComponent as SubIntelligentIcon } from './../../../assets/images/sub-intelligent.svg';
import { ReactComponent as SubBillingIcon } from './../../../assets/images/sub-billing.svg';
import { ReactComponent as SubAllOfDayIcon } from './../../../assets/images/sub-allOfDay.svg';
import { ReactComponent as SubRenewableIcon } from './../../../assets/images/sub-renewable.svg';
import { ReactComponent as SubBreakDownCostIcon } from './../../../assets/images/sub-breakdownCosts.svg';
import QuestionMarkIcon from './../../../assets/images/question-mark.svg';

export const questionMarkIcon = QuestionMarkIcon;

export const subscriptionOptionsIcon = {
  [SubscriptionOptionIncludeSubList.CONFIG]: <SubConfigIcon />,
  [SubscriptionOptionIncludeSubList.PLUG]: <SubPlugIcon />,
  [SubscriptionOptionIncludeSubList.REMOTECONTROL]: <SubRemoteIcon />,
  [SubscriptionOptionIncludeSubList.INTELLIGENT]: <SubIntelligentIcon />,
  [SubscriptionOptionIncludeSubList.BILLING]: <SubBillingIcon />,
  [SubscriptionOptionIncludeSubList.ALLOFDAY]: <SubAllOfDayIcon />,
  [SubscriptionOptionIncludeSubList.RENEWABLE]: <SubRenewableIcon />,
  [SubscriptionOptionIncludeSubList.BREAKDOWNCOST]: <SubBreakDownCostIcon />,
};

export const subscriptionOptionsData: SubscriptionOptionDataType[] = [
  {
    key: SubscriptionType.RENT,
    priceInCHF: 52,
    options: [
      SubscriptionOptionIncludeSubList.CONFIG,
      SubscriptionOptionIncludeSubList.PLUG,
      SubscriptionOptionIncludeSubList.REMOTECONTROL,
      SubscriptionOptionIncludeSubList.INTELLIGENT,
      SubscriptionOptionIncludeSubList.BILLING,
      SubscriptionOptionIncludeSubList.ALLOFDAY,
      SubscriptionOptionIncludeSubList.RENEWABLE,
      SubscriptionOptionIncludeSubList.BREAKDOWNCOST,
    ],
  },
  {
    key: SubscriptionType.PURCHASE,
    priceInCHF: 21,
    addOnPriceInCHF: 2120,
    options: [
      SubscriptionOptionIncludeSubList.CONFIG,
      SubscriptionOptionIncludeSubList.PLUG,
      SubscriptionOptionIncludeSubList.REMOTECONTROL,
      SubscriptionOptionIncludeSubList.INTELLIGENT,
      SubscriptionOptionIncludeSubList.BILLING,
      SubscriptionOptionIncludeSubList.ALLOFDAY,
      SubscriptionOptionIncludeSubList.RENEWABLE,
    ],
  },
];

export const subscriptionAdvantagesData: SubscriptionAdvantageDataType[] = [
  {
    key: SubscriptionAdvantage.WITH_OR_WITHOUT_PURCHASE,
    icon: 'PiggyBank',
    size: '125',
    color: '#000',
  },
  {
    key: SubscriptionAdvantage.FULLTANK,
    icon: 'Battery',
    size: '125',
    color: '#000',
  },
  {
    key: SubscriptionAdvantage.FINALLY,
    icon: 'ElectricCar',
    size: '125',
    color: '#000',
  },
];

export const useSubscription = () => {
  const { t } = useTranslation();
  const [isModalShow, setIsModalShow] = useState(false);
  const [subscriptionOptionKey, setSubscriptionOptionKey] =
    useState<SubscriptionType>(SubscriptionType.RENT);

  const commonLangData: LanguageData = t('common', { returnObjects: true });
  const subscriptionLangData: LanguageData = t('subscriptionPage', {
    returnObjects: true,
  });

  const closeModal = () => setIsModalShow(false);

  const openModal = (subOptionKey: SubscriptionType) => {
    setSubscriptionOptionKey(subOptionKey);
    setIsModalShow(true);
  };

  return {
    l: {
      subscriptionLangData,
      commonLangData,
    },
    closeModal,
    isModalShow,
    openModal,
    subscriptionOptionKey,
  };
};
