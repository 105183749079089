import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

type ChoiceProps = {
  background: string;
  button: string;
  dataTest: string;
  link: string;
  title: string;
};

export const Choice = ({
  background,
  button,
  dataTest,
  link,
  title,
}: ChoiceProps) => {
  return (
    <div
      className="choice_content"
      style={{
        background: `url(${background})`,
      }}
    >
      <div className="choice_content__overlay">
        <div className="choice_content__section">
          <h3 className="choice_content__section-title">
            <Trans i18nKey={title} />
          </h3>
          <Link id={dataTest} data-test={dataTest} to={link}>
            <div className="choice_content__section-button text-center">
              {button}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
