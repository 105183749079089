import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';

type FormCheckboxProps = {
  name: string;
  value?: boolean;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | ReactNode;
};

export const FormCheckbox = ({
  name,
  value,
  className = '',
  label = '',
  ...restProps
}: FormCheckboxProps) => {
  return (
    <>
      <Form.Check className={`custom-form-control check-box ${className}`}>
        <Form.Check.Input
          id={name}
          checked={value}
          type="checkbox"
          name={name}
          {...restProps}
        />
        <Form.Check.Label htmlFor={name}>{label}</Form.Check.Label>
      </Form.Check>
    </>
  );
};
