import React from 'react';

import { SubscriptionAdvantageDataType } from '../../../libraries/sections/models/Subscriptions';
import { Icon } from '../../dumps/Icon/Icon';

import { subscriptionAdvantagesData, useSubscription } from './useSubscription';

import './sub-advantage.scss';

export const Advantage = () => {
  const {
    l: { subscriptionLangData },
  } = useSubscription();
  return (
    <section className="sub-advantage container">
      <h3 className="sub-advantage__title cl-secondary">
        {subscriptionLangData.yourAdvantage.title}
      </h3>
      <div className="sub-advantage__list">
        {subscriptionAdvantagesData.map(
          (subscriptionAdvantage: SubscriptionAdvantageDataType) => (
            <div
              key={subscriptionAdvantage.key}
              className="sub-advantage__list__item"
            >
              <div className="sub-advantage__list__item__icon">
                <Icon
                  type={subscriptionAdvantage.icon}
                  size={subscriptionAdvantage.size}
                  color={subscriptionAdvantage.color}
                />
              </div>
              <div className="sub-advantage__list__item__content">
                <h3>
                  {
                    subscriptionLangData.yourAdvantage.advantages[
                      subscriptionAdvantage.key
                    ].title
                  }
                </h3>
                <p>
                  {
                    subscriptionLangData.yourAdvantage.advantages[
                      subscriptionAdvantage.key
                    ].detail
                  }
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </section>
  );
};
