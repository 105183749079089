import React from 'react';

import { BannerSection } from '../../components/views/FAQPage/BannerSection';

import { FAQList } from '../../components/views/FAQPage/FAQList';

export const FAQPage = () => {
  return (
    <>
      <BannerSection />
      <FAQList />
    </>
  );
};
