import React, { CSSProperties, ReactNode } from 'react';

type InputLabelProps = {
  label?: string | ReactNode;
  name?: string;
  required: boolean;
  style?: CSSProperties;
  className?: string;
};

export const InputLabel = ({
  label,
  name,
  required,
  style,
  className = '',
}: InputLabelProps) => {
  if (label) {
    if (typeof label === 'object') {
      return (
        <div style={style} className={className}>
          {label}
        </div>
      );
    }
    return (
      <div style={style} className={`common-input__label ${className}`}>
        <label htmlFor={name}>
          {label}
          {required && <span className="cl-secondary">*</span>}
        </label>
      </div>
    );
  }
  return null;
};
