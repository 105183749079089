import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon/Icon';

import { SubscriptionOptionKeys } from '../../../libraries/sections/models/Subscriptions';

import { DesktopRow } from './DesktopRow';
import { SubscribeBtn } from './SubscribeBtn';
type DesktopPricesProps = {
  hasBtn: boolean;
};

interface IRow {
  title: string;
  notRent?: boolean;
  notPurchase?: boolean;
  tooltip?: boolean;
}

export const DesktopPrices = ({ hasBtn }: DesktopPricesProps) => {
  const { t } = useTranslation();
  const rowData: IRow[] = [
    {
      title: 'item1',
    },
    {
      title: 'item2',
    },
    {
      title: 'item3',
    },
    {
      title: 'item4',
    },
    {
      title: 'item5',
    },
    {
      title: 'item6',
      tooltip: true,
    },
    {
      title: 'item7',
      tooltip: true,
      notPurchase: true,
    },
  ];

  return (
    <>
      <div className="container pb-4 max-w-standard">
        <div className="row m-auto text-red-re">
          <div className="col-6">
            <h3 className="text-lg">
              {t('userWithParkingPage.pricesSection.header.title')}
            </h3>
          </div>
          <div className="col-3">
            <h3 className="text-lg text-center fw-bold">
              {t('userWithParkingPage.pricesSection.header.titleCol1')}
            </h3>
          </div>
          <div className="col-3">
            <h3 className="text-lg text-center fw-bold">
              {t('userWithParkingPage.pricesSection.header.titleCol2')}
            </h3>
          </div>
        </div>
        <div className="mt-3">
          <div className="row m-auto bg-normal p-2 vertical-align">
            <div className="col-6">
              <h3 className="text-lg">
                {t('userWithParkingPage.pricesSection.bornePrice.title')}
              </h3>
              <p className="mb-0 text-xs">
                {t('userWithParkingPage.pricesSection.bornePrice.describe')}
              </p>
            </div>
            <div className="col-3 text-center">
              <h3>
                {t('userWithParkingPage.pricesSection.bornePrice.titleCol1')}
                <p className="mb-0 text-xxs">
                  {t('userWithParkingPage.pricesSection.bornePrice.descCol1')}
                </p>
              </h3>
            </div>
            <div className="col-3 text-center">
              <h3>
                {t('userWithParkingPage.pricesSection.bornePrice.titleCol2')}
              </h3>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <div className="row m-auto bg-normal p-2 vertical-align">
            <div className="col-6">
              <h3 className="text-lg">
                {t('userWithParkingPage.pricesSection.service.title')}
              </h3>
            </div>
            <div className="col-3 text-center">
              <h3 className="text-md">
                {t('userWithParkingPage.pricesSection.service.titleCol1')}
              </h3>
            </div>
            <div className="col-3 text-center">
              <h3 className="text-md">
                {t('userWithParkingPage.pricesSection.service.titleCol2')}
              </h3>
            </div>
          </div>
          {rowData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <DesktopRow
                title={row.title}
                notRent={row.notRent}
                notPurchase={row.notPurchase}
                tooltip={row.tooltip}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="row m-auto bg-normal p-2 vertical-align mt-2">
          <div className="col-6 align-self-center">
            <p className="mb-0">
              {t('userWithParkingPage.pricesSection.activation.title')}
            </p>
          </div>
          <div className="col-3 text-center">
            <p className="mb-0">
              {t('userWithParkingPage.pricesSection.activation.rentPrice')}
            </p>
          </div>
          <div className="col-3 text-center">
            <p className="mb-0">
              {t('userWithParkingPage.pricesSection.activation.rentPrice')}
            </p>
          </div>
        </div>
        <div className="row m-auto mt-2 bg-normal p-2 vertical-align">
          <div className="col align-self-center">
            <div className="row">
              <div className="col-1 align-self-center">
                <Icon type="MaleFemalePlug" size="20" color="#a10023" />
              </div>
              <div className="col-11 align-self-center">
                <p className="mb-0 pl-3">
                  {t('userWithParkingPage.pricesSection.bonus')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          *CHF 15.-/mois pour les parkings signés avant le 31.03.24.
        </div>
        {hasBtn && (
          <div className="row m-auto mt-2 p-2 vertical-align">
            <div className="col align-self-center">
              <div className="row">
                <div className="col-6"></div>
                <div className="col-3">
                  <SubscribeBtn
                    typeOfSubscription={SubscriptionOptionKeys.RENT}
                    title={t('userWithParkingPage.pricesSection.btnRent')}
                    id="rent-btn"
                  />
                </div>
                <div className="col-3 align-self-center">
                  <SubscribeBtn
                    typeOfSubscription={SubscriptionOptionKeys.PURCHASE}
                    title={t('userWithParkingPage.pricesSection.btnOwn')}
                    id="purchase-btn"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
