/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
        <div className="row g-5">
          <div className="col-12 col-md-8">
            <div className="col-6">
              <div className="footer__title">{t('common.footer.corp')}</div>
              <div className="d-flex">
                <a
                  className="ico ico--fb"
                  href="https://fr-fr.facebook.com/RomandeEnergie"
                  rel="noopener noreferrer"
                  target="_blank"
                />
                <a
                  className="ico ico--tw"
                  href="https://twitter.com/RomandeEnergie"
                  rel="noopener noreferrer"
                  target="_blank"
                />
                <a
                  className="ico ico--in"
                  href="https://www.linkedin.com/company/romande-energie"
                  rel="noopener noreferrer"
                  target="_blank"
                />
                <a
                  className="ico ico--yb"
                  href="https://www.youtube.com/user/RomandeEnergieSA"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="footer__title">
              {t('common.footer.contact.title')}
            </div>
            <div className="footer__contact">
              <p>{t('common.footer.contact.name')}</p>
              <p>{t('common.footer.contact.address')}</p>
            </div>
            <div className="footer__contact">
              <p>
                <b>{t('common.footer.contact.email')}</b>
                <a
                  href={
                    'mailto:' +
                    t('common.footer.contact.email_value') +
                    '?subject=Contact - Click%26Charge'
                  }
                >
                  {t('common.footer.contact.email_value')}
                </a>
              </p>
              <p>
                <b>{t('common.footer.contact.phone')}</b>
                {t('common.footer.contact.phone_value')}
              </p>
            </div>
            <div className="footer__contact">
              <p>
                {t('common.footer.contact.copyright')} |
                <a
                  href="https://www.romande-energie.ch/conditions-internet"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <b>{t('common.footer.contact.protect')} |</b>
                </a>
                <a
                  href="https://www.romande-energie.ch/images/files/service-recharge-parking-prive/cg-click-and-charge.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <b>{t('common.footer.contact.conditions_generales')}</b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
