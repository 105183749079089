export interface StepProps {
  id: number;
  title: string;
  children: React.ReactChild;
  icon: JSX.Element;
}

export const Step = function ({ children }: StepProps) {
  return <>{children}</>;
};
