import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { Trans, useTranslation } from 'react-i18next';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';
import { StepperContext } from '../../Stepper';
import { InlineCalendly } from '../Calendly/InlineCalendly';

export const AppointmentForm = () => {
  const { t } = useTranslation();
  const [isCalendlyLoaded, setIsCalendlyLoaded] = useState(false);
  const [isAppointmentDone, setIsAppointmentDone] = useState(false);
  const {
    data: { coordinates, calendlyUrl, utmContent },
  } = useContext(FormikContext);
  const { onNextStep } = useContext(StepperContext);

  useEffect(() => {
    // Google Tag Manager step form tracker
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        content_type: 'step6-rdv',
        item_id: 'form-subscription',
      },
    });
  }, []);

  return (
    <div
      className={`page-form__calendly ${
        isCalendlyLoaded ? 'loaded' : ''
      } page-form__calendly__info`}
    >
      <div>
        <Trans i18nKey="forms.subscribe.formContent.appointment.info">
          Nous avons maintenant toutes les infos nécessaires à votre
          souscription.
          <br />
          <p style={{ color: '#ba0029' }}>
            Il ne vous reste plus qu'à prendre RDV pour installer votre borne.
          </p>
        </Trans>
      </div>
      <InlineCalendly
        email={coordinates.mail ? coordinates.mail : ''}
        url={calendlyUrl}
        fullName={`${coordinates.firstName} ${coordinates.lastName}`}
        utmContent={utmContent}
        onChangeButtonDisplay={() => setIsAppointmentDone(true)}
        onFormLoaded={() => setIsCalendlyLoaded(true)}
      />
      <button
        type="button"
        disabled={!isAppointmentDone}
        onClick={() => onNextStep()}
        className="btn btn--red"
      >
        {t('forms.subscribe.formContent.next')}
      </button>
    </div>
  );
};
