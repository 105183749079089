import React from 'react';
import { useTranslation } from 'react-i18next';

import { Advantage } from '../../components/views/SubscriptionPage/Advantage';
import { Options } from '../../components/views/SubscriptionPage/Options';

import SubscriptionBackground from './../../assets/images/subpage-banner.jpg';

export const Subscriptions = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="child-page__banner sub-page__banner"
        style={{ backgroundImage: `url(${SubscriptionBackground})` }}
      >
        <div className="container">
          <h1>{t('subscriptionPage.title')}</h1>
        </div>
      </div>
      <Options />
      <Advantage />
    </>
  );
};
