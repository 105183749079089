import React, { useState } from 'react';

import { useError } from '../../hooks/useError';

import { StepProps } from './Step';
import { StepperContext } from './StepperContext';
import { StepperProgressBar } from './StepperProgressBar';

interface StepperProps {
  children: React.ReactElement<StepProps>[];
  withArianneThread?: boolean;
  finalStep?: JSX.Element;
  initialStep: boolean;
  title: string;
}

/**

 * HOW TO IMPLEMENT
 * <Stepper> 
 *  <Step id={n} title="title" icon={icon}>
 *    <YourComponent>
 *  </Step>
 *  [...repeat the step]
 * </Stepper>

 * HOW TO NAVIGATE
 * use StepperContext
 */

export const Stepper = ({
  children,
  withArianneThread = true,
  finalStep,
  initialStep = false,
  title,
}: StepperProps) => {
  const { clearErr } = useError();
  const [currentStep, setCurrentStep] = useState<number>(1);

  const changeStep = (step: number) => {
    clearErr();
    setCurrentStep(step);
  };

  const onNextStep = () => {
    changeStep(currentStep + 1);
  };
  const onPreviousStep = () => {
    changeStep(currentStep - 1);
  };

  return (
    <StepperContext.Provider
      value={{ onNextStep, onPreviousStep, currentStep, changeStep }}
    >
      <div className="stepper-wrapper">
        {withArianneThread && (!initialStep || currentStep !== 1) && (
          <StepperProgressBar
            steps={React.Children.map(children, (child) => ({
              title: child.props.title,
              id: child.props.id,
              icon: child.props.icon,
            }))}
            formEnd={currentStep > children.length}
            formTitle={title}
          />
        )}
        {currentStep > children.length ? (
          finalStep
        ) : (
          <div className="stepper-children">
            {children &&
              React.Children.map(children, (child) =>
                child.props.id === currentStep ? child : null
              )}
          </div>
        )}
      </div>
    </StepperContext.Provider>
  );
};
