import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { FormikContext } from '../../../pages/SubscribePage/Subscribe';

import { Icon } from '../../dumps/Icon/Icon';

import { StepperContext } from '../../Stepper';

import { ValidationResume } from './ValidationResume';

export const ValidationResumeList = () => {
  const { data } = useContext(FormikContext);
  const { changeStep } = useContext(StepperContext);
  const { t } = useTranslation();

  return (
    <>
      <button
        className="actions actions__validation-resume"
        onClick={() => changeStep(3)}
      >
        <span className="action-label">
          {t('forms.subscribe.button.modify')}
        </span>
        <Icon type="Pencil" />
      </button>
      {data.subscriptions.map((subscription) => (
        <ValidationResume parkingInformations={subscription} />
      ))}
    </>
  );
};
