import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

interface api_root_t {
  [key: string]: string;
}

interface runtime_config_t {
  IGNORE_WWW_IN_HOSTNAME: boolean;
  API_ROOT_OVERRIDE: string | null;
  API_ROOTS: api_root_t;
  API_ROOT_PROTOCOL: string;
  GTM_ID: string;
}

const runtimeConfig: runtime_config_t = RUNTIME_CONFIG as runtime_config_t;
const ignoreWwwInHostname = runtimeConfig.IGNORE_WWW_IN_HOSTNAME;
const apiRootsPerEnv = runtimeConfig.API_ROOTS;
const apiRootsProtocol = runtimeConfig.API_ROOT_PROTOCOL;
const apiRootOverride = runtimeConfig.API_ROOT_OVERRIDE;

const replaceWWW = (hostName: string): string => {
  return hostName.replace(/^www[0-9]*\./, '');
};

function getRuntimeEnvironment() {
  if (process.env.NODE_ENV === 'production') {
    /* eslint-disable no-constant-condition */
    const protocolPrefixWithApi =
      (apiRootsProtocol === 'identical'
        ? window.location.protocol
        : apiRootsProtocol + ':') + '//';
    const hostName = ignoreWwwInHostname
      ? replaceWWW(window.location.hostname)
      : window.location.hostname;

    if (apiRootOverride === 'prefix-with-api') {
      return protocolPrefixWithApi + 'api.' + hostName + '/';
    } else if (apiRootOverride === null) {
      if (!Object.prototype.hasOwnProperty.call(apiRootsPerEnv, hostName)) {
        throw new Error('We where unable to setup the environment');
      }

      if (apiRootsPerEnv[hostName] === 'prefix-with-api') {
        return protocolPrefixWithApi + 'api.' + hostName + '/';
      }

      return protocolPrefixWithApi + apiRootsPerEnv[hostName];
    }

    return protocolPrefixWithApi + apiRootOverride;
  }

  /**
   * Those uri configuration are used to automatically
   * connect to the mock implementation when the application
   * is started with `yarn start:mock`
   */
  const host = window.location.hostname;
  const port = process.env.API_LOCAL_PORT || 8000;

  const protocol = window.location.protocol === 'https:' ? 'https' : 'http';

  return `${protocol}://${host}:${port}/`;
}

axios.defaults.baseURL = getRuntimeEnvironment();

function changeNamingConvention(
  // eslint-disable-next-line
  data: any,
  convention: (txt: string) => string
) {
  if (Array.isArray(data)) {
    // eslint-disable-next-line
    const newArray: any[] = [];
    data.forEach((d) => {
      newArray.push(changeNamingConvention(d, convention));
    });
    return newArray;
  } else if (typeof data !== 'object') {
    return data;
  } else {
    // eslint-disable-next-line
    const newObject: any = {};
    const entries = Object.entries(data).map(([key, value]) => ({
      key,
      value,
    }));
    for (const i in entries) {
      if (entries[i].value && typeof entries[i].value === 'object') {
        newObject[convention(entries[i].key)] = changeNamingConvention(
          entries[i].value,
          convention
        );
      } else {
        newObject[convention(entries[i].key)] = entries[i].value;
      }
    }
    return newObject;
  }
}

const toCamelCase = (str: string): string => {
  return str.replace(/_\w/g, (char) => char[1].toUpperCase());
};

const toSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (char) => `_${char.toLowerCase()}`);
};

export const api = axios.create();

api.interceptors.response.use((response: AxiosResponse) => {
  if (
    response.data &&
    response.headers['content-type'] === 'application/json'
  ) {
    // camelCase
    response.data = changeNamingConvention(response.data, toCamelCase);
  }
  return response;
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const newConfig = { ...config };
    if (config.params) {
      // snake_case
      newConfig.params = changeNamingConvention(config.params, toSnakeCase);
    }
    if (config.data) {
      // snake_case
      newConfig.data = changeNamingConvention(config.data, toSnakeCase);
    }
    return newConfig;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);
