import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionOptionKeys } from '../../../libraries/sections/models/Subscriptions';

import { Icon } from '../Icon/Icon';

import { SubscribeBtn } from './SubscribeBtn';

type MobilePricesProps = {
  hasBtn: boolean;
};

interface IFeature {
  title: string;
  isNotInclude?: boolean;
}
interface IPrice {
  sectionTitle: string;
  headerPrice: string;
  headerPriceSmallInfo: string;
  servicePrice: string;
  btnConfirm: string;
  typeOfSubscription: string;
  id: string;
  features: IFeature[];
}

export const MobilePrices = ({ hasBtn }: MobilePricesProps) => {
  const { t } = useTranslation();
  const pricesData: IPrice[] = [
    {
      sectionTitle: t('userWithParkingPage.pricesSection.header.titleCol1'),
      headerPrice: t('userWithParkingPage.pricesSection.bornePrice.titleCol1'),
      headerPriceSmallInfo: t(
        'userWithParkingPage.pricesSection.bornePrice.descCol1'
      ),
      servicePrice: t('userWithParkingPage.pricesSection.service.titleCol1'),
      btnConfirm: t('userWithParkingPage.pricesSection.btnRent'),
      typeOfSubscription: SubscriptionOptionKeys.RENT,
      id: 'rent-btn',
      features: [
        {
          title: 'item1',
        },
        {
          title: 'item2',
        },
        {
          title: 'item3',
        },
        {
          title: 'item4',
        },
        {
          title: 'item5',
        },
        {
          title: 'item6',
        },
        {
          title: 'item7',
        },
      ],
    },
    {
      sectionTitle: t('userWithParkingPage.pricesSection.header.titleCol2'),
      headerPrice: t('userWithParkingPage.pricesSection.bornePrice.titleCol2'),
      headerPriceSmallInfo: '',
      servicePrice: t('userWithParkingPage.pricesSection.service.titleCol2'),
      btnConfirm: t('userWithParkingPage.pricesSection.btnOwn'),
      typeOfSubscription: SubscriptionOptionKeys.PURCHASE,
      id: 'purchase-btn',
      features: [
        {
          title: 'item1',
        },
        {
          title: 'item2',
        },
        {
          title: 'item3',
        },
        {
          title: 'item4',
        },
        {
          title: 'item5',
        },
        {
          title: 'item6',
        },
        {
          title: 'item7',
          isNotInclude: true,
        },
      ],
    },
  ];

  return (
    <>
      {pricesData.map((price, index) => (
        <React.Fragment key={index}>
          <div className="container pb-64">
            <div className="row m-auto text-red-re">
              <h3 className="text-lg text-center fw-bold">
                {price.sectionTitle}
              </h3>
            </div>
            <div className="row m-auto bg-normal mt-3 vertical-align p-2">
              <div className="col-9">
                <h3 className="text-lg">
                  {t('userWithParkingPage.pricesSection.bornePrice.title')}
                </h3>
                <p className="mb-0 text-xs">
                  {t('userWithParkingPage.pricesSection.bornePrice.describe')}
                </p>
              </div>
              <div className="col-3 text-center">
                <h3>
                  {price.headerPrice}
                  <p className="mb-0 text-xxs">{price.headerPriceSmallInfo}</p>
                </h3>
              </div>
            </div>
            <div className="row m-auto bg-normal mt-3 vertical-align p-2">
              <div className="col-9">
                <h3 className="text-lg">
                  {t('userWithParkingPage.pricesSection.service.title')}
                </h3>
              </div>
              <div className="col-3 text-center">
                <h3 className="text-sm">{price.servicePrice}</h3>
              </div>
            </div>
            {price.features.map((features, featureIndex) => (
              <React.Fragment key={featureIndex}>
                <div className="row m-auto bg-normal p-2 vertical-align">
                  <div className="col-9">
                    <p className="mb-0">
                      {t(
                        `userWithParkingPage.pricesSection.service.${features.title}`
                      )}
                    </p>
                  </div>
                  {!features.isNotInclude && (
                    <div className="col-3 text-center">
                      <Icon type="Valid" size="20" color="#000" />
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))}
            <div className="row m-auto bg-normal p-2 vertical-align mt-2">
              <div className="col-9 align-self-center">
                <p className="mb-0">
                  {t('userWithParkingPage.pricesSection.activation.title')}
                </p>
              </div>
              <div className="col-3 text-center">
                <p className="mb-0">
                  {t('userWithParkingPage.pricesSection.activation.rentPrice')}
                </p>
              </div>
            </div>
            <div className="row m-auto mt-2 bg-normal p-2 vertical-align">
              <div className="col align-self-center">
                <div className="row">
                  <div className="col-1 align-self-center">
                    <Icon type="MaleFemalePlug" size="20" color="#a10023" />
                  </div>
                  <div className="col-11 align-self-center">
                    <p className="mb-0 pl-3">
                      {t('userWithParkingPage.pricesSection.bonus')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              *CHF 15.-/mois pour les parkings signés avant le 31.03.24.
            </div>
            {hasBtn && (
              <SubscribeBtn
                typeOfSubscription={price.typeOfSubscription}
                title={price.btnConfirm}
                id={price.id}
              />
            )}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
