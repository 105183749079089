import React, { useReducer, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { InlineCalendly } from '../../components/views/Calendly/InlineCalendly';

import { EquipParkingForm } from '../../components/views/EquipeParkingPage/EquipParkingForm';
import { useLinks } from '../../hooks/useLinks';

type ReducerState = {
  renderCalendly: boolean;
  calendlyUrl: string;
  calendlyEmail: string;
  calendlyFullName: string;
};

type ActionProps = {
  type: string;
  payload: ReducerState;
};

enum DispatchReducer {
  UPDATE_CALENDLY_INFORMATION = 'UPDATE_CALENDLY_INFORMATION',
}

const reducer = (state: ReducerState, action: ActionProps) => {
  switch (action.type) {
    case DispatchReducer.UPDATE_CALENDLY_INFORMATION:
      return {
        ...state,
        renderCalendly: action.payload.renderCalendly,
        calendlyUrl: action.payload.calendlyUrl,
        calendlyEmail: action.payload.calendlyEmail,
        calendlyFullName: action.payload.calendlyFullName,
      };
    default:
      return state;
  }
};

const initialState = {
  renderCalendly: false,
  calendlyUrl: '',
  calendlyEmail: '',
  calendlyFullName: '',
};

export const EquipParking = () => {
  const navigate = useNavigate();
  const { home } = useLinks();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [closeButton, setCloseButton] = useState<boolean>(false);

  const onFormSubmitted = (url: string, email: string, fullName: string) => {
    dispatch({
      type: DispatchReducer.UPDATE_CALENDLY_INFORMATION,
      payload: {
        renderCalendly: true,
        calendlyUrl: url,
        calendlyEmail: email,
        calendlyFullName: fullName,
      },
    });
  };

  return state.renderCalendly ? (
    <>
      <InlineCalendly
        url={state.calendlyUrl}
        email={state.calendlyEmail}
        fullName={state.calendlyFullName}
        onChangeButtonDisplay={() => setCloseButton(true)}
      />
      {closeButton && (
        <button
          onClick={() => navigate(home().path)}
          className="btn btn--secondary btn--red calendly-close-btn"
        >
          {t('forms.subscribe.formContent.calendlyCloseBtn')}
        </button>
      )}
    </>
  ) : (
    <EquipParkingForm onFormSubmitted={onFormSubmitted} />
  );
};
